import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	API_SERVICE,
	convertObjectToHttpParams,
	CustomHttpUrlEncodingCodec,
	IApiService,
	IProductService,
} from '@oper-client/shared/data-access';
import {
	AcceptanceRule,
	ProductAllocationItem,
	ProductWithAcceptanceRules,
	SelectedBaseProductDiscounts,
	TweakedProductItemsResponse,
} from '@oper-client/shared/data-model';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProductService implements IProductService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getAll(loanRequestId: number, params: { [key: string]: string } = {}, body?: object): Observable<any> {
		const url = loanRequestId === null ? `/api/products/` : `/api/loan-requests/${loanRequestId}/search/products/`;
		const defaultPageSize = 20;

		let httpParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
		httpParams = httpParams.append('page_size', defaultPageSize);

		if (params?.credit_provider) {
			httpParams = httpParams.append('credit_provider', <any>params.credit_provider);
		}

		if (params?.productType) {
			httpParams = httpParams.append('product_type', <any>params.productType);
		}

		if (params?.loan_type) {
			httpParams = httpParams.append('loan_type', <any>params.loan_type);
		}

		if (params?.duration) {
			httpParams = httpParams.append('min_duration', params.duration);
			httpParams = httpParams.append('max_duration', params.duration);
		}

		if (params?.variability) {
			httpParams = httpParams.append('variability_group', params.variability);
		}

		if (params?.select_default_discounts) {
			httpParams = httpParams.append('select_default_discounts', params.select_default_discounts);
		}

		if (params?.page) {
			httpParams = httpParams.append('page', params.page);
		}

		return this.apiService.post(url, body, httpParams);
	}

	public getProductVersions(): Observable<any> {
		return this.apiService.get(`/api/products/`);
	}

	// TODO: refactor
	public getProductsForOfferAllocation(
		loanRequestId: number,
		params: { items: ProductAllocationItem[]; discounts: SelectedBaseProductDiscounts[] }
	): Observable<TweakedProductItemsResponse> {
		params.items.forEach((item) => {
			if (item.product.baseProductId) {
				const foundIndex = params.discounts?.findIndex((p) => p.baseProductId === item.product.baseProductId);
				if (foundIndex > -1) {
					item.discounts = params.discounts[foundIndex].discounts;
				} else {
					item.discounts = [];
				}
			}
		});
		delete params.discounts;
		const url = `/api/loan-requests/${loanRequestId}/tweak/products/`;
		return this.apiService.post(url, { ...params });
	}

	public getProductAcceptanceRules(
		loanRequestId: number,
		productId: number,
		params: { [key: string]: string } = {}
	): Observable<AcceptanceRule[]> {
		const url = `/api/loan-requests/${loanRequestId}/search/products/${productId}/`;
		return this.apiService.post(url, undefined, convertObjectToHttpParams(params)).pipe(
			map((response: ProductWithAcceptanceRules[]) => {
				if (response.length > 1) {
					throw new Error(`Expected only 1 list item in response but found more (${response.length}) than expected`);
				}

				return response.length === 1 ? response[0].acceptanceRules : [];
			})
		);
	}
}
