import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export function slideInOut(durationMs = 200): AnimationTriggerMetadata {
	return trigger('slideInOut', [
		state('void', style({ transform: 'translateX(100%)' })),
		state('*', style({ transform: 'none' })),
		transition('void => *', animate(`${durationMs}ms ease-out`)),
		transition('* => void', animate(`${durationMs}ms ease-in`)),
	]);
}
