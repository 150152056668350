import { PurposeConfiguration } from '../models/purpose-config';
import { LoanPurposeEnums, PartialNormalizedResource, RealtyPurposeEnum, ResourceType } from '@oper-client/shared/data-model';

const configuration: PurposeConfiguration = {
	presets: (resources: PartialNormalizedResource) => ({
		loanPurposes: resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.filter((purpose) => purpose.definition === 'purchase'),
	}),
	loanPurposes: [
		LoanPurposeEnums.PURCHASE,
		LoanPurposeEnums.NEW_BUILD,
		LoanPurposeEnums.BUY_OUT,
		LoanPurposeEnums.RENOVATION,
		LoanPurposeEnums.REFINANCE,
	],
	loanPurposeCombinations: {
		[LoanPurposeEnums.PURCHASE]: [LoanPurposeEnums.RENOVATION, LoanPurposeEnums.REFINANCE],
		[LoanPurposeEnums.NEW_BUILD]: [LoanPurposeEnums.REFINANCE],
		[LoanPurposeEnums.BUY_OUT]: [LoanPurposeEnums.RENOVATION, LoanPurposeEnums.REFINANCE],
	},
	loanAndRealtyPurposeMap: {
		[LoanPurposeEnums.PURCHASE]: [RealtyPurposeEnum.BUY, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.BUY_OUT]: [RealtyPurposeEnum.BUY_OUT, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.NEW_BUILD]: [RealtyPurposeEnum.BUILD, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.RENOVATION]: [RealtyPurposeEnum.RENOVATE, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.EXTENSION]: [RealtyPurposeEnum.EXTENSION, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.MODERNIZATION]: [RealtyPurposeEnum.MODERNIZATION, RealtyPurposeEnum.COLLATERAL],
		[LoanPurposeEnums.REFINANCE]: [RealtyPurposeEnum.REFINANCE, RealtyPurposeEnum.COLLATERAL],
		default: [RealtyPurposeEnum.BUY, RealtyPurposeEnum.COLLATERAL],
	},
};

export default configuration;
