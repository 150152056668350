import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';

@Injectable()
export class HomeSavingsService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}
    
	getApiVersion(): Observable<any> {
		return this.apiService.get(`/api/microservice/home-savings/version`);
	}

}
