import { Inject, Injectable } from '@angular/core';
import { EMPTY, expand, of, reduce } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProductActions from './product.actions';
import { updateLoadedProductsCount } from './product.actions';
import { IProductService, PRODUCT_SERVICE } from '@oper-client/shared/data-access';
import { Store } from '@ngrx/store';
import * as fromProducts from './product.reducer';
import { PaginatedSearchResponse, Product } from '@oper-client/shared/data-model';

@Injectable()
export class ProductEffects {
	constructor(
		private actions$: Actions,
		private store: Store<fromProducts.ProductState>,
		@Inject(PRODUCT_SERVICE) private productService: IProductService
	) {}

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.loadProducts),
			switchMap(({ loanRequestId, params, body }) => {
				let page = 1;

				return this.productService.getAll(loanRequestId, { ...params, page: page.toString() }, body).pipe(
					expand((response) => {
						if (!response.next) return EMPTY;

						page += 1;
						return this.productService.getAll(loanRequestId, { ...params, page: page.toString() }, body);
					}),
					reduce((allProducts: Product[], currentResponse: PaginatedSearchResponse<Product>) => {
						const combinedProducts = [...allProducts, ...currentResponse.results];
						this.store.dispatch(
							updateLoadedProductsCount({
								totalLoadedProductsProgress: (combinedProducts.length / currentResponse.count) * 100,
							})
						);
						return combinedProducts;
					}, []),
					map((products) => ProductActions.loadProductsSuccess({ products })),
					catchError((error) => of(ProductActions.loadProductsFailure({ error })))
				);
			})
		)
	);

	loadProductVariations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.loadProductVariations),
			switchMap(() =>
				this.productService.getProductVersions().pipe(
					map((productVariations) => ProductActions.loadProductVariationsSuccess({ products: productVariations[0].products })),
					catchError((error) => of(ProductActions.loadProductsFailure({ error: error })))
				)
			)
		)
	);
}
