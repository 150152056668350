import { Income, PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';
import { InputAsyncSearch, InputField, InputRadio, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { debounceTimes, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { GoogleGeocodeService } from '@oper-client/shared/util-google-maps';
import { map, Observable, filter, BehaviorSubject } from 'rxjs';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { ValidatorService } from '../../services/validator.service';
import { displayAddress } from '../../utils/dynamic-form.utils';

export default function (
	income: Partial<Income>,
	resources: PartialNormalizedResource,
	realties: Realty[],
	isLoanRequestRealty = true
): InputBase<any>[] {
	const isLoanRequestRealtySubject = new BehaviorSubject<boolean>(isLoanRequestRealty);
	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: income?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span12',
		}),
		new InputRadio({
			key: 'isLoanRequestRealty',
			label: 'ç.feature.client.income.isLoanRequestRealty',
			questionClass: 'multiline-label',
			value: isLoanRequestRealtySubject.value,
			required: false,
			options: [
				{ id: true, key: 'ç.misc.yes' },
				{ id: false, key: 'ç.misc.no' },
			],
			transform: (x) => {
				isLoanRequestRealtySubject.next(x === 'true');
				return x === 'true';
			},
		}),
		new InputSelect({
			key: 'realty.id',
			value: income?.realty?.id,
			options: realties?.map((realty) => ({
				id: realty?.id,
				key: displayAddress(realty?.address),
			})),
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => !isLoanRequestRealty)),
		}),
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: income?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: income?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: income?.address?.box || '',
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: income?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: income?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: income?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
			hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
		}),
	];
}
