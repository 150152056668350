<div [class]="class()" class="section__container">
	@if (iconName) {
		<div class="icon__container" [class.icon__container--background]="iconBackground()">
			<oper-client-fontawesome-icon [icon]="iconName()" [fixedWidth]="true" />
		</div>
	}
	<div class="section__title">
		{{ user() | fullName }}
	</div>
</div>
