import {
	AdditionalLoanPurposeEnum,
	LoanPurposeToRealtyPurposeMapCalculator,
	PrimaryLoanPurposeEnum,
	RealtyPurposeEnum,
	Resource,
} from '@oper-client/shared/data-model';

export function compareProjectPurposeFormFields(previous: { loanPurposes: Resource[] }, current: { loanPurposes: Resource[] }): boolean {
	return compareResources(previous.loanPurposes ?? [], current.loanPurposes ?? []);
}

export function compareResources(previous: Resource[], current: Resource[]): boolean {
	return previous.length === current.length && previous.every((resource, index) => resource.id === current[index].id);
}

export function getRealtyPurposeFromLRPurpose(
	loanPurposes: Resource[],
	loanAndRealtyPurposeMap: Record<string, RealtyPurposeEnum[]>,
	realtyPurposeResource: Resource[]
): number[] {
	const purposesToSet = new Set<number>();

	if (!loanPurposes?.length) {
		return loanAndRealtyPurposeMap.default
			.map((definition) => realtyPurposeResource.find((r) => r.definition === definition)?.id)
			.filter(Boolean);
	}
	loanPurposes.forEach((loanPurpose) => {
		const realtyPurposes = loanAndRealtyPurposeMap[loanPurpose?.definition];
		if (realtyPurposes) {
			realtyPurposes.forEach((purpose) => purposesToSet.add(realtyPurposeResource.find((r) => r.definition === purpose)?.id));
		}
	});

	return Array.from(purposesToSet);
}

/**
 * This method is used to filter out realty purposes that are allowed for the selected loan purposes.
 * @param loanPurposes selected loan purposes
 * @param realtyPurposes all realty purposes
 * @returns allowed realty purposes for the selected loan purposes
 */
export function filterAllowedRealtyPurposesByLoanPurpose(loanPurposes: Resource[], realtyPurposes: Resource[]): Resource[] {
	const result = loanPurposes
		.map((loanPurpose) => {
			const loanPurposeDefinition = loanPurpose.definition as PrimaryLoanPurposeEnum | AdditionalLoanPurposeEnum;
			const realtyPurpose = LoanPurposeToRealtyPurposeMapCalculator[loanPurposeDefinition];
			return realtyPurposes.find((rp) => rp.definition === realtyPurpose);
		})
		.filter((realtyPurpose): realtyPurpose is Resource => !!realtyPurpose);

	return result.length > 0 ? result : realtyPurposes;
}
