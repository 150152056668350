import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
	MortgageReport,
	AdvisorSimulationParameters,
	MortgageSimulationResult,
	MortgageSimulationResultDocumentResponse,
	MortgageReportRequest,
	Resource,
	Simulator,
} from '@oper-client/shared/data-model';

export const MORTGAGE_SIMULATOR_SERVICE = new InjectionToken<IMortgageSimulatorService>('MORTGAGE_SIMULATOR_SERVICE');

export interface IMortgageSimulatorService {
	calculateClientSimulation(mortgageReportRequest: MortgageReportRequest): Observable<MortgageReport>;

	calculateAdvisorSimulation(simulationParameters: AdvisorSimulationParameters): Observable<MortgageSimulationResult>;

	createSimulationResult(simulationResult: MortgageSimulationResult): Observable<MortgageSimulationResult>;

	updateSimulationResult(
		simulationResultId: number,
		simulationResult: Partial<MortgageSimulationResult>
	): Observable<MortgageSimulationResult>;

	loadSimulationResults(): Observable<MortgageSimulationResult[]>;

	loadSimulationResult(simulationResultId: number): Observable<MortgageSimulationResult>;

	removeSimulationResult(simulationResultId: number): Observable<void>;

	cleanUpSimulationResults(): Observable<void>;

	generateSimulationResultDocument(simulationResultId: number, language: Resource): Observable<MortgageSimulationResultDocumentResponse>;

	createSimulation(request?: Partial<Simulator.Simulation>): Observable<Simulator.Simulation>;

	updateSimulation(simulationResultId: number, request?: Partial<Simulator.Simulation>): Observable<Simulator.Simulation>;

	syncSimulations(loanRequestId: number): Observable<any>;
}
