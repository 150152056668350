import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActionState, ActionTypes, initialActionState, Product, setActionState } from '@oper-client/shared/data-model';

import * as ProductActions from './product.actions';

export const PRODUCT_ENTITY_KEY = 'product';

export const productAdapter: EntityAdapter<Product> = createEntityAdapter<Product>({
	selectId: (product: Product) => `${product.id}-${product.variability.definition}`,
});

export interface ProductState extends EntityState<Product> {
	actions: ProductActionsState;
	error: HttpErrorResponse | undefined;
	totalLoadedProductsProgress: number;
}

export type ProductActionTypes = 'loadProducts' | 'loadProductVariations';
export type ProductActionsState = Record<ProductActionTypes, ActionState>;

export const initialState: ProductState = productAdapter.getInitialState({
	actions: {
		loadProducts: initialActionState,
		loadProductVariations: initialActionState,
	},
	error: undefined,
	totalLoadedProductsProgress: 10,
});

function setActionStates(
	actionState: ProductActionsState,
	action: ProductActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): ProductActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,

	on(ProductActions.loadProducts, (state) => ({
		...state,
		totalLoadedProductsProgress: initialState.totalLoadedProductsProgress,
		actions: setActionStates(state.actions, 'loadProducts', ActionTypes.loading),
	})),
	on(ProductActions.updateLoadedProductsCount, (state, { totalLoadedProductsProgress }) => ({
		...state,
		totalLoadedProductsProgress,
	})),
	on(ProductActions.loadProductsSuccess, (state, { products }) =>
		productAdapter.setAll(products, {
			...state,
			actions: setActionStates(state.actions, 'loadProducts', ActionTypes.success),
		})
	),
	on(ProductActions.loadProductsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProducts', ActionTypes.failure, error),
	})),

	on(ProductActions.loadProductVariations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProductVariations', ActionTypes.loading),
	})),
	on(ProductActions.loadProductVariationsSuccess, (state, { products }) =>
		productAdapter.setAll(products, {
			...state,
			actions: setActionStates(state.actions, 'loadProductVariations', ActionTypes.success),
		})
	),
	on(ProductActions.loadProductVariationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProductVariations', ActionTypes.failure, error),
	})),

	on(ProductActions.clearProducts, (state) => productAdapter.removeAll({ ...state, actions: initialState.actions }))
);
