import { DynamicInputTable, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { BorrowerSimulationDto, isLiabilityTypeCreditLine, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';

export default function (formData?: BorrowerSimulationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	const additionalFormFields: InputBase<any>[] = [];
	if (formData?.coBorrowerIncomes?.length > 0) {
		additionalFormFields.push(
			new DynamicInputTable({
				key: 'coBorrowerLiabilities',
				value: formData?.coBorrowerLiabilities,
				debounceTime: debounceTimes.xxs,
				showAsCard: true,
				cardTitle: 'ç.misc.coBorrower',
				addRowLabel: 'ç.misc.action.moreExpenses',
				required: false,
				secondaryAction: true,
				showDeleteButton: true,
				showHeader: false,
				secondaryActionLabel: 'ç.misc.action.addCoBorrowerExpense',
				columns: getColumns(resources),
			})
		);
	}
	return [
		new DynamicInputTable({
			key: 'mainBorrowerLiabilities',
			value: formData?.mainBorrowerLiabilities,
			debounceTime: debounceTimes.xxs,
			showAsCard: true,
			cardTitle: 'ç.misc.mainBorrower',
			addRowLabel: 'ç.misc.action.moreExpenses',
			required: false,
			secondaryAction: true,
			secondaryActionLabel: 'ç.misc.action.addExpense',
			showDeleteButton: true,
			showHeader: false,
			columns: getColumns(resources),
		}),
		...additionalFormFields,
	];
}

function getColumns(resources: PartialNormalizedResource): InputBase<any>[] {
	const liabilityTypes = resources?.[ResourceType.LIABILITY_TYPE] ?? [];

	return [
		new InputSelect({
			key: 'liabilityType.id',
			required: true,
			clearable: true,
			label: 'ç.feature.mortgageSimulator.liabilityType.label',
			validators: [],
			hideRequiredAsterisk: true,
			options: orderBy(resources?.[ResourceType.LIABILITY_TYPE] || [], 'order'),
			alreadySorted: true,
			class: 'span6',
		}),
		new InputField({
			key: 'monthlyAmount',
			label: 'ç.feature.mortgageSimulator.monthly',
			type: 'number',
			required: true,
			currency: true,
			hideRequiredAsterisk: true,
			class: 'span6',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => {
				const isCreditLine = isLiabilityTypeCreditLine(formGroup.value['liabilityType.id'], liabilityTypes);
				const isHidden$ = new BehaviorSubject(isCreditLine);
				formGroup.controls['liabilityType.id'].valueChanges
					.pipe(
						map((liabilityTypeId) => isLiabilityTypeCreditLine(liabilityTypeId, liabilityTypes)),
						takeUntil(destroy$)
					)
					.subscribe((isCreditLine) => isHidden$.next(isCreditLine));

				return isHidden$.asObservable();
			},
		}),

		new InputField({
			key: 'amount',
			label: 'ç.question.creditLimit.label',
			type: 'number',
			required: true,
			currency: true,
			hideRequiredAsterisk: true,
			class: 'span6',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => {
				const isCreditLine = isLiabilityTypeCreditLine(formGroup.value['liabilityType.id'], liabilityTypes);
				const isHidden$ = new BehaviorSubject(!isCreditLine);
				formGroup.controls['liabilityType.id'].valueChanges
					.pipe(
						map((liabilityTypeId) => isLiabilityTypeCreditLine(liabilityTypeId, liabilityTypes)),
						takeUntil(destroy$)
					)
					.subscribe((isCreditLine) => isHidden$.next(!isCreditLine));

				return isHidden$.asObservable();
			},
		}),
	];
}
