import { Resource } from './resource.model';
import { Document } from './document.model';
import { Integrator } from './integrator.model';
import { LiabilityConsultation } from './liability.model';

export type DataConnectionMode = 'data' | 'documents';
export enum IntegratorType {
	LENDER = 'lender',
	BORROWER_INFORMATION = 'borrowerInformation',
}

export interface DataConnection {
	id?: number;
	integrator: Integrator;
	reference?: string;
	emailRecipient?: string;
	additionalData?: {
		broker: string;
		subBroker?: string;
	};
	status?: Resource;
	integrationAction?: Resource;
	errors?: { code: string; translationCode: string; message: string; parameters: any }[];
	created?: string;
	documents?: Partial<Document>[];
	proofObjectsIds?: number[];
	consultation?: LiabilityConsultation;
}

export enum DataConnectionStatusType {
	FAILED = 'failed',
	MISSING_DATA = 'missingData',
	REJECTED = 'rejected',
	PENDING = 'pending',
	SUCCESS = 'success',
	ACCEPTED = 'accepted',
}
