import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (): InputBase<any>[] {

	return [
        new InputField(
            {
                key: 'birth-name',
                label: 'ç.question.birthName.label',
                value: '',
                controlType: 'field',
                class: "span12",
                placeholder: "Placeholder",
                required: false
            }
        ),
        new InputField(
            {
                key: 'mothers-maiden-name',
                label: 'ç.question.motherMaidenName.label',
                value: 'Anna Doe',
                controlType: 'field',
                class: "span12",
                placeholder: "Placeholder",
                required: false
            }
        ),
        new InputField(
            {
                key: 'place-of-birth',
                label: 'ç.question.placeOfBirth.label',
                value: 'Debrecen',
                controlType: 'field',
                
                class: "span6",
                placeholder: "Placeholder",
                required: false
            }
        ),
        new InputField(
            {
                key: 'date-of-birth',
                label: 'ç.question.birthDate.label',
                value: '2025-01-15',
                controlType: 'field',
                type: "date",
                class: "span6",
                placeholder: "Placeholder",
                required: false
            }
        ),
    ];
}
