import { Address, Income, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { InputField, InputSelect, Section } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import {
	COMMON_REGEX_EXPRESSIONS,
	DATE_RESTRICTION,
	DATE_RESTRICTION_VALIDATION_CONSTANTS,
} from '../../services/validator-constants';
import { Observable, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { betaSelectContainsValues } from '../../utils/dynamic-form.utils';

const CONTRACT_TYPES_WITH_END_DATE = {
  DEFINITE: 'definite',
  INTERIM: 'interim',
};

export default function (income: Partial<Income>, resources: PartialNormalizedResource): InputBase<any>[] {
	const address: Address = income?.employer?.address || <Address>{};
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();

	const contractTypes = resources[ResourceType.EMPLOYMENT_CONTRACT_TYPE] || [];
	const contractTypeDefinite = contractTypes.find(resource => resource.definition === CONTRACT_TYPES_WITH_END_DATE.DEFINITE);
	const contractTypeInterim = contractTypes.find(resource => resource.definition === CONTRACT_TYPES_WITH_END_DATE.INTERIM);

	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: income?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span12',
		}),
		new InputField({
			key: 'employer.name',
			label: 'ç.question.employer.label',
			value: income?.employer?.name,
			required: true,
			type: 'text',
			class: 'span12',
		}),
		new InputSelect({
			key: 'contractType.id',
			label: 'ç.question.contractType.label',
			value: income?.contractType?.id,
			required: true,
			options: resources?.[ResourceType.EMPLOYMENT_CONTRACT_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'startDate',
			label: 'ç.question.startDate.label',
			value: income?.startDate,
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE),
			],
			max: currentDate,
			type: 'date',
			class: 'span12',
		}),
		new InputField({
			key: 'endDate',
			label: 'ç.question.endDate.label',
			value: income?.endDate,
			required: false,
			type: 'date',
			validators: [
				ValidatorService.getDateRestrictionValidatior('future', currentDate, DATE_RESTRICTION.PAST),
			],
			class: 'span12',
			min: currentDate,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'endDate');
				const formControl = <FormControl>formGroup.controls['endDate'];
				return formGroup.controls['contractType.id'].valueChanges.pipe(
					startWith(formGroup.controls['contractType.id'].value),
					filter((value) => !!value),
					map((value) => {
						if (!betaSelectContainsValues(value, [contractTypeDefinite, contractTypeInterim])) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							inputField.value = null,
							formControl.patchValue(null);
						} else {
							inputField.type = 'date';
							inputField.disabled = false;
							inputField.required = true;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'jobFunction',
			label: 'ç.question.jobFunction.label',
			value: income?.jobFunction,
			required: false,
			validators: [],
			type: 'text',
			class: 'span12',
		}),
		new InputField({
			key: 'employer.vatNumber',
			label: 'ç.question.vatNumber.label',
			value: income?.employer?.vatNumber,
			required: false,
			type: 'text',
			class: 'span12',
		}),
		new InputField({
			key: 'isOnMaternityLeave',
			label: 'ç.question.isOnMaternityLeave.label',
			value: income?.isOnMaternityLeave,
			required: false,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputField({
			key: 'isOnNoticePeriod',
			label: 'ç.question.isOnNoticePeriod.label',
			value: income?.isOnNoticePeriod,
			required: false,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputField({
			key: 'isOnProbationPeriod',
			label: 'ç.question.isOnProbationPeriod.label',
			value: income?.isOnProbationPeriod,
			required: false,
			type: 'checkbox',
			class: 'span12',
		}),
		new Section({
			class: 'span12',
			title: 'ç.question.address.details',
		}),
		new InputSelect({
			key: 'employer.address.country.id',
			label: 'ç.question.country.label',
			value: address.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: 'employer.address.city',
			label: 'ç.question.city.label',
			value: address.city,
			type: 'text',
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: 'employer.address.street',
			label: 'ç.question.street.label',
			value: address.street,
			type: 'text',
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: 'employer.address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: address.houseNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: 'employer.address.zipCode',
			label: 'ç.question.zipCode.label',
			value: address.zipCode,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(
					COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_WHITESPACE,
					'onlyNumbersAndLetters'
				),
				Validators.maxLength(8),
			],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: 'employer.address.box',
			label: 'ç.question.box.label',
			value: address.box,
			required: false,
			type: 'text',
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
	];
}
