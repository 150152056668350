import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { JwtModule, JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { tokenGetter } from '@oper-client/shared/util-bootstrap';

import { APP_CONFIG, CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import {
	API_SERVICE,
	AUTH_SERVICE,
	AuthenticationGuard,
	AuthRedirectionService,
	IsAuthenticatedGuard,
	RefreshTokenInterceptor,
	ROLE_GUARD,
	RoleGuard,
	TokenInterceptor,
} from '@oper-client/shared/data-access';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';
import { ApiV2Module, AuthService } from '@oper-client/shared/api-v2.0';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonBrokerageAppModule } from '../common-brokerage-app/common-brokerage-app.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		CommonBrokerageAppModule,
		AppRoutingModule,

		/* jwt helper config */
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: (config: CustomerInsights) => ({
					tokenGetter,
					whitelistedDomains: config.whitelistedDomains,
				}),
				deps: [CUSTOMER_INSIGHTS_CONFIG],
			},
		}),

		/* Initialize generated client */
		ApiV2Module.forRoot({
			authService: AuthService,
			deps: [API_SERVICE, APP_CONFIG, LocalStorageService, JwtHelperService, EnvironmentLocaleFormatService],
		}),
	],
	providers: [
		[
			{
				provide: HTTP_INTERCEPTORS,
				useClass: TokenInterceptor,
				multi: true,
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: RefreshTokenInterceptor,
				multi: true,
			},
			{
				provide: AuthenticationGuard,
				deps: [AUTH_SERVICE, Router, AuthRedirectionService],
				useClass: AuthenticationGuard,
			},
			{
				provide: ROLE_GUARD,
				deps: [AUTH_SERVICE, Router],
				useClass: RoleGuard,
			},
			{
				provide: IsAuthenticatedGuard,
				deps: [AUTH_SERVICE, Router],
			},
		],
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
