import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IApiService, IIntegrationsService } from '@oper-client/shared/data-access';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IntegrationsService implements IIntegrationsService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getAllowedIntegrations(integratorType?: string): Observable<any> {
		let params = new HttpParams();
		if (integratorType) {
			params = params.set('integrator_type', integratorType);
		}
		return this.apiService.get(`/api/integrations/allowed-integrators/`, params).pipe(map((paginatedResult) => paginatedResult));
	}
}
