import { LoanPurposeEnums, PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';
import { InputField, InputSelect, PercentageInputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { roundPercentage } from '@oper-client/shared/util-formatting';

export default function (
	formData?: Partial<Realty>,
	resources?: PartialNormalizedResource,
	multiplePurposesEnabled = false
): InputBase<any>[] {
	const isPurchaseLoanType = formData?.loanPurpose?.definition === LoanPurposeEnums.PURCHASE;
	return [
		new InputSelect({
			key: multiplePurposesEnabled ? 'purposes' : 'purposes[0].id',
			label: 'ç.question.mainPurpose.label',
			value: multiplePurposesEnabled ? formData?.purposes?.map((el) => el.id) : formData?.purposes?.[0]?.id,
			required: true,
			hideRequiredAsterisk: true,
			options: resources?.[ResourceType.REALTY_PURPOSE] || [],
			class: 'span12',
			multiple: multiplePurposesEnabled,
			beta: multiplePurposesEnabled,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.propertyType.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			options: resources?.[ResourceType.REALTY_TYPE] || [],
			validators: [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageType.label',
			value: formData?.usageTypes?.[0]?.id,
			required: true,
			hideRequiredAsterisk: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'mainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.mainFirstResidence || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
		}),
		new InputSelect({
			key: 'purchaseSaleType.id',
			label: 'ç.question.typeOfSale.label',
			value: formData?.purchaseSaleType?.id,
			required: false,
			markAsOptional: true,
			clearable: false,
			options: resources?.[ResourceType.PURCHASE_SALE_TYPE],
			class: 'span12',
			hidden: (): Observable<boolean> => of(!isPurchaseLoanType),
		}),
		new PercentageInputField({
			key: 'livingPercentage',
			label: 'ç.question.livingPercentage.label',
			value: roundPercentage(formData?.livingPercentage),
			type: 'number',
			validators: [Validators.min(0), Validators.max(100)],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const livingUsageTypeId = resources?.[ResourceType.REALTY_USAGE_TYPE]?.find((item) => item.definition === 'living')?.id;
				const subject = new BehaviorSubject(
					!formGroup.value['usageTypes[0].id'] || formGroup.value['usageTypes[0].id'] === livingUsageTypeId
				);

				formGroup.controls['usageTypes[0].id'].valueChanges
					.pipe(
						map((value) => !value || value === livingUsageTypeId),
						takeUntil(destroy$)
					)
					.subscribe({
						complete: () => subject.complete(),
						error: (error) => subject.error(error),
						next: (value) => subject.next(value),
					});

				return subject.asObservable();
			},
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
	];
}
