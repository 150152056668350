import { Ownership, Realty } from '@oper-client/shared/data-model';
import { LoanRequestEntityService } from './loan-request-entity.service';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const REALTY_OWNER_SERVICE = new InjectionToken<IRealtyService>('REALTY_OWNER_SERVICE');

export interface IRealtyService extends LoanRequestEntityService<Realty> {
	updateOwnership?(loanRequestId: number, realtyId: number, payload: Partial<Realty>): Observable<Ownership[]>;
}
