import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SideBarService {
	protected readonly _sideBarCollapsed$ = new BehaviorSubject(false);
	private readonly _showSideBar$ = new BehaviorSubject(true);

	protected _sideBarElement: HTMLElement;
	protected readonly _clicked = new Subject();

	readonly sideBarCollapsed$ = this._sideBarCollapsed$.asObservable();
	readonly showSidebar$ = this._showSideBar$.asObservable().pipe(debounceTime(10), distinctUntilChanged(), shareReplay(1));
	readonly clicked$ = this._clicked.asObservable();

	get sideBarCollapsed() {
		return this._sideBarCollapsed$.getValue();
	}

	collapse(state: boolean) {
		this._sideBarCollapsed$.next(state);
	}

	toggleCollapse(): void {
		this._sideBarCollapsed$.next(!this._sideBarCollapsed$.value);
	}

	hideSidebar(): void {
		this._showSideBar$.next(false);
	}

	showSidebar(): void {
		this._showSideBar$.next(true);
	}

	clicked() {
		this._clicked.next(true);
	}

	get width(): number {
		return this._showSideBar$.value ? this._sideBarElement?.offsetWidth : 0;
	}

	set sideBarElement(value: HTMLElement) {
		this._sideBarElement = value;
	}
}
