import { RealtyPurposeEnum } from './realty-purpose.constants';

export enum PrimaryLoanPurposeEnum {
	PURCHASE = 'purchase',
	NEW_BUILD = 'newBuild',
	BUY_OUT = 'buyOut',
	FREE_USE = 'freeUse',
}

export enum AdditionalLoanPurposeEnum {
	RENOVATION = 'renovation',
	EXTENSION = 'extension',
	MODERNIZATION = 'modernization',
	REFINANCE = 'refinance',
}

export const LoanPurposeEnums = {
	...PrimaryLoanPurposeEnum,
	...AdditionalLoanPurposeEnum,
};

export type LoanPurpose = PrimaryLoanPurposeEnum | AdditionalLoanPurposeEnum;

export const PrimaryLoanPurposes = Object.values(PrimaryLoanPurposeEnum);

export const AdditionalLoanPurposes = Object.values(AdditionalLoanPurposeEnum);

export const LoanPurposeToRealtyPurposeMapCalculator: { [key: string]: RealtyPurposeEnum } = {
	[PrimaryLoanPurposeEnum.PURCHASE]: RealtyPurposeEnum.BUY,
	[PrimaryLoanPurposeEnum.BUY_OUT]: RealtyPurposeEnum.BUY_OUT,
	[PrimaryLoanPurposeEnum.NEW_BUILD]: RealtyPurposeEnum.BUILD,
	[AdditionalLoanPurposeEnum.MODERNIZATION]: RealtyPurposeEnum.MODERNIZATION,
	[AdditionalLoanPurposeEnum.EXTENSION]: RealtyPurposeEnum.EXTENSION,
};

export const LoanPurposeToRealtyPurposeMap: { [key: string]: RealtyPurposeEnum } = {
	[PrimaryLoanPurposeEnum.PURCHASE]: RealtyPurposeEnum.BUY,
	[PrimaryLoanPurposeEnum.BUY_OUT]: RealtyPurposeEnum.BUY_OUT,
	[PrimaryLoanPurposeEnum.NEW_BUILD]: RealtyPurposeEnum.BUILD,
	[AdditionalLoanPurposeEnum.RENOVATION]: RealtyPurposeEnum.RENOVATE,
	[AdditionalLoanPurposeEnum.REFINANCE]: RealtyPurposeEnum.REFINANCE,
};
