import {
	Component,
	ChangeDetectionStrategy,
	inject,
	DestroyRef,
	signal,
	WritableSignal,
	input,
	AfterViewInit,
	Injector,
	runInInjectionContext,
	output,
	viewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { EndpointSettings } from '../../models/input-types.model';
import { debounceTime, filter, noop, of, switchMap, tap, Observable, catchError, BehaviorSubject, map, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'oper-client-dynamic-async-search-input-field',
	templateUrl: './dynamic-async-search-input-field.component.html',
	styleUrl: './dynamic-async-search-input-field.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicAsyncSearchInputFieldComponent implements AfterViewInit, ControlValueAccessor {
	readonly bindLabel = input<string>('name');
	readonly bindValue = input<string>('id');
	readonly characterThreshold = input<number>(undefined);
	readonly debounceTime = input<number>(undefined);
	readonly disable = input<boolean>(false);
	readonly endpointSettings = input<EndpointSettings>(undefined);
	readonly hideArrow = input<boolean>(undefined);
	readonly iconName = input<string>('');
	readonly placeholder = input<string>('');
	readonly prefillDefaultValue = input<boolean>(false);
	readonly clearAfterSearch = input<boolean>(false);
	readonly value = input<any>(null);
	readonly formControlName = input<string>(null);
	readonly noFoundTextLabel = input<string>('');
	readonly valueChange = output<any>();
	readonly selectedValueChange = output<any>();

	asyncSearchResult: WritableSignal<any[]> = signal([]);
	isAsyncSearchLoading = signal<boolean>(false);
	selectedValue: any = null;
	comparisonKey: any;
	dropdownCanOpen = signal<boolean>(false);

	private select = viewChild<NgSelectComponent>('select');
	private searchTerms$ = new BehaviorSubject<string>('');
	private valueChanged$ = new Subject<any>();
	private destroyRef$ = inject(DestroyRef);
	private injector = inject(Injector);
	private control = inject(NgControl);

	constructor() {
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.comparisonKey = this.bindValue();
		this.handleDefaultValue();

		if (!this.endpointSettings()) return;
		this.searchTerms$
			.pipe(
				debounceTime(this.debounceTime() ?? 0),
				tap((term) => {
					this.dropdownCanOpen.set(false);
					if (!term || term.trim().length < (this.characterThreshold() ?? 1)) {
						this.asyncSearchResult.set([]);
					}
				}),
				filter((term) => !!term && term.trim().length >= (this.characterThreshold() ?? 1)),
				switchMap((searchTerm) => {
					this.isAsyncSearchLoading.set(true);
					return this.genericSearch(searchTerm.trim()).pipe(
						catchError(() => {
							return this.resetResultAndAllowEmptyDisplay();
						})
					);
				}),
				takeUntilDestroyed(this.destroyRef$)
			)
			.subscribe((result) => {
				if (result) {
					this.updateAsyncSearchResult(result);
				}
				this.isAsyncSearchLoading.set(false);
			});

		// Subscribe to valueChanged$ to handle value changes
		this.valueChanged$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((value) => {
			this.valueChange.emit(value);
			this.selectedValueChange.emit(value);
		});
	}

	/**
	 * This function is clearing the results array and allows the dropdown to open to show that no result has been found
	 * @returns {Observable<any[]>} In case of an error, this function returns an observable of an empty array to allow further API calls
	 */
	private resetResultAndAllowEmptyDisplay(): Observable<any[]> {
		this.dropdownCanOpen.set(true);
		this.asyncSearchResult.set([]);
		this.isAsyncSearchLoading.set(false);
		return of([]);
	}

	/**
	 * This function return an Observable based on the method passed as input #endpointSettings.
	 * @param {string} searchTerm
	 * @returns {Observable<any>} Observable that represents the result of the search operation.
	 */
	private genericSearch(searchTerm: string): Observable<any> {
		return runInInjectionContext(this.injector, () => {
			return this.endpointSettings()
				.method(searchTerm)
				.pipe(
					map((data) => {
						if (this.endpointSettings().transform) {
							return this.endpointSettings().transform(data, searchTerm);
						} else {
							return data;
						}
					})
				);
		});
	}

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	writeValue(obj: any): void {}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(disabled: boolean): void {}

	handleDefaultValue() {
		if (!this.prefillDefaultValue()) return;
		if (this.endpointSettings().initialLoadMethod && this.value() && this.value()[this.bindValue()]) {
			runInInjectionContext(this.injector, () => {
				this.endpointSettings()
					.initialLoadMethod(this.value()[this.bindValue()])
					.pipe(
						map((result: any) => {
							if (this.endpointSettings().transform) {
								return this.endpointSettings().transform([result])[0];
							} else {
								return result;
							}
						}),
						tap((transformedResult: any) => {
							this.selectedValue = transformedResult;
							this.asyncSearchResult.set([]);
						}),
						catchError((error) => {
							// TODO: handle error (callback or log)
							this.selectedValue = null;
							this.asyncSearchResult.set([]);
							return of(null);
						}),
						takeUntilDestroyed(this.destroyRef$)
					)
					.subscribe();
			});
		} else if (this.value()) {
			this.selectedValue = this.value();
			this.asyncSearchResult.set([]);
		}
	}

	updateAsyncSearchResult(result: any[]) {
		this.asyncSearchResult.set(result);
	}

	onInputSearch(event: any) {
		this.searchTerms$.next(event.term);
	}

	onClear() {
		this.asyncSearchResult.set([]);
		this.valueChanged$.next(null);
		this.select().blur();
	}

	onFocus() {
		this.asyncSearchResult.set([]);
	}

	onChange(event: any) {
		this.onValueChanged(event);
		this.select().blur();
	}

	clearSelectInput() {
		this.select()?.handleClearClick();
	}

	onValueChanged(value: any, skipOnTouched?: boolean) {
		if (!value) return;
		this.onChangeCallback(value);
		if (!skipOnTouched) {
			this.onTouchedCallback();
		}
		if (this.endpointSettings().transformSelectedValue) {
			runInInjectionContext(this.injector, () => {
				this.endpointSettings()
					.transformSelectedValue(value)
					.pipe(
						tap((transformedValue) => {
							this.valueChanged$.next(transformedValue);
						}),
						takeUntilDestroyed(this.destroyRef$)
					)
					.subscribe();
			});
		} else {
			this.valueChanged$.next(value);
		}
		if (this.clearAfterSearch()) {
			this.clearSelectInput();
		}
		this.asyncSearchResult.set([]);
	}

	// remove id for generic
	compareObjects(o1: any, o2: any): boolean {
		if (o1 === o2) {
			return true;
		} else {
			return o1 && o2 ? o1[this.comparisonKey] === o2[this.comparisonKey] : o1 === o2;
		}
	}
}
