import {
	Address,
	BorrowerIncomeEnum,
	Client,
	ClientRoleEnum,
	ContractTypeEnum,
	CreditProvider,
	Income,
	IncomeTypeEnum,
	LoanApplicationDto,
	NormalizedResource,
	PartialNormalizedResource,
	Realty,
	Resource,
	ResourceType,
} from '@oper-client/shared/data-model';
import { InputBase } from '../models/input-base.model';
import { DynamicFormCard, InputAsyncSearch, InputField, InputRadio, InputSelect, NamedSection, Section } from '../models/input-types.model';
import { FormConfiguration } from '../models/dynamic-form.model';
import { ValidatorService } from '../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS, DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';
import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, filter, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { CUSTOMER_INSIGHTS_CONFIG, debounceTimes } from '@oper-client/shared/configuration';
import { inject } from '@angular/core';
import { GoogleGeocodeService } from '@oper-client/shared/util-google-maps';
import { displayAddress } from '../utils/dynamic-form.utils';

const hiddenFieldsInForm = 4;
const borrowerFormConfiguration = new FormConfiguration();

const hideField =
	(controlKey: string, valuesToMatch: string[], resources?: Resource[]) =>
	(formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
		const initialFieldValue = resources?.find((resource) => resource?.id === formGroup?.controls[controlKey]?.value);
		const subject = new BehaviorSubject(valuesToMatch.includes(initialFieldValue?.definition));

		formGroup?.controls[controlKey]?.valueChanges
			.pipe(
				map((value) => {
					const fieldValue = resources.find((resource) => resource.id === value);
					return valuesToMatch.includes(fieldValue?.definition);
				}),
				takeUntil(destroy$)
			)
			.subscribe({
				complete: () => subject.complete(),
				error: (error) => subject.error(error),
				next: (value) => subject.next(value),
			});

		return subject.asObservable();
	};

export default function (
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	currency?: string,
	isUnderwrittingEnabled?: boolean
): InputBase<any>[] {
	const questions: InputBase<any>[] = [];
	const coBorrowerQuestions: InputBase<any>[] = [];
	borrowerFormConfiguration.setName('mainBorrowerPersonalDetailsForm');

	const borrowersClients = getBorrowers(formData.clients);

	const mainBorrower: Partial<Client> = formData[BorrowerIncomeEnum.MAIN_BORROWER]
		? borrowersClients.find((borrower) => borrower.lastName === formData[BorrowerIncomeEnum.MAIN_BORROWER][0].client.lastName)
		: undefined;

	const borrowersMap = new Map<Partial<Client>, Partial<Income>[]>().set(mainBorrower, formData[BorrowerIncomeEnum.MAIN_BORROWER]);

	questions.push(
		new NamedSection({
			user: {
				firstName: formData?.mainBorrowerPersonalDetails?.firstName,
				lastName: formData?.mainBorrowerPersonalDetails?.lastName,
			},
			iconName: 'faUser',
			iconBackground: true,
			required: false,
		})
	);
	const realties = [formData.mainRealty, formData.collateralRealty].filter(Boolean);
	borrowersMap.get(mainBorrower)?.forEach((income, index) => {
		if (!income || !income?.incomeType) return;
		const formConfiguration = getFormConfigurationForIncomeType(income, resources, realties, isUnderwrittingEnabled);
		questions.push(
			new DynamicFormCard({
				title: getIncomeTypeKey(resources, income),
				subtitle: `${income?.amount} ${currency}`,
				key: `${BorrowerIncomeEnum.MAIN_BORROWER}[${index}]`,
				formConfiguration: formConfiguration,
				showDeleteButton: false,
				hidden: () => of(formConfiguration?.formControl?.questions?.length <= hiddenFieldsInForm),
				required: false,
				requiredOnOpen: true,
			})
		);
	});

	if (borrowersClients.length > 1) {
		const coBorrower: Partial<Client> = formData[BorrowerIncomeEnum.CO_BORROWER]
			? borrowersClients.find((borrower) => borrower.lastName === formData[BorrowerIncomeEnum.CO_BORROWER][0].client.lastName)
			: undefined;
		borrowersMap.set(coBorrower, formData[BorrowerIncomeEnum.CO_BORROWER]);

		coBorrowerQuestions.push(
			new Section({
				class: 'span12',
			}),
			new NamedSection({
				user: {
					firstName: formData?.coBorrowerPersonalDetails?.firstName,
					lastName: formData?.coBorrowerPersonalDetails?.lastName,
				},
				iconName: 'faUser',
				iconBackground: true,
				required: false,
			})
		);
		borrowersMap.get(coBorrower)?.forEach((income, index) => {
			const formConfiguration = getFormConfigurationForIncomeType(income, resources, realties, isUnderwrittingEnabled);
			coBorrowerQuestions.push(
				new DynamicFormCard({
					title: getIncomeTypeKey(resources, income),
					subtitle: `${income?.amount} ${currency}`,
					key: `${BorrowerIncomeEnum.CO_BORROWER}[${index}]`,
					formConfiguration: formConfiguration,
					showDeleteButton: false,
					required: false,
					requiredOnOpen: true,
					hidden: () => of(formConfiguration?.formControl?.questions?.length <= hiddenFieldsInForm),
				})
			);
		});
	}

	questions.push(...coBorrowerQuestions);

	return questions;
}

function getBorrowers(clients: Client[]): Client[] {
	return clients.filter((client) => client.role?.definition === ClientRoleEnum.BORROWER);
}

function getIncomeTypeKey(resources: Partial<NormalizedResource>, income: Partial<Income>): string {
	if (!resources || !income) return;
	const incomeTypes = resources[ResourceType.INCOME_TYPE];
	const matchType = incomeTypes?.find((type) => type.definition === income?.incomeType?.definition);

	return matchType?.key;
}

function getFormConfigurationForIncomeType(
	income: Partial<Income>,
	resources: Partial<NormalizedResource>,
	realties: Realty[],
	isUnderwrittingEnabled = false
): FormConfiguration {
	if (!income || !resources || !income?.incomeType) return;

	const incomeType = income?.incomeType?.definition;

	switch (incomeType) {
		case IncomeTypeEnum.SALARY:
		case IncomeTypeEnum.SALARY_ABROAD:
		case IncomeTypeEnum.SALARY_INTERIM:
		case IncomeTypeEnum.FOREIGN_SALARY:
			return getSalaryFormConfiguration(income, resources);
		case IncomeTypeEnum.SELF_EMPLOYED:
			return getSelfEmployedFormConfiguration(income, resources);
		case IncomeTypeEnum.FUTURE_RENT:
		case IncomeTypeEnum.RENT:
			return getRentFormConfiguration(income, realties, isUnderwrittingEnabled);
		case IncomeTypeEnum.RETIRED:
			return getRetirementFormConfiguration(income);
		default:
			return getOtherFormConfiguration(income);
	}

	function getRentFormConfiguration(income: Partial<Income>, realties: Realty[], isUnderwrittingEnabled = false): FormConfiguration {
		const isLoanRequestRealtySubject = new BehaviorSubject<boolean>(true);
		const rentIncomeForm = new FormConfiguration();
		rentIncomeForm.setName('rentIncomeForm');
		const questions = [];

		questions.push(
			new InputField({
				key: 'id',
				value: income?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'client',
				value: income?.client,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'incomeType',
				value: income.incomeType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'amount',
				value: income?.amount,
				type: 'hidden',
				required: false,
			})
		);

		if (isUnderwrittingEnabled) {
			questions.push(
				new InputRadio({
					key: 'isLoanRequestRealty',
					label: 'ç.feature.client.income.isLoanRequestRealty',
					questionClass: 'multiline-label',
					value: isLoanRequestRealtySubject.value,
					required: false,
					options: [
						{ id: true, key: 'ç.misc.yes' },
						{ id: false, key: 'ç.misc.no' },
					],
					transform: (x) => {
						isLoanRequestRealtySubject.next(x === 'true');
						return x === 'true';
					},
				}),
				new InputSelect({
					key: 'realty.id',
					value: income?.realty?.id,
					options: realties?.map((realty) => ({
						id: realty?.id,
						key: displayAddress(realty?.address),
					})),
					required: false,
					validators: [],
					class: 'span12',
					updateValidityOnFormValueChanges: true,
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => !isLoanRequestRealty)),
				}),
				new InputAsyncSearch({
					key: 'addressSearch',
					label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
					placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
					bindValue: 'id',
					bindLabel: 'label',
					required: false,
					noFoundTextLabel: 'ç.misc.nothingFound',
					debounceTime: debounceTimes.m,
					clearAfterSearch: true,
					characterThreshold: 2,
					iconName: 'faMagnifyingGlass',
					prefillDefaultValue: false,
					hideArrow: true,
					class: 'span12',
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
					endpointSettings: {
						method: (searchTerm: string) => {
							const googleGeocodeService = inject(GoogleGeocodeService);
							const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
							const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
							return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
						},
						transform: (addresses: any[]) => {
							return addresses.map((address) => ({
								id: address.place_id,
								label: address.description,
							}));
						},
						transformSelectedValue: (value: any): any => {
							const googleGeocodeService = inject(GoogleGeocodeService);
							return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
						},
					},
					transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
						const key = 'addressSearch';
						const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

						return formGroup.get(key)?.valueChanges.pipe(
							filter((placeId) => !!placeId),
							filter((addressDetails) => !!addressDetails && !!addressDetails.address),
							map((addressDetails) => {
								addressDetails = { ...addressDetails, ...addressDetails.address };
								if (addressDetails.country) {
									formGroup.patchValue({
										'address.street': addressDetails.street || null,
										'address.houseNumber': addressDetails.houseNumber || null,
										'address.city': addressDetails.city || null,
										'address.zipCode': addressDetails.zipCode || null,
										'address.country.id': addressDetails.country.id || null,
									});
									formGroup.markAsDirty();
								}
								return inputField;
							})
						);
					},
				}),
				new InputField({
					key: 'address.street',
					label: 'ç.question.street.label',
					value: income?.address?.street || '',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span12',
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				}),
				new InputField({
					key: 'address.houseNumber',
					label: 'ç.question.houseNumber.label',
					value: income?.address?.houseNumber || '',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span6',
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				}),
				new InputField({
					key: 'address.box',
					label: 'ç.question.box.label',
					value: income?.address?.box || '',
					required: false,
					type: 'text',
					class: 'span6',
					markAsOptional: true,
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				}),
				new InputField({
					key: 'address.city',
					label: 'ç.question.city.label',
					value: income?.address?.city || '',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span6',
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				}),
				new InputField({
					key: 'address.zipCode',
					label: 'ç.question.zipCode.label',
					value: income?.address?.zipCode || '',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					validators: [
						ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
						Validators.maxLength(8),
					],
					class: 'span6',
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				}),
				new InputSelect({
					key: 'address.country.id',
					label: 'ç.question.country.label',
					value: income?.address?.country?.id,
					options: resources?.[ResourceType.COUNTRY] || [],
					required: true,
					bindLabel: 'key',
					hideRequiredAsterisk: true,
					hidden: () => isLoanRequestRealtySubject.pipe(map((isLoanRequestRealty) => isLoanRequestRealty)),
				})
			);
		}

		rentIncomeForm.formControl.questions = questions;
		return rentIncomeForm;
	}

	function getOtherFormConfiguration(income: Partial<Income>): FormConfiguration {
		const otherIncomeForm = new FormConfiguration();
		otherIncomeForm.setName('otherIncomeForm');

		otherIncomeForm.formControl.questions = [
			new InputField({
				key: 'id',
				value: income?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'client',
				value: income?.client,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'incomeType',
				value: income.incomeType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'amount',
				value: income?.amount,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'comment',
				label: 'ç.question.comment.label',
				value: income?.comment,
				required: false,
				type: 'text',
				class: 'span12',
				validators: [Validators.maxLength(128)],
				markAsOptional: true,
			}),
		];

		return otherIncomeForm;
	}

	function getRetirementFormConfiguration(income: Partial<Income>): FormConfiguration {
		const retirementIncomeForm = new FormConfiguration();
		retirementIncomeForm.setName('retirementIncomeForm');

		retirementIncomeForm.formControl.questions = [
			new InputField({
				key: 'id',
				value: income?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'client',
				value: income?.client,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'incomeType',
				value: income.incomeType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'amount',
				value: income?.amount,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'payer',
				label: 'ç.question.payer.label',
				value: income?.payer,
				required: true,
				hideRequiredAsterisk: true,
				type: 'text',
				class: 'span12',
				validators: [Validators.maxLength(128)],
			}),
		];

		return retirementIncomeForm;
	}

	function getSelfEmployedFormConfiguration(income: Partial<Income>, resources: Partial<NormalizedResource>): FormConfiguration {
		const address: Address = income?.employer?.address || <Address>{};
		const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();

		const selfEmployedIncomeForm = new FormConfiguration();
		selfEmployedIncomeForm.setName('selfEmployedForm');

		selfEmployedIncomeForm.formControl.questions = [
			new InputField({
				key: 'id',
				value: income?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'client',
				value: income?.client,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'incomeType',
				value: income.incomeType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'amount',
				value: income?.amount,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'employer.name',
				label: 'ç.question.companyName.label',
				value: income?.employer?.name,
				required: true,
				type: 'text',
				class: 'span12',
			}),
			new InputField({
				key: 'startDate',
				label: 'ç.question.startDate.label',
				value: income?.startDate,
				required: true,
				validators: [ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE)],
				max: currentDate,
				type: 'date',
				class: 'span12',
			}),
			new InputField({
				key: 'jobFunction',
				label: 'ç.question.jobFunction.label',
				value: income?.jobFunction,
				required: false,
				markAsOptional: true,
				validators: [],
				type: 'text',
				class: 'span12',
			}),
			new InputField({
				key: 'employer.vatNumber',
				label: 'ç.question.vatNumber.label',
				value: income?.employer?.vatNumber,
				required: false,
				markAsOptional: true,
				type: 'text',
				class: 'span12',
			}),
			new Section({
				class: 'span12',
				title: 'ç.question.address.details',
			}),
			new InputAsyncSearch({
				key: 'addressSearch',
				label: 'ç.feature.addressAutocomplete.searchForYourAddress.companyAddress.label',
				placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
				bindValue: 'id',
				bindLabel: 'label',
				required: false,
				noFoundTextLabel: 'ç.misc.nothingFound',
				debounceTime: debounceTimes.m,
				clearAfterSearch: true,
				characterThreshold: 2,
				iconName: 'faMagnifyingGlass',
				prefillDefaultValue: false,
				hideArrow: true,
				class: 'span12',
				endpointSettings: {
					method: (searchTerm: string) => {
						const googleGeocodeService = inject(GoogleGeocodeService);
						const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
						const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
						return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
					},
					transform: (addresses: any[]) => {
						return addresses.map((address) => ({
							id: address.place_id,
							label: address.description,
						}));
					},
					transformSelectedValue: (value: any): any => {
						const googleGeocodeService = inject(GoogleGeocodeService);
						return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
					},
				},
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
					const key = 'addressSearch';
					const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

					return formGroup.get(key)?.valueChanges.pipe(
						filter((placeId) => !!placeId),
						filter((addressDetails) => !!addressDetails && !!addressDetails.address),
						map((addressDetails) => {
							addressDetails = { ...addressDetails, ...addressDetails.address };
							if (addressDetails.country) {
								formGroup.patchValue({
									'employer.address.street': addressDetails.street || null,
									'employer.address.houseNumber': addressDetails.houseNumber || null,
									'employer.address.city': addressDetails.city || null,
									'employer.address.zipCode': addressDetails.zipCode || null,
									'employer.address.country.id': addressDetails.country.id || null,
								});
								formGroup.markAsDirty();
							}
							return inputField;
						})
					);
				},
			}),
			new InputField({
				key: 'employer.address.street',
				label: 'ç.question.street.label',
				value: address.street,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span12',
			}),
			new InputField({
				key: 'employer.address.houseNumber',
				label: 'ç.question.houseNumber.label',
				value: address.houseNumber,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span6',
			}),
			new InputField({
				key: 'employer.address.box',
				label: 'ç.question.box.label',
				value: address.box,
				required: false,
				markAsOptional: true,
				type: 'text',
				class: 'span6',
			}),
			new InputField({
				key: 'employer.address.zipCode',
				label: 'ç.question.zipCode.label',
				value: address.zipCode,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [
					ValidatorService.getTrimmedPatternValidator(
						COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_WHITESPACE,
						'onlyNumbersAndLetters'
					),
					Validators.maxLength(8),
				],
				class: 'span6',
			}),
			new InputField({
				key: 'employer.address.city',
				label: 'ç.question.city.label',
				value: address.city,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span6',
			}),
			new InputSelect({
				key: 'employer.address.country.id',
				label: 'ç.question.country.label',
				value: address.country?.id,
				options: resources?.[ResourceType.COUNTRY] || [],
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span12',
			}),
		];

		return selfEmployedIncomeForm;
	}

	function getSalaryFormConfiguration(income: Partial<Income>, resources: Partial<NormalizedResource>): FormConfiguration {
		const address: Address = income?.employer?.address || <Address>{};
		const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
		const employeeIncomeForm = new FormConfiguration();
		employeeIncomeForm.setName('employeeIncomeForm');

		employeeIncomeForm.formControl.questions = [
			new InputField({
				key: 'id',
				value: income?.id,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'client',
				value: income?.client,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'incomeType',
				value: income.incomeType,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'amount',
				value: income?.amount,
				type: 'hidden',
				required: false,
			}),
			new InputField({
				key: 'employer.name',
				label: 'ç.question.employer.label',
				value: income?.employer?.name,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				class: 'span12',
				order: 1,
			}),
			new InputSelect({
				key: 'contractType.id',
				label: 'ç.question.contractType.label',
				value: income?.contractType?.id,
				required: true,
				hideRequiredAsterisk: true,
				options: resources?.[ResourceType.EMPLOYMENT_CONTRACT_TYPE] || [],
				class: 'span12',
			}),
			new InputField({
				key: 'startDate',
				label: 'ç.question.startDate.label',
				value: income?.startDate,
				required: true,
				hideRequiredAsterisk: true,
				validators: [ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE)],
				max: currentDate,
				type: 'date',
				class: 'span12',
			}),
			new InputField({
				key: 'endDate',
				label: 'ç.question.endDate.label',
				value: income?.endDate ?? undefined,
				type: 'date',
				validators: [
					ValidatorService.getDateRestrictionValidatior('future', currentDate, DATE_RESTRICTION.PAST),
					ValidatorService.getEndDateRestriction(
						'contractType.id',
						[ContractTypeEnum.INTERIM, ContractTypeEnum.DEFINITE],
						resources[ResourceType.EMPLOYMENT_CONTRACT_TYPE]
					),
				],
				class: 'span12',
				min: currentDate,
				hideRequiredAsterisk: true,
				hidden: hideField('contractType.id', [ContractTypeEnum.INDEFINITE], resources[ResourceType.EMPLOYMENT_CONTRACT_TYPE]),
				required: false,
				configureOnHidden: true,
			}),
			new InputField({
				key: 'jobFunction',
				label: 'ç.question.jobFunction.label',
				value: income?.jobFunction,
				required: false,
				markAsOptional: true,
				validators: [],
				type: 'text',
				class: 'span12',
			}),
			new InputField({
				key: 'employer.vatNumber',
				label: 'ç.question.vatNumber.label',
				value: income?.employer?.vatNumber,
				required: false,
				markAsOptional: true,
				type: 'text',
				class: 'span12',
			}),
			new InputField({
				key: 'isOnMaternityLeave',
				label: 'ç.question.isOnMaternityLeave.label',
				value: income?.isOnMaternityLeave,
				required: false,
				type: 'checkbox',
				class: 'span12',
			}),
			new InputField({
				key: 'isOnNoticePeriod',
				label: 'ç.question.isOnNoticePeriod.label',
				value: income?.isOnNoticePeriod,
				required: false,
				type: 'checkbox',
				class: 'span12',
			}),
			new InputField({
				key: 'isOnProbationPeriod',
				label: 'ç.question.isOnProbationPeriod.label',
				value: income?.isOnProbationPeriod,
				required: false,
				type: 'checkbox',
				class: 'span12',
			}),
			new Section({
				class: 'span12',
				title: 'ç.question.address.details',
			}),
			new InputAsyncSearch({
				key: 'addressSearch',
				label: 'ç.feature.addressAutocomplete.searchForYourAddress.employerAddress.label',
				placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
				bindValue: 'id',
				bindLabel: 'label',
				required: false,
				noFoundTextLabel: 'ç.misc.nothingFound',
				debounceTime: debounceTimes.m,
				clearAfterSearch: true,
				characterThreshold: 2,
				iconName: 'faMagnifyingGlass',
				prefillDefaultValue: false,
				hideArrow: true,
				class: 'span12',
				endpointSettings: {
					method: (searchTerm: string) => {
						const googleGeocodeService = inject(GoogleGeocodeService);
						const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
						const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
						return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
					},
					transform: (addresses: any[]) => {
						return addresses.map((address) => ({
							id: address.place_id,
							label: address.description,
						}));
					},
					transformSelectedValue: (value: any): any => {
						const googleGeocodeService = inject(GoogleGeocodeService);
						return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
					},
				},
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
					const key = 'addressSearch';
					const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

					return formGroup.get(key)?.valueChanges.pipe(
						filter((placeId) => !!placeId),
						filter((addressDetails) => !!addressDetails && !!addressDetails.address),
						map((addressDetails) => {
							addressDetails = { ...addressDetails, ...addressDetails.address };
							if (addressDetails && addressDetails.country) {
								formGroup.patchValue({
									'employer.address.street': addressDetails.street || null,
									'employer.address.houseNumber': addressDetails.houseNumber || null,
									'employer.address.city': addressDetails.city || null,
									'employer.address.zipCode': addressDetails.zipCode || null,
									'employer.address.country.id': addressDetails.country.id || null,
								});
								formGroup.markAsDirty();
							}
							return inputField;
						})
					);
				},
			}),
			new InputField({
				key: 'employer.address.street',
				label: 'ç.question.street.label',
				value: address.street,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span12',
			}),
			new InputField({
				key: 'employer.address.houseNumber',
				label: 'ç.question.houseNumber.label',
				value: address.houseNumber,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span6',
			}),
			new InputField({
				key: 'employer.address.box',
				label: 'ç.question.box.label',
				value: address.box,
				required: false,
				type: 'text',
				class: 'span6',
				markAsOptional: true,
			}),
			new InputField({
				key: 'employer.address.city',
				label: 'ç.question.city.label',
				value: address.city,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span6',
			}),
			new InputField({
				key: 'employer.address.zipCode',
				label: 'ç.question.zipCode.label',
				value: address.zipCode,
				type: 'text',
				required: true,
				hideRequiredAsterisk: true,
				validators: [
					ValidatorService.getTrimmedPatternValidator(
						COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_WHITESPACE,
						'onlyNumbersAndLetters'
					),
					Validators.maxLength(8),
				],
				class: 'span6',
			}),
			new InputSelect({
				key: 'employer.address.country.id',
				label: 'ç.question.country.label',
				value: address.country?.id,
				options: resources?.[ResourceType.COUNTRY] || [],
				required: true,
				hideRequiredAsterisk: true,
				validators: [],
				class: 'span12',
			}),
		];

		return employeeIncomeForm;
	}
}
