import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BorrowerModes, LoanRequest, LoanRequestLoadParameters, parseActionState, ParsedActionState } from '@oper-client/shared/data-model';
import { LoanRequestPartialState } from '../loan-request.reducer';
import * as LoanRequestSelectors from './loan-request.selector';
import * as LoanRequestActions from './loan-request.actions';
import { LoanRequestActionTypes } from './loan-request.reducer';
import { GtmEventPayload } from '@oper-client/shared/tracking/gtm';

@Injectable()
export class LoanRequestFacade {
	/**
	 * LoanRequest Instance
	 */
	currentLoanRequestId$ = this.store.pipe(select(LoanRequestSelectors.selectCurrentLoanRequestId));

	currentLoanRequest$ = this.store.pipe(select(LoanRequestSelectors.selectCurrentLoanRequest)).pipe(
		filter((loanRequest: LoanRequest): loanRequest is LoanRequest => loanRequest !== undefined),
		distinctUntilChanged(
			(previousLoanRequest: LoanRequest, currentLoanRequest: LoanRequest) =>
				previousLoanRequest.lastModified === currentLoanRequest.lastModified && previousLoanRequest.id === currentLoanRequest.id
		)
	);

	currentLoanRequestStatus$ = this.store.pipe(select(LoanRequestSelectors.selectCurrentLoanRequestStatus));
	currentLoanRequestStatusId$ = this.store.pipe(select(LoanRequestSelectors.selectCurrentLoanRequestStatusId));

	loanRequestFirstLoaded$ = this.store.pipe(select(LoanRequestSelectors.loanRequestFirstLoaded));

	gtmEventPayload$: Observable<Pick<GtmEventPayload, 'office'>> = this.store.pipe(
		select(LoanRequestSelectors.selectCurrentLoanRequest),
		map((loanRequest) => {
			return { office: loanRequest?.broker?.name };
		}),
		distinctUntilChanged(deepEqual)
	);

	/**
	 * LoanRequest List Total Count
	 */
	loanRequestTotalCount$ = this.store.pipe(select(LoanRequestSelectors.loanRequestTotalCount));
	loanRequestCountsPerStatus$ = this.store.pipe(select(LoanRequestSelectors.loanRequestCountsPerStatus));

	/**
	 * LoanRequest List Total Amount
	 */
	loanRequestTotalAmount$ = this.store.pipe(select(LoanRequestSelectors.loanRequestTotalAmount));
	loanRequestTotalAmountPerStatus$ = this.store.pipe(select(LoanRequestSelectors.loanRequestTotalAmountPerStatus));

	/**
	 * LoanRequest Filters
	 */
	filters$ = this.store.pipe(select(LoanRequestSelectors.selectFilters));
	search$ = this.store.pipe(select(LoanRequestSelectors.selectSearch));

	/**
	 * LoanRequest List
	 */
	loanRequestList$ = this.store.pipe(select(LoanRequestSelectors.selectAllLoanRequest));
	loadLoanRequestListActionState$ = this.store.pipe(select(LoanRequestSelectors.loadLoanRequestListActionState));
	loadLoanRequestsPerStatusActionState$ = this.store.pipe(select(LoanRequestSelectors.loadLoanRequestsPerStatusActionState));

	loadLoanRequestsTotalAmountPerStatusActionState$ = this.store.pipe(
		select(LoanRequestSelectors.loadLoanRequestsTotalAmountPerStatusActionState)
	);

	loadLoanRequestTotalAmountActionState$ = this.store.pipe(select(LoanRequestSelectors.loadLoanRequestTotalAmountActionState));
	/**
	 * LoanRequest Statuses
	 */
	loanRequestStatuses$ = this.store.pipe(select(LoanRequestSelectors.selectLoanRequestStatuses));
	loanRequestStatusChangePending$ = this.store.pipe(select(LoanRequestSelectors.loanRequestStatusChangePending));

	updateLoanRequestStatusActionState$ = this.store.pipe(select(LoanRequestSelectors.updateLoanRequestStatusActionState));

	/**
	 * LoanRequest Actions State
	 */
	loadLoanRequestActionState$ = this.selectActionState('loadLoanRequest');
	createLoanRequestActionState$ = this.selectActionState('createLoanRequest');
	updateLoanRequestActionState$ = this.selectActionState('updateLoanRequest');
	assignAnalystActionState$ = this.selectActionState('assignAnalyst');
	createDecisionActionState$ = this.selectActionState('createDecision');
	createCommentActionState$ = this.selectActionState('createComment');
	loadDecisionsActionState$ = this.selectActionState('loadDecisions');
	loadCommentsActionState$ = this.selectActionState('loadComments');
	loadHistoriesActionState$ = this.selectActionState('loadHistories');
	getPreapprovalActionState$ = this.selectActionState('getPreapproval');
	loadPreapprovalActionState$ = this.selectActionState('loadPreapproval');

	decisions$ = this.store.pipe(select(LoanRequestSelectors.selectDecisions));
	decisionsStatistics$ = this.store.pipe(select(LoanRequestSelectors.selectDecisionsStatistics));
	comments$ = this.store.pipe(select(LoanRequestSelectors.selectComments));
	histories$ = this.store.pipe(select(LoanRequestSelectors.selectHistories));
	preapproval$ = this.store.pipe(select(LoanRequestSelectors.selectPreapproval));
	financedItems$ = this.store.pipe(select(LoanRequestSelectors.selectFinancedItems));

	constructor(private store: Store<LoanRequestPartialState>) {}

	loadLoanRequest(loanRequestId: number) {
		this.store.dispatch(LoanRequestActions.loadLoanRequest({ loanRequestId }));
	}

	loadLoanRequests(options: LoanRequestLoadParameters = {}, loadTotalAmount = false, removeAll = false, shouldFilterByType = false) {
		this.store.dispatch(
			LoanRequestActions.loadLoanRequests({ parameters: options, loadTotalAmount: loadTotalAmount, removeAll, shouldFilterByType })
		);
	}

	loadLoanRequestsPerStatuses(
		options: LoanRequestLoadParameters = {},
		statuses: string[] = [],
		loadTotalAmount = false,
		shouldFilterByType = false
	) {
		this.store.dispatch(
			LoanRequestActions.loadLoanRequestsPerStatus({ parameters: options, statuses, loadTotalAmount, shouldFilterByType })
		);
		if (loadTotalAmount) {
			// adding timeout to avoid 2 updates of the state at the same time
			setTimeout(() => this.loadLoanRequestsTotalAmountPerStatus(options, statuses), 2000);
		}
	}

	loadLoanRequestsTotalAmountPerStatus(options: LoanRequestLoadParameters = {}, statuses: string[] = []) {
		this.store.dispatch(
			LoanRequestActions.loadLoanRequestsTotalAmountPerStatus({ parameters: options, statuses, loadTotalAmount: true })
		);
	}

	loadMoreLoanRequests(parameters: LoanRequestLoadParameters, shouldFilterByType?: boolean) {
		this.store.dispatch(LoanRequestActions.loadMoreLoanRequests({ parameters, shouldFilterByType }));
	}

	createLoanRequest(loanRequest: Partial<LoanRequest>) {
		this.store.dispatch(LoanRequestActions.createLoanRequest({ loanRequest: loanRequest }));
	}

	updateLoanRequest(loanRequestId: number, loanRequest: Partial<LoanRequest>) {
		this.store.dispatch(LoanRequestActions.updateLoanRequest({ loanRequest: { id: loanRequestId, changes: loanRequest } }));
	}

	updateLoanRequestStatus(loanRequestId: number, loanRequest: Partial<LoanRequest>) {
		this.store.dispatch(LoanRequestActions.updateLoanRequestStatus({ loanRequest: { id: loanRequestId, changes: loanRequest } }));
	}

	setCurrentLoanRequestStatusId(statusId: number) {
		this.store.dispatch(LoanRequestActions.setCurrentLoanRequestStatusId({ statusId }));
	}

	loadLoanRequestStatuses(loanRequestId: number): void {
		this.store.dispatch(LoanRequestActions.loadLoanRequestStatuses({ loanRequestId }));
	}

	setCurrentLoanRequestId(loanRequestId: number | null) {
		if (loanRequestId === null) {
			return this.store.dispatch(LoanRequestActions.resetCurrentLoanRequestId());
		}
		return this.store.dispatch(LoanRequestActions.setCurrentLoanRequestId({ loanRequestId }));
	}

	resetLoanRequestStatusById(loanRequestId: number) {
		return this.store.dispatch(LoanRequestActions.resetLoanRequestStatusById({ loanRequestId }));
	}

	setLoanRequestStatusChangePending(loanRequestId: number) {
		return this.store.dispatch(LoanRequestActions.loanRequestStatusChangePending({ loanRequestId }));
	}

	assignAnalyst(loanRequestId: number, analystId: number) {
		this.store.dispatch(LoanRequestActions.assignAnalystToLoanRequest({ analystId, loanRequestId }));
	}

	clearLoanRequests(): void {
		this.store.dispatch(LoanRequestActions.clearLoanRequests());
	}

	clearState(): void {
		this.store.dispatch(LoanRequestActions.clearState());
	}

	getPreapproval(loanRequestId: number, borrowerMode: BorrowerModes): void {
		this.store.dispatch(LoanRequestActions.getPreapproval({ loanRequestId, borrowerMode }));
	}

	loadPreapproval(loanRequestId: number): void {
		this.store.dispatch(LoanRequestActions.loadPreapproval({ loanRequestId }));
	}

	loadFinancialItems(loanRequestId: number): void {
		this.store.dispatch(LoanRequestActions.loadFinancedItems({ loanRequestId }));
	}

	dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: LoanRequestActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(LoanRequestSelectors.getActionState(actionType)), map(parseActionState));
	}
}

function deepEqual(x, y) {
	const keys1 = Object.keys(x);
	const keys2 = Object.keys(y);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = x[key];
		const val2 = y[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}

	return true;
}

function isObject(object) {
	return object != null && typeof object === 'object';
}
