import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { DateFormatPipe } from './pipes/date-format.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { EnvironmentCurrencyPipe } from './pipes/environment-currency.pipe';
import { TranslateParamsPipe } from './pipes/translate-params.pipe';
import { TranslateResourcePipe } from './pipes/translate-resource.pipe';
import { MonthsToYearsPipe } from './pipes/months-to-years.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { CustomizableCurrencyPipe } from './pipes/customizable-currency.pipe';
import { ProductDurationPipe } from './pipes/product-duration.pipe';
import { AbsolutePipe } from './pipes/absolute.pipe';
import { AcceptanceRuleParameterValuePipe } from './pipes/acceptance-rule-parameter-value.pipe';
import { TranslateACCMessagePipe } from './pipes/translate-acc-message.pipe';
import { DiscountDescriptionPipe } from './pipes/discount-description.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { BorrowerAcceptanceRuleDescriptionPipe } from './pipes/borrower-acceptance-rule-description.pipe';

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [
		DateFormatPipe,
		RelativeDatePipe,
		TimeFormatPipe,
		EnvironmentCurrencyPipe,
		CustomizableCurrencyPipe,
		TranslateParamsPipe,
		TranslateResourcePipe,
		MonthsToYearsPipe,
		PercentagePipe,
		ProductDurationPipe,
		AbsolutePipe,
		AcceptanceRuleParameterValuePipe,
		TranslateACCMessagePipe,
		DiscountDescriptionPipe,
		FullNamePipe,
		BorrowerAcceptanceRuleDescriptionPipe,
	],
	exports: [
		DateFormatPipe,
		RelativeDatePipe,
		TimeFormatPipe,
		EnvironmentCurrencyPipe,
		CustomizableCurrencyPipe,
		TranslateParamsPipe,
		TranslateResourcePipe,
		MonthsToYearsPipe,
		PercentagePipe,
		ProductDurationPipe,
		AbsolutePipe,
		AcceptanceRuleParameterValuePipe,
		TranslateACCMessagePipe,
		DiscountDescriptionPipe,
		FullNamePipe,
		BorrowerAcceptanceRuleDescriptionPipe,
	],
	providers: [
		DateFormatPipe,
		RelativeDatePipe,
		TimeFormatPipe,
		EnvironmentCurrencyPipe,
		CustomizableCurrencyPipe,
		TranslateParamsPipe,
		TranslateResourcePipe,
		TranslatePipe,
		MonthsToYearsPipe,
		PercentagePipe,
		ProductDurationPipe,
		AbsolutePipe,
		AcceptanceRuleParameterValuePipe,
		TranslateACCMessagePipe,
		DiscountDescriptionPipe,
		FullNamePipe,
		BorrowerAcceptanceRuleDescriptionPipe,
	],
})
export class UtilFormattingModule {}
