import { createAction, props } from '@ngrx/store';

import {
	Offer,
	Product,
	ProductExplorerStep,
	ProductSearchFilterParams,
	ProductSortableKeys,
	UniqueProduct,
} from '@oper-client/shared/data-model';

export const setActiveStep = createAction('[ProductExplorer] Set active step', props<{ activeStep: ProductExplorerStep }>());
export const setOfferToUpdate = createAction('[ProductExplorer] Set offer to update', props<{ offerToUpdate: Partial<Offer> }>());
export const setSelectedProducts = createAction('[ProductExplorer] Set selected products', props<{ selectedProducts: Product[] }>());
export const setSearchFilterParams = createAction(
	'[ProductExplorer] Set search filter params',
	props<{ searchFilterParams: ProductSearchFilterParams }>()
);
export const sortProductsByKey = createAction('[ProductExplorer] Sort Products By Key', props<{ sortableKey: ProductSortableKeys }>());
export const reset = createAction('[ProductExplorer] Reset');
export const clearProductSearchFilters = createAction('[ProductExplorer] Clear Product Search Filters');
export const selectProducts = createAction('[ProductExplorer] Select Products', props<{ products: UniqueProduct[] }>());
export const unselectProducts = createAction('[ProductExplorer] Unselect Products', props<{ products: UniqueProduct[] }>());

// Solutions
export const createSolution = createAction('[ProductExplorer] Create Solution]');
export const removeEmptySolutions = createAction('[ProductExplorer] Remove Empty Solutions]');
export const removeSolution = createAction('[ProductExplorer] Remove Solution]', props<{ index: number }>());
export const setActiveSolution = createAction('[ProductExplorer] Set Active Solution]', props<{ index: number }>());
export const updateSolutionProducts = createAction(
	'[ProductExplorer] Update Solution Products]',
	props<{ index: number; products: Product[] }>()
);
