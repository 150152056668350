import { Resource } from './resource.model';

export enum FieldConfigEnum {
	COST_FIELDS = 'financialPlanCostFields',
	AMOUNT_FIELDS = 'financialPlanLoanAmountFields',
	OWN_FUND_FIELDS = 'financialPlanOwnFundFields',
}

export enum ExternalCalculatorType {
	babyLoan = 'babyLoan',
	csokCash = 'csokCash',
	csokMortgage = 'csokMortgage',
	villageCsokMortgage = 'villageCsokMortgage',
	villageCsokCash = 'villageCsokCash',
}
export interface FieldConfig {
	id: number;
	isOverridable?: boolean;
	isCalculated?: boolean;
	isDefault?: boolean;
	isMain?: boolean;
	isLoanNeeded?: boolean;
	externalCalculatorType: Resource;
	defaultRate?: number;
	defaultDuration?: number;
}

export interface FieldConfigExtended extends FieldConfig {
	type: Resource;
}

export interface OwnFundFieldConfig extends FieldConfig {
	ownFundType: Resource;
}

export interface LoanAmountFieldConfig extends FieldConfig {
	loanAmountType: Resource;
}

export interface CostFieldConfig extends FieldConfig {
	costType: Resource;
	costGroupType?: Resource;
}

export interface FinancialPlanConfig {
	id: number;
	financialPlanCostFields: [CostFieldConfig];
	financialPlanLoanAmountFields: [LoanAmountFieldConfig];
	financialPlanOwnFundFields: [OwnFundFieldConfig];
}

export const toCostFieldKey = (key: string): string => `cost${key.replace(/^\w/, (c) => c.toUpperCase())}`;
