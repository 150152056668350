import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAM, IdentityProvider, JoinDetails } from '@oper-client/shared/data-model';
import { IamActionsState } from './iam.reducer';
import { Params } from '@angular/router';
import { Update } from '@ngrx/entity';

export const loadCurrentUser = createAction('[IAM] LoadCurrentUser');
export const loadCurrentUserSuccess = createAction(
	'[IAM] LoadCurrentUser Success',
	props<{
		user: IAM.User;
	}>()
);
export const loadCurrentUserFailure = createAction(
	'[IAM] LoadCurrentUser Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const updateCurrentUser = createAction(
	'[IAM] UpdateCurrentUser',
	props<{
		user: Update<IAM.User>;
	}>()
);
export const updateCurrentUserSuccess = createAction(
	'[IAM] UpdateCurrentUser Success',
	props<{
		user: Update<IAM.User>;
	}>()
);
export const updateCurrentUserFailure = createAction(
	'[IAM] UpdateCurrentUser Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const login = createAction(
	'[IAM] Login',
	props<{
		credentials: IAM.UserCredentials;
		loginSuccessRedirectRoute?: string[];
	}>()
);
export const loginSuccess = createAction(
	'[IAM] Login Success',
	props<{
		tokens: IAM.JwtTokens;
		loginSuccessRedirectRoute?: string[];
	}>()
);
export const loginFailure = createAction(
	'[IAM] Login Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const logout = createAction('[IAM] Logout');
// export const logoutSuccess = createAction('[IAM] Logout Success');
// export const logoutFailure = createAction('[IAM] Logout Failure', props<{ httpError: HttpErrorResponse }>());

export const forgotPassword = createAction(
	'[IAM] Forgot password',
	props<{
		email: string;
	}>()
);
export const forgotPasswordSuccess = createAction('[IAM] Forgot password Success');
export const forgotPasswordFailure = createAction(
	'[IAM] Forgot password Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const resetPassword = createAction(
	'[IAM] Reset password',
	props<{
		payload: IAM.ResetPassword;
	}>()
);
export const resetPasswordSuccess = createAction('[IAM] Reset password Success');
export const resetPasswordFailure = createAction(
	'[IAM] Reset password Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const resetPassword2Fa = createAction(
	'[IAM] Reset password 2FA',
	props<{
		payload: IAM.ResetPassword;
	}>()
);
export const resetPassword2FaSuccess = createAction(
	'[IAM] Reset password 2FA Success',
	props<{
		tokens: IAM.Tokens;
	}>()
);
export const resetPassword2FaFailure = createAction(
	'[IAM] Reset password 2FA Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const activateUser = createAction(
	'[IAM] Activate user',
	props<{
		token: string;
	}>()
);
export const activateUserSuccess = createAction('[IAM] Activate user Success');
export const activateUserFailure = createAction(
	'[IAM] Activate user Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const verifyUser = createAction(
	'[IAM] Verify user',
	props<{
		code: string;
		token: string;
	}>()
);
export const verifyUserSuccess = createAction('[IAM] Verify user Success');
export const verifyUserFailure = createAction(
	'[IAM] Verify user Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const validateCredentials = createAction(
	'[IAM] Validate credentials',
	props<{
		username: string;
		password: string;
	}>()
);
export const validateCredentialsSuccess = createAction(
	'[IAM] Validate credentials Success',
	props<{
		email: string;
		token: string;
		verified: boolean;
	}>()
);
export const validateCredentialsFailure = createAction(
	'[IAM] Validate credentials Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const activateOTP = createAction(
	'[IAM] Activate otp',
	props<{
		token: string;
	}>()
);
export const activateOTPSuccess = createAction('[IAM] Activate otp Success');
export const activateOTPFailure = createAction(
	'[IAM] Activate otp Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const verifyOTP = createAction(
	'[IAM] Verify otp',
	props<{
		token: string;
		code: string;
		loginSuccessRedirectRoute?: string[];
	}>()
);
export const verifyOTPSuccess = createAction(
	'[IAM] Verify otp Success',
	props<{
		access: string;
		refresh: string;
	}>()
);
export const verifyOTPFailure = createAction(
	'[IAM] Verify otp Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const releaseIamActionState = createAction(
	'[IAM] Release ActionsState',
	props<{
		kind: Partial<keyof IamActionsState>;
	}>()
);

export const loadPermissions = createAction('[IAM] Load Permissions');
export const clearPermissions = createAction('[IAM] Clear Permissions');
export const loadPermissionsSuccess = createAction(
	'[Permissions] Load loadPermissions Success',
	props<{
		permissions: string[];
	}>()
);
export const loadPermissionsFailure = createAction(
	'[Permissions] Load loadPermissions Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const loadIdentity = createAction(
	'[IAM] Load identity',
	props<{
		identityProvider: IdentityProvider;
		queryParams?: Params;
		loanRequestId?: number;
	}>()
);
export const loadIdentitySuccess = createAction(
	'[IAM] Load identity Success',
	props<{
		identity: any;
		identityProvider: IdentityProvider;
	}>()
);
export const loadIdentityFailure = createAction(
	'[IAM] Load identity Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const signInToIdentityProvider = createAction(
	'[IAM] Sign in Identity',
	props<{
		authRequest: {
			state: string;
			url: string;
			identityProvider: IdentityProvider;
			authUrl: string;
		};
	}>()
);
export const resetLoadIdentityState = createAction('[IAM] Reset Load Identity State');

export const createUser = createAction(
	'[IAM] CreateUser',
	props<{
		userData: Partial<JoinDetails>;
	}>()
);
export const createUserSuccess = createAction(
	'[IAM] CreateUser Success',
	props<{
		tokens: IAM.JwtTokens;
	}>()
);
export const createUserFailure = createAction(
	'[IAM] CreateUser Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const updateProfile = createAction(
	'[IAM] UpdateProfile',
	props<{
		profileData: Partial<JoinDetails>;
	}>()
);
export const updateProfileSuccess = createAction(
	'[IAM] UpdateProfile Success',
	props<{
		token: string;
	}>()
);
export const updateProfileFailure = createAction(
	'[IAM] UpdateProfile Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const inviteUser = createAction(
	'[IAM] InviteUser',
	props<{
		userData: Partial<JoinDetails>;
	}>()
);
export const inviteUserSuccess = createAction(
	'[IAM] InviteUser Success',
	props<{
		tokens: IAM.JwtTokens;
	}>()
);
export const inviteUserFailure = createAction(
	'[IAM] InviteUser Failure',
	props<{
		httpError: HttpErrorResponse;
	}>()
);

export const resetState = createAction('[IAM] Reset State');

export const ssoLogin = createAction('[IAM] SSO Login');
export const ssoLoginSuccess = createAction('[IAM] SSO Login Success', props<{ tokens: IAM.JwtTokens }>());
export const ssoLoginFailure = createAction('[IAM] SSO Login Failure', props<{ httpError: HttpErrorResponse }>());
