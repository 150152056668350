import { DynamicFormCard, DynamicInputFormItems, InputAsyncSearch, InputField, InputPhone, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { Client, LoanApplicationDto, Ownership, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { FormGroup, Validators } from '@angular/forms';
import { FormConfiguration } from '../models/dynamic-form.model';
import { orderBy } from '@oper-client/shared/util-array';
import { COMMON_REGEX_EXPRESSIONS, DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';
import { ValidatorService } from '../services/validator.service';
import { CUSTOMER_INSIGHTS_CONFIG, debounceTimes } from '@oper-client/shared/configuration';
import { inject } from '@angular/core';
import { GoogleGeocodeService } from '@oper-client/shared/util-google-maps';
import { filter, map, Observable, Subject } from 'rxjs';
import { hideWhenFieldIsFalse } from '../utils/dynamic-form.utils';

export default function (formData?: Partial<LoanApplicationDto>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();

	const extraCollateralForm = new FormConfiguration();
	extraCollateralForm.setName('extraCollateralForm');
	extraCollateralForm.formControl.questions = [
		new InputField({
			key: 'id',
			value: formData?.extraCollateral?.id,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.extraCollateral?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
								'address.box': addressDetails.box || '',
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.extraCollateral?.address?.street || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.extraCollateral?.address?.houseNumber || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.extraCollateral?.address?.box || '',
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.extraCollateral?.address?.city || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.extraCollateral?.address?.zipCode || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.extraCollateral?.address?.country?.id || null,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.extraCollateral?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.extraCollateral?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.extraCollateral?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			type: 'checkbox',
			key: 'hasOwnership',
			label: 'ç.feature.loanRequest.nonOwnershipDeclaration',
			required: false,
			value: formData?.collateralRealty?.clients.length > 0 || false,
		}),
		new InputField({
			key: 'clients',
			value: formData?.clients,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'ownerships',
			value: formData?.collateralRealty?.clients,
			type: 'hidden',
			required: false,
		}),
		new DynamicInputFormItems({
			key: 'owners',
			value: getClientsFromOwnerships(formData?.collateralRealty?.clients, formData?.clients) ?? [],
			debounceTime: debounceTimes.xxs,
			itemTitle: 'ç.feature.loanRequest.owner',
			addItemLabel: 'ç.dialog.assignOwner.title',
			required: true,
			requireOnAdd: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasOwnership'),
			items: [
				new InputField({
					key: 'id',
					type: 'hidden',
					required: false,
				}),
				new InputField({
					key: 'firstName',
					label: 'ç.question.firstName.label',
					type: 'text',
					required: true,
					class: 'span6',
					hideRequiredAsterisk: true,
				}),
				new InputField({
					key: 'lastName',
					label: 'ç.question.lastName.label',
					type: 'text',
					required: true,
					class: 'span6',
					hideRequiredAsterisk: true,
				}),
				new InputSelect({
					key: 'sex.id',
					label: 'ç.question.gender.label',
					required: true,
					hideRequiredAsterisk: true,
					options: resources?.[ResourceType.SEX] || [],
					class: 'span12',
				}),
				new InputSelect({
					key: 'civilStatus.id',
					label: 'ç.question.civilStatus.label',
					required: true,
					hideRequiredAsterisk: true,
					options: resources?.[ResourceType.CIVIL_STATUS] || [],
					class: 'span12',
				}),
				new InputSelect({
					key: 'birthCountry.id',
					label: 'ç.question.birthCountry.label',
					required: true,
					hideRequiredAsterisk: true,
					options: resources?.[ResourceType.COUNTRY] || [],
					class: 'span12',
				}),
				new InputField({
					key: 'birthCity',
					label: 'ç.question.birthCity.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					validators: [],
					class: 'span12',
				}),
				new InputAsyncSearch({
					key: 'addressSearch',
					label: 'ç.feature.addressAutocomplete.searchForYourAddress.ownerAddress.label',
					placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
					bindValue: 'id',
					bindLabel: 'label',
					required: false,
					debounceTime: debounceTimes.m,
					characterThreshold: 2,
					iconName: 'faMagnifyingGlass',
					prefillDefaultValue: false,
					clearAfterSearch: true,
					hideArrow: true,
					class: 'span12',
					endpointSettings: {
						method: (searchTerm: string) => {
							const googleGeocodeService = inject(GoogleGeocodeService);
							const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
							const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
							return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
						},
						transform: (addresses: any[]) => {
							return addresses.map((address) => ({
								id: address.place_id,
								label: address.description,
							}));
						},
						transformSelectedValue: (value: any): any => {
							const googleGeocodeService = inject(GoogleGeocodeService);
							return googleGeocodeService.getAddressDetails(value, value.id, null, resources?.[ResourceType.COUNTRY]);
						},
					},
					transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
						const key = 'addressSearch';
						const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

						return formGroup.get(key)?.valueChanges.pipe(
							filter((placeId) => !!placeId),
							map((addressDetails) => {
								if (addressDetails) {
									formGroup.patchValue({
										'addresses[0].street': addressDetails?.address?.street || null,
										'addresses[0].houseNumber': addressDetails?.address?.houseNumber || null,
										'addresses[0].city': addressDetails?.address?.city || null,
										'addresses[0].zipCode': addressDetails?.address?.zipCode || null,
										'addresses[0].country.id': addressDetails?.address?.country?.id || null,
										'addresses[0].box': addressDetails?.address?.box || '',
									});
								}
								return inputField;
							})
						);
					},
				}),
				new InputField({
					key: 'addresses[0].street',
					label: 'ç.question.street.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span12',
				}),
				new InputField({
					key: 'addresses[0].houseNumber',
					label: 'ç.question.houseNumber.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span6',
				}),
				new InputField({
					key: 'addresses[0].box',
					label: 'ç.question.box.label',
					required: false,
					type: 'text',
					class: 'span6',
					markAsOptional: true,
				}),
				new InputField({
					key: 'addresses[0].city',
					label: 'ç.question.city.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span6',
				}),
				new InputField({
					key: 'addresses[0].zipCode',
					label: 'ç.question.zipCode.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					validators: [
						ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
						Validators.maxLength(8),
					],
					class: 'span6',
				}),
				new InputSelect({
					key: 'addresses[0].country.id',
					label: 'ç.question.country.label',
					options: resources?.[ResourceType.COUNTRY] || [],
					required: true,
					bindLabel: 'key',
					hideRequiredAsterisk: true,
				}),
				new InputPhone({
					key: 'phoneNumbers[0]',
					label: 'ç.question.phoneNumber.label',
					allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
					required: true,
					hideRequiredAsterisk: true,
					class: 'span12',
					validators: [ValidatorService.phoneNumberRequiredValidator()],
					allowedTypes: ['MOBILE'],
				}),
				new InputField({
					key: 'emails[0].value',
					label: 'ç.question.username.label',
					type: 'email',
					required: true,
					hideRequiredAsterisk: true,
					validators: [Validators.email],
					class: 'span12',
				}),
				new InputSelect({
					key: 'nationality.id',
					label: 'ç.question.nationality.label',
					required: true,
					hideRequiredAsterisk: true,
					validators: [],
					options: resources?.[ResourceType.NATIONALITY] || [],
					class: 'span12',
				}),
				new InputField({
					key: 'idCardNumber',
					label: 'ç.feature.aiValidation.field.idCardNumber',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span12',
				}),
				new InputField({
					key: 'idExpirationDate',
					label: 'ç.feature.aiValidation.field.expirationDate',
					type: 'date',
					required: true,
					hideRequiredAsterisk: true,
					min: currentDate,
					validators: [ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST)],
				}),
				new InputField({
					key: 'nationalNumber',
					label: 'ç.question.nationalNumber.label',
					type: 'text',
					required: true,
					hideRequiredAsterisk: true,
					class: 'span12',
				}),
			],
		}),
	];
	const collateralForm = new FormConfiguration();
	collateralForm.setName('collateralForm');
	collateralForm.formControl.questions = [
		new InputField({
			key: 'id',
			value: formData?.extraCollateral?.id,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	const questions: InputBase<any>[] = [
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	const hasCollateral = formData?.collateralRealty?.id || formData?.extraCollateral?.id;
	if (hasCollateral) {
		if (
			formData?.collateralRealty?.purposes?.length === 1 &&
			formData?.collateralRealty?.purposes?.find((x) => x.definition === 'collateral')
		) {
			questions.push(
				new DynamicFormCard({
					title: 'ç.misc.additionalCollateral',
					key: 'extraCollateral',
					value: formData?.extraCollateral,
					formConfiguration: extraCollateralForm,
					showDeleteButton: !formData?.extraCollateral?.id,
					secondaryActionLabel: 'ç.misc.action.addCollateral',
					secondaryAction: true,
					forceExpand: true,
					required: false,
					requiredOnOpen: true,
				})
			);
		} else {
			questions.push(
				new DynamicFormCard({
					title: 'ç.misc.additionalCollateral',
					key: 'extraCollateral',
					value: formData?.extraCollateral,
					formConfiguration: collateralForm,
					showDeleteButton: !formData?.extraCollateral?.id,
					secondaryActionLabel: 'ç.misc.action.addCollateral',
					secondaryAction: true,
					forceExpand: true,
					required: false,
				})
			);
		}
	}
	return questions;
}

function getClientsFromOwnerships(owners: Partial<Ownership>[], clients: Client[]): Client[] {
	const clientsFromOwners: Client[] = [];
	if (owners && owners.length > 0) {
		owners.forEach((owner) => {
			clientsFromOwners.push(clients.find((client) => client?.id === owner?.client?.id));
		});
	}
	return clientsFromOwners;
}
