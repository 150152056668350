import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	input,
	Input,
	OnInit,
	Optional,
	Output,
	Self,
} from '@angular/core';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { debounceTime, map, withLatestFrom } from 'rxjs/operators';
import { DestroyableComponent } from '@shared/util-component';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { UtilService } from '@oper-client/shared/util-formatting';
import { debounceTimes } from '@oper-client/shared/configuration';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { removeNullValues } from '@oper-client/shared/util-object';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'oper-client-dynamic-input-form-items',
	templateUrl: './dynamic-input-form-items.component.html',
	styleUrl: './dynamic-input-form-items.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputFormItemsComponent extends DestroyableComponent implements OnInit, AfterViewInit, ControlValueAccessor {
	private destroyRef = inject(DestroyRef);

	readonly formReferences$ = new BehaviorSubject<FormGroupWithWarning[]>([]);
	readonly removeRow$ = new Subject<number>();
	readonly formChanged$ = new Subject<{ index: number; form: FormGroupWithWarning }>();

	readonly itemTitle = input<string>('');
	readonly cardTitle = input<string>('');
	readonly showAsCard = input<boolean>(false);
	readonly showDeleteButton = input<boolean>(false);
	readonly requireOnAdd = input<boolean>(false);
	@Input() rows: FormConfiguration[];
	@Input() debounceTime = debounceTimes.s;
	@Input() addItemLabel: string;
	@Input() disabled = false;

	@Output() valueChange = new EventEmitter<any>();
	@Output() removeRow = new EventEmitter<number>();
	@Output() addRow = new EventEmitter<void>();

	parentForm: FormGroupWithWarning;
	readonly nestedFormControlName = 'nestedFormControl';

	readonly hasInvalidForm$ = this.formReferences$.pipe(map((forms) => forms.some((form) => form.invalid)));

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	constructor(
		@Self() @Optional() public control: NgControl,
		readonly utilService: UtilService
	) {
		super();
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.formReferences$
			.pipe(
				debounceTime(this.debounceTime),
				map((forms) =>
					forms
						.map((form) => removeNullValues(this.utilService.erectObject(form.value)))
						.filter((form) => Object.keys(form).length > 0)
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((values) => {
				this.valueChange.emit(values);
			});
	}

	ngOnInit(): void {
		this.parentForm = this.control.control.parent as FormGroupWithWarning;

		this.removeRow$
			.pipe(withLatestFrom(this.formReferences$), takeUntilDestroyed(this.destroyRef))
			.subscribe(([index, formReferences]) => {
				const forms = formReferences.filter((_, i) => i !== index);
				this.formReferences$.next(forms);
				if (forms.length === 0) {
					this.valueChange.emit([]);
				}
				this.removeRow.emit(index);
			});

		this.formChanged$
			.pipe(withLatestFrom(this.formReferences$), takeUntilDestroyed(this.destroyRef))
			.subscribe(([{ index, form }, formReferences]) => {
				if (formReferences[index]) {
					formReferences[index] = form;
				} else {
					formReferences.push(form);
				}
				this.formReferences$.next(formReferences);
			});

		if (!this.requireOnAdd()) return;
		this.hasInvalidForm$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isInvalid) => {
			if (isInvalid) {
				if (!this.parentForm.contains(this.nestedFormControlName)) {
					this.parentForm.addControl(this.nestedFormControlName, new FormControl(null, Validators.required));
				}
			} else {
				if (this.parentForm.contains(this.nestedFormControlName)) {
					this.parentForm.removeControl(this.nestedFormControlName);
				}
			}
		});

		this.destroyRef.onDestroy(() => {
			if (this.parentForm.contains(this.nestedFormControlName) && this.requireOnAdd()) {
				this.parentForm.removeControl(this.nestedFormControlName);
				this.formReferences$.complete();
			}
		});
	}

	writeValue(obj: any[]): void {}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(disabled: boolean): void {}
}
