import { DynamicInputFormItems, HeaderSection, InputField, InputSelect, PercentageInputField, Section } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import {
	BorrowerSimulationDto,
	creditLiabilityDefinitions,
	LiabilityTypeEnum,
	PartialNormalizedResource,
	ResourceType,
} from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';

export default function (formData?: Partial<BorrowerSimulationDto>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const mortgageLoanTypes: LiabilityTypeEnum[] = [LiabilityTypeEnum.MortgageMovable, LiabilityTypeEnum.MortgageImmovable];
	const liabilityTypes = resources?.[ResourceType.LIABILITY_TYPE] ?? [];
	return [
		new Section({
			class: 'span12',
		}),
		new HeaderSection({
			title: 'ç.feature.selfService.steps.additionalFinance.title',
			subtitle: 'ç.feature.selfService.steps.additionalFinance.description',
		}),
		new DynamicInputFormItems({
			key: 'mortgageRefinances',
			value: formData?.mortgageRefinances,
			debounceTime: debounceTimes.xxs,
			addItemLabel: 'ç.misc.action.addMortgageLoanToRefinance',
			required: false,
			secondaryAction: true,
			cardTitle: 'ç.misc.mortgageLoanToRefinance',
			secondaryActionLabel: 'ç.misc.action.addMortgageLoanToRefinance',
			showAsCard: true,
			requireOnAdd: true,
			showDeleteButton: true,
			items: [
				new InputSelect({
					key: 'liabilityType.id',
					label: 'ç.question.typeOfLoan.label',
					required: true,
					hideRequiredAsterisk: true,
					appendTo: null,
					options: liabilityTypes?.filter((item) => mortgageLoanTypes.includes(item.definition as LiabilityTypeEnum)),
					class: 'span12',
				}),
				new InputField({
					key: 'valueOfTheRealty',
					label: 'ç.question.valueOfTheProperty.label',
					type: 'text',
					currency: true,
					required: true,
					hideRequiredAsterisk: true,
				}),
				new DynamicInputFormItems({
					key: 'tranches',
					value: formData.mortgageRefinances,
					debounceTime: debounceTimes.xxs,
					itemTitle: 'ç.misc.tranche',
					addItemLabel: 'ç.misc.action.addTranche',
					requireOnAdd: true,
					required: false,
					items: [
						new InputField({
							key: 'originalLoanAmount',
							label: 'ç.feature.mortgageSimulator.originalLoanAmount',
							type: 'number',
							required: true,
							currency: true,
							class: 'span12',
							hideRequiredAsterisk: true,
						}),
						new InputField({
							key: 'outstandingBalance',
							label: 'ç.question.refinancingOutstandingBalance.label',
							type: 'number',
							required: true,
							currency: true,
							class: 'span12',
							hideRequiredAsterisk: true,
						}),
						new PercentageInputField({
							key: 'interestRate',
							label: 'ç.feature.mortgageSimulator.interestRateOnThisLoan',
							required: true,
							type: 'number',
							class: 'span12',
							hideRequiredAsterisk: true,
						}),
					],
				}),
				new InputField({
					key: 'isCollateral',
					label: 'ç.feature.mortgageSimulator.isCollateral',
					type: 'checkbox',
					class: 'span12',
					required: false,
				}),
			],
		}),
		new DynamicInputFormItems({
			key: 'nonMortgageRefinances',
			value: formData?.nonMortgageRefinances,
			debounceTime: debounceTimes.xxs,
			addItemLabel: 'ç.misc.action.addNonMortgageLoanToRefinance',
			requireOnAdd: true,
			required: false,
			secondaryAction: true,
			cardTitle: 'ç.misc.nonMortgageLoanToRefinance',
			secondaryActionLabel: 'ç.misc.action.addNonMortgageLoanToRefinance',
			showAsCard: true,
			showDeleteButton: true,

			items: [
				new InputSelect({
					key: 'liabilityType.id',
					label: 'ç.question.typeOfLoan.label',
					required: true,
					hideRequiredAsterisk: true,
					appendTo: null,
					options: liabilityTypes?.filter(
						(item) =>
							creditLiabilityDefinitions.includes(item.definition) &&
							!mortgageLoanTypes.includes(item.definition as LiabilityTypeEnum)
					),
					class: 'span12',
				}),
				new InputField({
					key: 'outstandingBalance',
					label: 'ç.question.refinancingOutstandingBalance.label',
					type: 'number',
					required: true,
					currency: true,
					class: 'span12',
					hideRequiredAsterisk: true,
				}),
				new PercentageInputField({
					key: 'interestRate',
					label: 'ç.feature.mortgageSimulator.interestRateOnThisLoan',
					required: true,
					type: 'number',
					class: 'span12',
					hideRequiredAsterisk: true,
					validators: [Validators.min(0), Validators.max(20)],
				}),
			],
		}),
	];
}
