import { HttpErrorResponse } from '@angular/common/http';
import { OperHttpError } from './http-error.model';
import { distinctUntilChanged, map, Observable } from 'rxjs';

export interface FeatureState {
	show: boolean;
	enabled: boolean;
	tooltip: string;
}

export interface ActionState {
	processing: boolean;
	httpError: HttpErrorResponse | undefined;
	success?: boolean;
	ids?: any;
}

export const initialActionState: ActionState = {
	...{
		processing: false,
		success: false,
		httpError: undefined,
		ids: null,
		credentials: null,
	},
};

export enum ActionTypes {
	loading = 'Loading',
	success = 'Success',
	failure = 'Failure',
	init = 'Init',
}

export function setActionState(state: ActionState, type: ActionTypes, error: HttpErrorResponse = null, ids: any = null): ActionState {
	switch (type) {
		case ActionTypes.loading:
			return {
				...state,
				processing: true,
				httpError: undefined,
				success: false,
				ids: ids,
			};
		case ActionTypes.success:
			return {
				...state,
				processing: false,
				httpError: undefined,
				success: true,
				ids: ids,
			};
		case ActionTypes.failure:
			return {
				...state,
				processing: false,
				httpError: error,
				success: false,
				ids: ids,
			};
		case ActionTypes.init:
			return {
				...initialActionState,
			};
		default:
			return state;
	}
}

export interface ParsedActionState {
	processing: boolean;
	httpError: OperHttpError | undefined;
	success?: boolean;
	ids?: any;
}

export function getProcessingState(actionState$: Observable<{ processing: boolean }>) {
	return actionState$.pipe(
		map(({ processing }) => processing),
		distinctUntilChanged()
	);
}
