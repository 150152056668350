import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (): InputBase<any>[] {

	return [
		new InputField({
			key: 'city',
			placeholder: 'Placeholder',
			class: 'span12',
            label: 'City'
		}),
		new InputField({
			key: 'townLot',
			placeholder: 'Placeholder',
			class: 'span12',
            label: 'LOT Number'
		}),
	];
}
