import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IRealtyService } from '@oper-client/shared/data-access';
import { Ownership, Realty } from '@oper-client/shared/data-model';
import { map } from 'rxjs/operators';

@Injectable()
export class RealtyService implements IRealtyService {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<Realty[]> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/realties/`, params)
			.pipe(map((realities) => realities.map((el) => this.dtoToRealty(el))));
	}

	get(loanRequestId: number, propertyId: number, params: HttpParams = new HttpParams()): Observable<Realty> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`, params)
			.pipe(map((realty) => this.dtoToRealty(realty)));
	}

	create(loanRequestId: number, property: Realty, params: HttpParams = new HttpParams()): Observable<Realty> {
		return this.apiService
			.post(`/api/loan-requests/${loanRequestId}/realties/`, property, params)
			.pipe(map((realty) => this.dtoToRealty(realty)));
	}

	update(
		loanRequestId: number,
		propertyId: number,
		property: Partial<Realty>,
		params: HttpParams = new HttpParams()
	): Observable<Realty> {
		return this.apiService
			.patch(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`, this.realtyToDto(property), params)
			.pipe(map((realty) => this.dtoToRealty(realty)));
	}

	updateOwnership(loanRequestId: number, realtyId: number, payload?: Partial<Realty>): Observable<Ownership[]> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/realties/${realtyId}/collateral-ownership/`, payload);
	}

	delete(loanRequestId: number, propertyId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`);
	}

	dtoToRealty(item): Realty {
		const realty = { ...item };
		const address = item?.address;
		if (address && address.latitude && address.longitude) {
			realty.address = {
				...address,
				mapConfig: {
					coordinates: { lat: address.latitude, lng: address.longitude },
					pov: {
						heading: address.heading || 0,
						pitch: address.pitch || 0,
					},
					zoom: address.zoom !== null ? address.zoom : 1,
				},
			};

			delete realty.address.latitude;
			delete realty.address.longitude;
			delete realty.address.pitch;
			delete realty.address.heading;
			delete realty.address.zoom;
		}

		return realty;
	}

	realtyToDto(realty: Partial<Realty>) {
		const dtoRealty: any = { ...realty };

		if (realty.address) {
			dtoRealty.address = {
				...realty.address,
				latitude: realty.address.mapConfig?.coordinates?.lat,
				longitude: realty.address.mapConfig?.coordinates?.lng,
				heading: realty.address.mapConfig?.pov?.heading,
				pitch: realty.address.mapConfig?.pov?.pitch,
				zoom: realty.address.mapConfig?.zoom,
			};

			delete dtoRealty.address.mapConfig;
		}

		return dtoRealty;
	}
}
