import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { isMoment, Moment } from 'moment';
import 'moment/min/locales.min.js';
import { OverlayService } from '@oper-client/shared/overlay';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { faCalendar, faExclamationCircle, faQuestionCircle, IconDefinition } from '@oper-client/shared/util-fontawesome';

import { EnvironmentNumberFormatService, NumberInputOptions } from '@oper-client/shared/util-formatting';
import { InputBase } from '../../models/input-base.model';
import { DynamicFormCard, DynamicInputFormItems, DynamicInputTable, InputField } from '../../models/input-types.model'; //TODO we should only deal with InputBase here
import { ValidatorService } from '../../services/validator.service';
import { debounceTimes, NUMBER_FORMAT_OPTIONS, NumberFormatOptions } from '@oper-client/shared/configuration';
import { DestroyableComponent } from '@shared/util-component';
import { matDateFormatsFactory } from '@oper-client/shared/util-bootstrap';
import { CustomerService } from '@oper-client/ui';

const useAsteriskMap = {
	button: false,
	date: true,
	time: true,
	email: true,
	number: true,
	password: true,
	radio: true,
	text: true,
	tel: true,
	select: true,
	percentage: true,
};

// FIXME: We should refactor the entire component and break it down to smaller chunks
@Component({
	selector: 'oper-client-dynamic-form-question',
	templateUrl: './dynamic-form-question.component.html',
	styleUrls: ['./dynamic-form-question.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DynamicFormQuestionComponent extends DestroyableComponent implements OnInit {
	@Input() formId: string;
	@Input() formControlId: string;
	@Input() formName: string;
	@Input() question: InputBase<any>;
	@Input() numberOfQuestions: number;
	@Input() form: FormGroup;
	@Input() markAsTouched: boolean;
	@Input() debounceTime: number;
	@Input() keydownDebounceExtension: boolean;
	@Output() valueChange = new EventEmitter();
	@Output() labelLinkClick = new EventEmitter();
	@Output() externalActionClick = new EventEmitter<{ questionKey: string }>();

	readonly errorIcon = faExclamationCircle;
	readonly questionIcon = faQuestionCircle;

	public faCalendar: IconDefinition = faCalendar;
	public isMobile = this.deviceService.isMobile();
	public isDesktop = !this.isMobile;
	public minDate: string;
	public maxDate: string;
	public expanded: boolean;

	public environmentCurrencySymbol: string = this.environmentNumberFormatService.getCurrencySymbol();
	public defaultCurrencyInputOptions$: Observable<NumberInputOptions> =
		this.environmentNumberFormatService.defaultCurrencyInputOptions$.pipe(
			map((defaultCurrencyInputOptions) => ({
				...defaultCurrencyInputOptions,
				min: (<InputField>this.question).min,
				max: (<InputField>this.question).max,
				digitsInfo: (<InputField>this.question).digitsInfo || this.numberFormatOptions.currencyDigitsInfo,
			})),
			takeUntil(this.destroy$)
		);
	public defaultPercentageInputOptions$: Observable<NumberInputOptions> =
		this.environmentNumberFormatService.defaultPercentageInputOptions$.pipe(
			map((defaultCurrencyInputOptions) => ({
				...defaultCurrencyInputOptions,
				min: (<InputField>this.question).min,
				max: (<InputField>this.question).max,
				digitsInfo: (<InputField>this.question).digitsInfo || this.numberFormatOptions.percentageDigitsInfo,
			})),
			takeUntil(this.destroy$)
		);
	public defaultDecimalInputOptions$: Observable<NumberInputOptions> =
		this.environmentNumberFormatService.defaultDecimalInputOptions$.pipe(
			map((defaultCurrencyInputOptions) => ({
				...defaultCurrencyInputOptions,
				min: (<InputField>this.question).min,
				max: (<InputField>this.question).max,
				digitsInfo: (<InputField>this.question).digitsInfo || this.numberFormatOptions.decimalDigitsInfo,
			})),
			takeUntil(this.destroy$)
		);
	public get errorMessage(): string {
		return (
			this.form?.controls[this.question.key]?.errors &&
			this.validatorService.formatValidationErrors(this.form?.controls[this.question.key]?.errors, this.question)[0]
		);
	}
	public isFocused: boolean;
	public mask: string;

	@ViewChild('picker') datePicker: MatDatepicker<Moment>;
	@ViewChild('dateInput') dateInput: ElementRef;
	private valueChange$: Subject<void> = new Subject<void>();

	private static formatDate(date: Date, format = 'yyyy-MM-DD'): string {
		return moment(date).format(format);
	}

	private static formatMoment(date: Moment, format = 'yyyy-MM-DD'): string {
		return date.format(format);
	}

	/**
	 *
	 * @property {boolean} expanded
	 * @property {InputBase<any>} question
	 *
	 * @returns {boolean}
	 *
	 * Question should be expanded if secondaryAction = true and when field has a value already
	 *
	 */
	get isQuestionExpanded(): boolean {
		if (this.question.secondaryAction) {
			if (this.question.disabled || this.question.forceExpand) {
				return true;
			}

			if (typeof this.question.value === 'number' && this.question.value > 0) {
				return true;
			}

			if (Array.isArray(this.question.value) && this.question.value.length > 0) {
				return true;
			}

			return this.expanded;
		} else {
			return true;
		}
	}

	constructor(
		readonly validatorService: ValidatorService,
		readonly environmentNumberFormatService: EnvironmentNumberFormatService,
		readonly overlayService: OverlayService,
		@Inject(NUMBER_FORMAT_OPTIONS) readonly numberFormatOptions: NumberFormatOptions,
		@Inject(MAT_DATE_FORMATS) protected readonly dateFormats: MatDateFormats,
		private deviceService: DeviceDetectorService,
		private dateAdapter: DateAdapter<any>,
		private customerService: CustomerService,
		private translateService: TranslateService
	) {
		super();
	}

	ngOnInit() {
		//TODO getCurrencyInputOptions and setDateRange are related to specific controls
		this.setDateRange();
		this.valueChangedDebounce();

		this.form?.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				filter(() => !!this.question?.updateValidityOnFormValueChanges),
				tap(() => {
					const control: FormControl = this.form.controls[this.question.key] as FormControl;
					if (this.question.disabled) {
						control.disable();
					}
					control.markAsTouched();
				})
			)
			.subscribe();

		if (this.question instanceof InputField && this.question?.type === 'hidden' && this.question.configureOnHidden) {
			this.configureDateFields(this.translateService.currentLang);
		}

		if (this.question instanceof InputField && this.question?.type === 'date') {
			this.configureDateFields(this.translateService.currentLang);
		}
	}

	configureDateFields(locale: string) {
		if (this.isDesktop) {
			// since MatDatePicker is only used in Desktop mode, clear all the dynamically added validators from matDatePicker input directive.
			this.setOnlyConfiguredValidators();

			this.applyDateFormat(locale);
			this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((params: LangChangeEvent) => {
				if (params.lang) {
					this.applyDateFormat(params.lang);
				}
			});
		}
	}

	applyDateFormat(locale: string) {
		this.dateAdapter.setLocale(locale);

		const matDateFormats = matDateFormatsFactory(locale);
		this.dateFormats.parse = matDateFormats.parse;
		this.dateFormats.display = matDateFormats.display;

		const dateFormatKey = `ç.misc.datePicker.placeholder.${this.dateFormats?.display?.dateInput}`;
		this.mask = this.translateService.instant(dateFormatKey);
		if (this.mask === dateFormatKey) {
			this.dateFormats?.display?.dateInput?.toLowerCase();
		}
	}

	dateInputClick() {
		if (this.dateInput?.nativeElement && typeof this.dateInput.nativeElement.showPicker === 'function') {
			this.dateInput.nativeElement.showPicker();
			this.dateInput.nativeElement.focus();
		}
	}

	clearValue() {
		this.form?.controls[this.question.key]?.setValue(undefined);
		this.valueChange$.next();
	}

	onBlur() {
		this.isFocused = false;
	}

	onFocus() {
		this.isFocused = true;
		if (this.question.required) {
			this.form?.controls?.[this.question.key]?.markAsTouched();
		}
	}

	onTextChange(event) {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		const question: InputField = this.question as InputField;
		let value = event;
		if (question.type === 'checkbox') {
			value = !event;
		}
		if (question.transform || question.multiline || question.type === 'checkbox') {
			// Not emmiting the value to prevent recursive call of value change
			control?.setValue(question.transform ? question.transform(value) : value, {
				emitEvent: true,
				emitViewToModelChange: false,
			});
		}
		this.valueChange$.next();
	}

	onRadioSelectChange(value): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;

		control?.setValue(this.question.transform ? this.question.transform(value) : value, {
			emitEvent: true,
			emitViewToModelChange: false,
		});

		this.valueChange$.next();
	}

	onPhoneNumberChange(value) {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue(value, {
			emitEvent: true,
			emitViewToModelChange: false,
		});
		this.valueChange$.next();
	}

	onSelectionChange() {
		this.valueChange$.next();
	}

	onSwitchChange() {
		this.valueChange$.next();
	}

	/**
	 * Handles changes in asynchronous search component (select with dropdown)
	 * Sets the value of the corresponding form control without triggering a full model update immediately,
	 *
	 * @param value The new value selected after asynchronous data for selection fetch.
	 */
	onAsyncSelectionChange(value: any) {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue(value, {
			emitEvent: true,
			emitViewToModelChange: false,
		});
		this.valueChange$.next();
	}

	onInputSingleRowChange(value: any) {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue(value, {
			emitEvent: true,
			emitViewToModelChange: false,
		});
		this.valueChange$.next();
	}

	onLabelLinkClick(event: Event) {
		if ((event.target as HTMLElement).classList.contains('link')) {
			this.labelLinkClick.emit();
		}
	}

	onLabelClick(event: Event): void {
		// skipping the click event when a link is clicked, since there is a other event for this purpose
		if (this.question.onLabelClick && !(event.target as HTMLElement)?.classList?.contains('link')) {
			this.question.onLabelClick();
		}
	}

	datePickerChanged(event: MatDatepickerInputEvent<any> | null) {
		const dateString = isMoment(event?.value) ? DynamicFormQuestionComponent.formatMoment(event.value) : event?.value || '';
		if (this.form?.controls[this.question.key]?.value !== dateString) {
			this.question.value = dateString;
			this.form?.controls?.[this.question.key]?.markAsTouched();
			this.form?.controls?.[this.question.key]?.setValue(dateString);
			this.form?.controls?.[this.question.key]?.markAsDirty();
			this.valueChange$.next();
		}
	}

	public onKeydown(): void {
		if (this.keydownDebounceExtension) {
			// re-triggering debounce on observable
			this.valueChange$.next();
		}
	}

	getValidity(): boolean {
		return this.form.controls[this.question.key].valid;
	}

	shouldShowAsterisk(): boolean {
		if (typeof (this.question as InputField).type === 'undefined') {
			return this.question?.required && !this.question.hideRequiredAsterisk;
		}

		return this.question?.required && useAsteriskMap[(this.question as InputField).type] && !this.question?.hideRequiredAsterisk;
	}

	onSingleRowRemoveCard(): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue(null, { emitEvent: true, emitViewToModelChange: false });
		this.collapseQuestion();
	}

	onDynamicTableValueChange(value: any): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue(value, { emitEvent: true, emitViewToModelChange: false });

		this.valueChange$.next();
	}

	onDynamicTableAddRow(): void {
		const table = <DynamicInputTable>this.question;
		table.addNewRow();
	}

	onDynamicFormItemsAddRow(): void {
		const form = <DynamicInputFormItems>this.question;
		form.addNewRow();
	}

	onDynamicTableRemoveCard(): void {
		const table = <DynamicInputTable>this.question;
		table.updateRowsByValue([]);

		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		control?.setValue([], { emitEvent: true, emitViewToModelChange: false });
		this.valueChange$.next();

		this.collapseQuestion();
	}

	onDynamicTableRemoveRow(index: number): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		const filteredValue = ((control.value as any[]) ?? []).filter((_, i) => i !== index);

		const table = <DynamicInputTable>this.question;
		table.updateRowsByValue(filteredValue);

		control?.setValue(filteredValue, { emitEvent: true, emitViewToModelChange: false });
		this.valueChange$.next();

		if (table.rows.length === 0) {
			this.collapseQuestion();
		}
	}

	onDynamicFormItemsRemoveRow(index: number): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		const filteredValue = ((control.value as any[]) ?? []).filter((_, i) => i !== index);

		const table = <DynamicInputFormItems>this.question;
		table.updateRowsByValue(filteredValue);

		control?.setValue(filteredValue, { emitEvent: true, emitViewToModelChange: false });
		this.valueChange$.next();

		if (index === 0) {
			this.collapseQuestion();
		}
	}

	onDynamicFormCardValueChange(value: any): void {
		const control: FormControl = this.form.controls[this.question.key] as FormControl;
		const question: DynamicFormCard = this.question as DynamicFormCard;
		// extraction of nested object
		/**
		 * Following logic is doing:
		 * 1. (Object.keys(value ?? {}).length === 1    // checks if the value is a single object
		 * 2. this.question.key in value  // checks if the key of the dynamic card is the same (as single object) as nested control
		 * 3. question.extractSingleNestedFormControl  // new propriety for DynamicCard to allow this extraction
		 *
		 * 4. If all checks are true, value of DynamicCard becomes value of nested control (eg: notary: {notary: {...}} becomes notary: {...})
		 */
		if (Object.keys(value ?? {}).length === 1 && this.question.key in value && question.extractSingleNestedFormControl) {
			value = value[this.question.key];
		}
		control?.setValue(value, {
			emitEvent: true,
			emitViewToModelChange: false,
		});

		this.valueChange$.next();
	}

	removeDynamicFormCard(key: string): void {
		if (this.question.secondaryAction) {
			const control: FormControl = this.form.controls[this.question.key] as FormControl;
			control?.setValue(null, { emitEvent: true, emitViewToModelChange: false });
			this.question.forceExpand = false;
			this.collapseQuestion();
		} else {
			this.form.removeControl(key);
		}
		this.valueChange$.next();
	}

	private setDateRange(): void {
		const inputField = this.question as InputField;
		if (inputField?.type === 'date' || inputField.type === 'hidden') {
			this.minDate = (this.question?.['min'] && DynamicFormQuestionComponent.formatDate(this.question['min'])) || null;
			this.maxDate = (this.question?.['max'] && DynamicFormQuestionComponent.formatDate(this.question['max'])) || null;
		}
	}

	private valueChangedDebounce(): void {
		const debounceTimeValue: number = this.debounceTime || 0;
		this.valueChange$
			.pipe(
				debounceTime(debounceTimeValue),
				tap(() => {
					this.valueChange.emit();
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	/**
	 * Used to set only the custom configured InputField validators and
	 * clear the dynamically added validators from used directives like, for example, from Angular MatDatePicker input directive: matDatepickerMin, matDatepickerMax
	 */
	private setOnlyConfiguredValidators(): void {
		setTimeout(() => {
			this.form.controls[this.question.key].clearValidators();
			this.form.controls[this.question.key].setValidators(this.question.validators);
			this.form.controls[this.question.key].updateValueAndValidity();
		}, debounceTimes.s);
	}

	get customerName(): string {
		const name = this.customerService.getCustomer();
		return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
	}

	expandQuestion(): void {
		this.expanded = true;
	}

	collapseQuestion(): void {
		this.expanded = false;
	}
}
