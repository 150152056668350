import { Client } from './client.model';
import { CreditProvider } from './offer.model';
import { Resource } from './resource.model';
import { ConsultationBorrower, ConsultationClient, ConsultationInstrument } from './loan-request.model';

export interface Liability {
	id: number;
	client: Partial<Client>;
	amount: number;
	monthlyAmount?: number;
	periodicity: Resource;
	liabilityType: Resource;
	// TODO RentalLiability extends Liability
	goneAfterRequest?: boolean;
	// TODO AlimonyLiability extends Liability
	startDate?: string;
	handlingCosts?: number;
	endDate?: string;
	outstandingAmount?: number;
}

export interface CreditLiability extends Liability {
	creditProvider: CreditProvider;
	regularity?: Resource;
	takeover: Resource;
	contractNumber?: string;
	balance?: number;
	duration?: number;
	initialAmount?: number;
	interestRate?: number;
	handlingCosts: number;
	earlyRepaymentFee: number;
	irregularityStartDate?: string;
	deedDate?: string;
	startDate?: string;
	isMax2Tranches18MonthsApart?: boolean;
}

export interface LiabilityConsultation {
	id: number;
	borrower: ConsultationBorrower;
	instruments: ConsultationInstrument[];
	client: ConsultationClient;
	hasPdfDocument: boolean;
	created: string;
	consultationNumber: string;
	replyCode: string;
	replyDescription: string;
	loanRequest: number;
	originalReport: number;
}

export interface CalculateAmortization
	extends Omit<
		CreditLiability,
		| 'initialAmount'
		| 'monthlyAmount'
		| 'creditProvider'
		| 'regularity'
		| 'takeover'
		| 'contractNumber'
		| 'deedDate'
		| 'irregularityStartDate'
	> {
	amortization: number;
	loanType: Resource;
}

export interface CalculateMonthlyAmortization {
	output: {
		amortization: number;
		theoreticalInterestRate: number;
	};
	input?: {
		initialAmount?: number;
		liabilityType?: Resource;
	};
}

export function buildCalculateAmortization(creditLiability: Partial<CreditLiability>, loanType: Resource): CalculateAmortization {
	if (!creditLiability || !loanType || !creditLiability.liabilityType) {
		return null;
	}
	const calculateAmortization = <CalculateAmortization>{};
	if (creditLiability.initialAmount) {
		calculateAmortization.amount = creditLiability.initialAmount;
	}
	if (creditLiability.duration) {
		calculateAmortization.duration = creditLiability.duration;
	}
	if (creditLiability.startDate) {
		calculateAmortization.startDate = creditLiability.startDate;
	}
	if (creditLiability.interestRate) {
		calculateAmortization.interestRate = creditLiability.interestRate / 100;
	}
	if (creditLiability.monthlyAmount) {
		calculateAmortization.amortization = creditLiability.monthlyAmount;
	}
	if (creditLiability.handlingCosts) {
		calculateAmortization.handlingCosts = creditLiability.handlingCosts;
	}
	calculateAmortization.loanType = { id: loanType.id };
	calculateAmortization.liabilityType = { id: creditLiability.liabilityType.id };
	return calculateAmortization;
}

export function applyCalculatedAmortization(creditLiability: Partial<CreditLiability>, calculateAmortization: CalculateAmortization): void {
	if (!creditLiability || !calculateAmortization) {
		return;
	}
	if (calculateAmortization.earlyRepaymentFee) {
		creditLiability.earlyRepaymentFee = calculateAmortization.earlyRepaymentFee;
	}
	if (calculateAmortization.balance) {
		creditLiability.balance = calculateAmortization.balance;
	}
	if (calculateAmortization.interestRate) {
		creditLiability.interestRate = calculateAmortization.interestRate;
	}
	if (calculateAmortization.amortization) {
		creditLiability.monthlyAmount = calculateAmortization.amortization;
	}
	if (calculateAmortization.handlingCosts) {
		creditLiability.handlingCosts = calculateAmortization.handlingCosts;
	}
}

export const creditLiabilityDefinitions = [
	'consumerLoan',
	'consumerLoanImmovable',
	'consumerLoanMovable',
	'mortgageImmovable',
	'mortgageMovable',
	'leasing',
	'creditCard',
	'creditLine',
	'generalCreditExpense',
	'investmentCredit',
	'purchaseCredit',
	'otherCredit',
	'municipalLoan',
	'employerLoan',
	'privateLoan',
	'studentLoan',
	'homeSavingsBankLoan',
	'otherGuaranteeObligation',
	'budgetAidToBeReimbursed',
	'advanceLoan',
	'commodityLoan',
	'motorLoan',
	'foreignLoan',
	'csokMortgage',
	'babyExpectingLoan',
	'lifeInsuranceLoan',
	'businessLoan',
	'farmersLoan',
	'vCSOK',
	'MFB',
];

export const calculatedCreditLiabilityDefinitions = creditLiabilityDefinitions.filter(
	(item) => item !== 'creditCard' && item !== 'creditLine'
);

export const openingsLiabilityDefinitions = ['creditCard', 'creditLine'];

export const nonCreditLiabilityDefinitions = [
	'rent',
	'alimony',
	'buildingSocietySavings',
	'deposit',
	'costOfHousing',
	'costOfInsurance',
	'costOfLiving',
	'communicationExpense',
	'mobilityExpense',
	'additionalLiabilities',
];
