import { on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as MortgageSimulatorActions from './mortgage-simulator.actions';
import {
	ActionState,
	ActionTypes,
	setActionState,
	initialActionState,
	Step,
	FinancialData,
	MortgageReport,
	Resource,
	StepDataPoint,
	generateDataObjectFromStepDataPoints,
	updateStepDataPoints,
} from '@oper-client/shared/data-model';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';

export const MORTGAGE_SIMULATOR_KEY = 'clientSimulator';

const INITIAL_FINANCIAL_DATA: FinancialData = {
	householdIncome: null,
	liabilities: null,
	ownFunds: null,
	realtyPrice: null,
	buildingCosts: null,
	priceBuilding: null,
	priceOfLand: null,
	homeType: null,
	epcBeforeRenovations: null,
	epcAfterRenovations: null,
	coLivingPurpose: null,
	coLivingGroupType: null,
	region: null,
	dontShowIncomeInfo: false,
	dontShowLiabilityInfo: false,
	dontShowOwnFoundsInfo: false,
	householdIncomeSecondBorrower: null,
	liabilitiesSecondBorrower: null,
	ownFundsSecondBorrower: null,
	loanPurpose: null,
	housingType: false,
};

export interface MortgageSimulatorState {
	dataPoints: StepDataPoint | null;
	configuration: Step[];
	activeStep: Step | null;
	loadingIndicator: boolean;
	financialData: FinancialData;
	mortgageReport: MortgageReport;
	error: string | null;
	actions: MortgageSimulatorActionsState;
	simulationFlowType: Resource | null;
	formNames: string[];
	fromQueryParam: boolean;
}

export type MortgageSimulatorActionTypes = 'loadConfig' | 'loadMortgageReport' | 'syncSimulations';
export type MortgageSimulatorActionsState = Record<MortgageSimulatorActionTypes, ActionState>;

export const initialState: MortgageSimulatorState = {
	dataPoints: null,
	configuration: null,
	activeStep: null,
	loadingIndicator: false,
	financialData: { ...INITIAL_FINANCIAL_DATA },
	mortgageReport: {
		simulationResults: [
			{
				duration: null,
				monthlyAmount: null,
				affordabilityLimit: null,
				yearlyAmount: null,
				yearlyInterestPayment: null,
				yearlyAmortizationPayment: null,
				yearlyAdditionalCost: null,
				monthlyInterestPayment: null,
				monthlyAmortizationPayment: null,
				monthlyAdditionalCost: null,
				loanAmount: null,
				inverseLtv: null,
				inverseLtvThreshold: null,
				dsti: null,
				dstiThreshold: null,
				configurationValues: null,
				additionalCosts: null,
				totalCosts: null,
				errors: null,
			},
		],
		errors: null,
	},
	error: null,
	actions: {
		loadConfig: initialActionState,
		loadMortgageReport: initialActionState,
		syncSimulations: initialActionState,
	},
	simulationFlowType: null,
	formNames: null,
	fromQueryParam: null,
};

function setActionStates(
	actionState: MortgageSimulatorActionsState,
	action: MortgageSimulatorActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): MortgageSimulatorActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createRehydrateReducer(
	MORTGAGE_SIMULATOR_KEY,
	initialState,

	on(MortgageSimulatorActions.setConfiguration, (state, { configuration, simulationFlowType, fromQueryParam }) => {
		return {
			...state,
			configuration,
			simulationFlowType,
			formNames: configuration.reduce(
				(prev, current) => [...prev, ...(current.recaulculateFormName ? [current.recaulculateFormName] : [])],
				[]
			),
			fromQueryParam,
		};
	}),

	on(MortgageSimulatorActions.showLoadingIndicator, (state) => {
		return {
			...state,
			loadingIndicator: true,
		};
	}),

	on(MortgageSimulatorActions.hideLoadingIndicator, (state) => {
		return {
			...state,
			loadingIndicator: false,
		};
	}),

	on(MortgageSimulatorActions.setActiveStep, (state, { step }) => {
		return {
			...state,
			activeStep: step,
		};
	}),

	on(MortgageSimulatorActions.nextStep, (state) => {
		let nextStep = state.configuration.find((step) => step.name === state.activeStep.next);
		while (nextStep && nextStep?.skip) {
			nextStep = state.configuration.find((step) => step.name === nextStep.next);
		}
		return {
			...state,
			activeStep: nextStep,
		};
	}),

	on(MortgageSimulatorActions.prevStep, (state) => {
		let previousStep = state.configuration.find((step) => step.name === state.activeStep.back);
		while (previousStep && previousStep?.skip) {
			previousStep = state.configuration.find((step) => step.name === previousStep.back);
		}
		return {
			...state,
			activeStep: previousStep,
		};
	}),

	on(MortgageSimulatorActions.showError, (state, { message }) => {
		return {
			...state,
			error: message,
		};
	}),

	on(MortgageSimulatorActions.hideError, (state) => {
		return {
			...state,
			error: null,
		};
	}),

	on(MortgageSimulatorActions.reset, (state) => {
		return {
			...state,
			activeStep: null,
			borrowerMode: null,
		};
	}),

	on(MortgageSimulatorActions.clear, () => {
		return {
			...initialState,
		};
	}),

	on(MortgageSimulatorActions.clearFinancialData, (state) => {
		return {
			...state,
			financialData: initialState.financialData,
			dataPoints: initialState.dataPoints,
		};
	}),

	on(MortgageSimulatorActions.setFinancialData, (state, { financialData, resetPrevious }) => {
		let update = { ...state.financialData, ...financialData };
		if (resetPrevious) {
			update = { ...initialState.financialData, ...financialData };
		}
		return { ...state, financialData: update, dataPoints: updateStepDataPoints(state.dataPoints, update) };
	}),
	on(MortgageSimulatorActions.setDataForStep, (state, { stepData }) => {
		const dataPoints = { ...state.dataPoints, [stepData.step]: stepData.data };
		const financialData = { ...initialState.financialData, ...generateDataObjectFromStepDataPoints<FinancialData>(dataPoints) };
		return { ...state, dataPoints, financialData };
	}),
	on(MortgageSimulatorActions.loadMortgageReport, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMortgageReport', ActionTypes.loading),
	})),
	on(MortgageSimulatorActions.loadMortgageReportSuccess, (state, { mortgageReport }) => {
		return {
			...state,
			mortgageReport,
			actions: setActionStates(state.actions, 'loadMortgageReport', ActionTypes.success),
		};
	}),
	on(MortgageSimulatorActions.loadMortgageReportFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMortgageReport', ActionTypes.failure, error),
	})),
	on(MortgageSimulatorActions.syncSimulations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'syncSimulations', ActionTypes.loading),
	})),
	on(MortgageSimulatorActions.syncSimulationsSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'syncSimulations', ActionTypes.success),
	})),
	on(MortgageSimulatorActions.syncSimulationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'syncSimulations', ActionTypes.failure, error),
	})),
	on(MortgageSimulatorActions.markStepForSkip, (state, { step, skip }) => {
		return {
			...state,
			configuration: state.configuration.map((configStep) => {
				if (configStep.name === step) {
					return {
						...configStep,
						skip,
					};
				}
				return configStep;
			}),
		};
	})
);
