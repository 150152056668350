import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { Product } from '@oper-client/shared/data-model';

export const loadProducts = createAction(
	'[LoanRequest] Load Products',
	props<{ loanRequestId: number; params: { [key: string]: string }; body?: object }>()
);
export const loadProductsSuccess = createAction('[LoanRequest] Load Products Success', props<{ products: Product[] }>());
export const loadProductsFailure = createAction('[LoanRequest] Load Products Failure', props<{ error: HttpErrorResponse }>());
export const updateLoadedProductsCount = createAction(
	'[LoanRequest] Update Loaded Products Count',
	props<{ totalLoadedProductsProgress: number }>()
);

export const loadProductVariations = createAction('[LoanRequest] Load Product Variations');
export const loadProductVariationsSuccess = createAction('[LoanRequest] Load Product Variations Success', props<{ products: Product[] }>());
export const loadProductVariationsFailure = createAction(
	'[LoanRequest] Load Product Variations Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearProducts = createAction('[LoanRequest] Clear Products');
