import { createSelector } from '@ngrx/store';

import { getLoanRequestFeatureState } from '../loan-request.selectors';
import { LoanRequestEntityState } from '../loan-request.reducer';

import { LoanRequestState } from './loan-request.reducer';
import * as fromLoanRequest from './loan-request.reducer';
import { LoanRequest } from '@oper-client/shared/data-model';

export const getLoanRequestState = createSelector(
	getLoanRequestFeatureState,
	(state: LoanRequestEntityState) => state[fromLoanRequest.LOAN_REQUEST_ENTITY_KEY]
);

export const getCurrentLoanRequestId = createSelector(getLoanRequestState, (state: LoanRequestState) => state.currentLoanRequestId);

export const loanRequestTotalCount = createSelector(getLoanRequestState, (state: LoanRequestState) => state.loanRequestTotalCount);

export const loanRequestCountsPerStatus = createSelector(
	getLoanRequestState,
	(state: LoanRequestState) => state.loanRequestCountsPerStatus
);

export const loanRequestTotalAmount = createSelector(getLoanRequestState, (state: LoanRequestState) => state.loanRequestTotalAmount);

export const loanRequestTotalAmountPerStatus = createSelector(
	getLoanRequestState,
	(state: LoanRequestState) => state.loanRequestTotalAmountPerStatus
);

export const selectFilters = createSelector(getLoanRequestState, (state: LoanRequestState) => state.filters);
export const selectSearch = createSelector(getLoanRequestState, (state: LoanRequestState) => state.search);

export const loanRequestStatusChangePending = createSelector(
	getLoanRequestState,
	(state: LoanRequestState) => state.loanRequestStatusChangePending
);

export const getCurrentLoanRequest = createSelector(getLoanRequestState, (state: LoanRequestState) => {
	if (state.currentLoanRequestId === null) {
		return null;
	} else if (state.currentLoanRequestId === 0) {
		return <LoanRequest>{};
	}
	return state.entities[state.currentLoanRequestId];
});

export const getLoanRequests = createSelector(getLoanRequestState, (state: LoanRequestState) => Object.values(state.entities));

export const selectLoanRequestIds = createSelector(
	getLoanRequestState,
	fromLoanRequest.selectLoanRequestIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectLoanRequestEntities = createSelector(getLoanRequestState, fromLoanRequest.selectLoanRequestEntities);
export const selectAllLoanRequest = createSelector(getLoanRequestState, fromLoanRequest.selectAllLoanRequest);
export const selectLoanRequestTotal = createSelector(getLoanRequestState, fromLoanRequest.selectLoanRequestTotal);
export const selectCurrentLoanRequestId = createSelector(getLoanRequestState, fromLoanRequest.getSelectedLoanRequestId);

// Select LoanRequest Statuses
export const selectLoanRequestStatuses = createSelector(getLoanRequestState, (state) => state.loanRequestStatuses);
export const selectCurrentLoanRequestStatusId = createSelector(getLoanRequestState, (state) => state.currentLoanRequestStatusId);
export const selectCurrentLoanRequestStatus = createSelector(
	selectLoanRequestStatuses,
	selectCurrentLoanRequestStatusId,
	(statusList, statusId) => statusList.find((status) => status.id === statusId)
);

export const loadLoanRequestActionState = createSelector(getLoanRequestState, (state) => state.actions.loadLoanRequest);
export const createLoanRequestActionState = createSelector(getLoanRequestState, (state) => state.actions.createLoanRequest);
export const updateLoanRequestActionState = createSelector(getLoanRequestState, (state) => state.actions.updateLoanRequest);
export const assignAnalystActionState = createSelector(getLoanRequestState, (state) => state.actions.assignAnalyst);
export const loadLoanRequestListActionState = createSelector(getLoanRequestState, (state) => state.actions.loadLoanRequestList);
export const loadLoanRequestsPerStatusActionState = createSelector(getLoanRequestState, (state) => state.actions.loadLoanRequestsPerStatus);
export const updateLoanRequestStatusActionState = createSelector(getLoanRequestState, (state) => state.actions.updateLoanRequestStatus);

export const loadLoanRequestsTotalAmountPerStatusActionState = createSelector(
	getLoanRequestState,
	(state) => state.actions.loadLoanRequestsTotalAmountPerStatus
);
export const loadLoanRequestTotalAmountActionState = createSelector(
	getLoanRequestState,
	(state) => state.actions.loadLoanRequestTotalAmount
);

export const selectCurrentLoanRequest = createSelector(
	selectLoanRequestEntities,
	selectCurrentLoanRequestId,
	(loanRequestEntities, loanRequestId) => loanRequestEntities[loanRequestId]
);

export const getActionStates = createSelector(getLoanRequestState, (state) => state.actions);
export const getActionState = (actionType: fromLoanRequest.LoanRequestActionTypes) =>
	createSelector(getActionStates, (state) => state[actionType]);

export const selectDecisions = createSelector(getLoanRequestState, (state) => state.decisions);
export const selectDecisionsStatistics = createSelector(getLoanRequestState, (state) => state.decisionsStatistics);
export const selectComments = createSelector(getLoanRequestState, (state) => state.comments);
export const selectHistories = createSelector(getLoanRequestState, (state) => state.histories);
export const selectPreapproval = createSelector(getLoanRequestState, (state) => state.preapproval);
export const selectFinancedItems = createSelector(getLoanRequestState, (state) => state.financedItems);

export const loanRequestFirstLoaded = createSelector(getLoanRequestState, (state) => state.loanRequestFirstLoaded);
