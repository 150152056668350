import { ComponentFactoryResolver, Injectable, Inject, ViewContainerRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TooltipPaneComponent } from '../components/tooltip-pane/tooltip-pane.component';

export const TOOLTIP_WIDTH_MIN = 250;
export const TOOLTIP_WIDTH_MAX = 400;

@Injectable({
	providedIn: 'root',
})
export class TooltipService {
	private componentFactoryResolver: ComponentFactoryResolver;
	private tooltipMount: ViewContainerRef;
	private tooltipPaneComponent: TooltipPaneComponent;
	private readonly marginSide = 10;
	private readonly marginTop = 25;

	constructor(
		@Inject(ComponentFactoryResolver) componentFactoryResolver,
		@Inject(DOCUMENT) private document: Document
	) {
		this.componentFactoryResolver = componentFactoryResolver;
	}

	@HostListener('window:resize') onWindowResize() {
		this.flush();
	}

	public setMountElement(tooltipMount: ViewContainerRef): void {
		this.tooltipMount = tooltipMount;
		this.addListeners();
	}

	public toggle(text: string, indicatorBoundingClientRect: DOMRect, parentContainerBoundingRect?: DOMRect): void {
		if (this.tooltipPaneComponent) {
			this.flush();
			this.removeListeners();
			return;
		}
		const minWidth = this.getMinWidth();
		const maxWidth = this.getMaxWidth();
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipPaneComponent);
		const rootElement = this.getRootElement();
		const mainElementBoundingClientRect = rootElement.getBoundingClientRect();
		this.tooltipPaneComponent = <TooltipPaneComponent>this.tooltipMount.createComponent(componentFactory).instance;
		this.tooltipPaneComponent.text = text;
		const scrollTop = window.scrollY || rootElement.scrollTop;

		const defaultWidth = parentContainerBoundingRect?.width || mainElementBoundingClientRect.width;
		let paneWidth = defaultWidth;
		if (defaultWidth < minWidth) {
			paneWidth = minWidth;
		} else if (defaultWidth > maxWidth) {
			paneWidth = maxWidth;
		}

		const posLeft =
			rootElement.scrollLeft +
			(parentContainerBoundingRect && parentContainerBoundingRect.left + paneWidth > mainElementBoundingClientRect.right
				? parentContainerBoundingRect.left
				: mainElementBoundingClientRect.left);

		let paneLeft = posLeft;
		if (posLeft + paneWidth > mainElementBoundingClientRect.right) {
			paneLeft = indicatorBoundingClientRect.right - paneWidth;
		} else if (posLeft + paneWidth < mainElementBoundingClientRect.right) {
			paneLeft = indicatorBoundingClientRect.left;
		}

		const rightEdge = this.document.documentElement.getBoundingClientRect().right;
		if (paneLeft + paneWidth > rightEdge) {
			paneLeft = rightEdge - paneWidth - this.marginSide;
		}

		if (paneLeft < this.marginSide) paneLeft = this.marginSide;

		const paneTop = scrollTop + indicatorBoundingClientRect.top + this.marginTop;

		this.tooltipPaneComponent.panePositioning = {
			paneTop,
			paneLeft,
			paneWidth,
			arrowTop: scrollTop + indicatorBoundingClientRect.top + 15,
			arrowLeft: rootElement.scrollLeft + indicatorBoundingClientRect.left + indicatorBoundingClientRect.width / 4,
		};
		this.tooltipPaneComponent.hide.subscribe(() => this.flush());
	}

	private getRootElement(): HTMLElement {
		return this.document.getElementById('router-outlet-container') || this.document.documentElement;
	}

	private getMinWidth(): number {
		const bodyStyles = window.getComputedStyle(document.documentElement);
		return parseInt(bodyStyles.getPropertyValue('--tooltip-width-min')) || TOOLTIP_WIDTH_MIN;
	}

	private getMaxWidth(): number {
		const bodyStyles = window.getComputedStyle(document.documentElement);
		return parseInt(bodyStyles.getPropertyValue('--tooltip-width-max')) || TOOLTIP_WIDTH_MAX;
	}

	public flush(): void {
		if (this.tooltipMount) {
			this.tooltipMount.clear();
		}

		if (this.tooltipPaneComponent) {
			this.tooltipPaneComponent.hide.unsubscribe();
			this.tooltipPaneComponent = null;
		}
	}

	private addListeners(): void {
		this.document.getElementById('shell-container').addEventListener(
			'scroll',
			(scrollEvent: Event) => {
				this.tooltipPaneComponent?.calculatePanePosition(scrollEvent);
			},
			true
		);
	}

	private removeListeners(): void {
		this.document.getElementById('shell-container').removeEventListener(
			'scroll',
			(scrollEvent: Event) => {
				this.tooltipPaneComponent?.calculatePanePosition(scrollEvent);
			},
			true
		);
	}
}
