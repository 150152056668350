import { Observable, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { API_SERVICE, IApiService, IAuthService, UnsavedDataService } from '@oper-client/shared/data-access';
import { IAM } from '@oper-client/shared/data-model';
import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Router } from '@angular/router';
import { OverlayService } from '@oper-client/shared/overlay';

@Injectable()
export class KcAuthService implements IAuthService {
	_currentUser = new BehaviorSubject<IAM.User>(undefined);
	_isInitialized: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

	constructor(
		private readonly kcService: KeycloakService,
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly config: CustomerInsights,
		private router: Router,
		private overlayService: OverlayService,
		private unsavedDataService: UnsavedDataService
	) {
		this.kcService
			.init({
				config: {
					url: this.config.kcAuth.url,
					realm: this.config.kcAuth.realm,
					clientId: this.config.kcAuth.clientId,
				},
				initOptions: {
					flow: 'standard',
					onLoad: 'check-sso',
					checkLoginIframe: false,
				},
				bearerExcludedUrls: [
					`/version.json`,
					`./assets/`,
					`${config?.serverUrl}/contact/`,
					`${config?.serverUrl}/resources/language/`,
					`${config?.serverUrl}/resources/features/`,
				],
			})
			.then((authenticated) => {
				this._isInitialized.next(authenticated);
				if (authenticated) {
					if (!this._currentUser.getValue()) {
						this.apiService.get('/api/me/').subscribe(
							(apiUser) =>
								this._currentUser.next({
									...apiUser,
									role: 'analyst',
								} as IAM.User),
							() => {
								this._currentUser.next(null);
							}
						);
					}
					this.kcService.keycloakEvents$.subscribe({
						next: (event) => {
							if (event.type === KeycloakEventType.OnAuthRefreshError) {
								this.onTokenRefreshFailed();
							}
						},
					});
				} else {
					this._currentUser.next(null);
				}
			});
	}

	isInitialized(): Observable<boolean> {
		return this._isInitialized.pipe(filter((_) => _ !== undefined));
	}

	getCurrentUser(): Observable<IAM.User> {
		return this._currentUser.asObservable().pipe(
			filter((value) => value !== undefined),
			take(1)
		);
	}

	updateCurrentUser(user: Partial<IAM.User>): Observable<IAM.User> {
		return this.apiService.patch(`/api/me/`, { bank_ids: user.bankIds });
	}

	isAuthenticated() {
		return this.kcService.getKeycloakInstance().authenticated && !this.kcService.getKeycloakInstance().isTokenExpired();
	}

	logout(): Observable<void> {
		return fromPromise(this.kcService.logout(this.config.kcAuth.postLogoutRedirectUri));
	}

	getAccessToken(): string {
		return this.kcService.getKeycloakInstance().token;
	}

	getRefreshToken(): string {
		return this.kcService.getKeycloakInstance().refreshToken;
	}

	refreshToken(): Observable<any> {
		return fromPromise(this.kcService.updateToken());
	}

	public getPrivacyPolicy(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/privacy-policy/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	onTokenRefreshFailed() {
		this.overlayService.closeAll();
		this.unsavedDataService.untrackAll();
		this.router.navigate(['/auth/login']);
		this._currentUser.next(null);
	}

	getTermsAndConditions(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/terms-and-conditions/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	login?(): void {
		this.kcService.login({ redirectUri: this.config?.kcAuth?.redirectUri, idpHint: this.config?.kcAuth?.idpHint });
	}
}
