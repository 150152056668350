export enum LoanRequestStatusEnum {
	LEAD = 'lead',
	SUBMITTED_APPLICATION = 'submittedApplication',
	PENDING_SIGNATURE = 'pendingSignature',
	MISSING_DOCUMENTS = 'missingDocuments',
	APPLICATION_COMPLETE = 'applicationComplete',
	ANALYSIS_ONGOING = 'analysisOngoing',
	DECISION_PENDING = 'decisionPending',
	NEGATIVE_DECISION = 'negativeDecision',
	POSITIVE_DECISION = 'positiveDecision',
	REJECTED = 'rejected',
	FINAL_CHECK = 'finalCheck',
	OFFER_CREATED = 'offerCreated',
	OFFER_SENT = 'offerSent',
	OFFER_SIGNED = 'offerSigned',
	EXPIRED = 'expired',
	CANCELLED = 'cancelled',

	// Finporta specific statuses
	DOCUMENTS_TO_BE_UPLOADED = 'documentsToBeUploaded',
	DOC_AGENT_VERIFIED = 'docAgentVerified',
	DOC_ANALYST_VERIFIED = 'docAnalystVerified',
	SENT_FOR_DECISION = 'sentForDecision',
	LOAN_DISBURSED = 'loanDisbursed',
	DIP_REJECTED = 'dipRejected',
	REQUEST_CANCELLED = 'requestCancelled',
	SIMULATION_DRAFT = 'simulationDraft',
}

export const LoanRequestEndedType: string[] = [
	LoanRequestStatusEnum.EXPIRED,
	LoanRequestStatusEnum.REJECTED,
	LoanRequestStatusEnum.CANCELLED,
];

export enum BorrowerApplicationStatusEnum {
	DRAFT = 'draft',
	UNDER_REVIEW = 'underReview',
	DOCUMENTS_PENDING = 'documentsPending',
	APPROVED = 'approved',
	PENDING_SIGNATURE = 'pendingSignature',
	PENDING_LRF_SIGNATURE = 'pendingLrfSignature',
	OFFER_SIGNED = 'offerSigned',
	REJECTED = 'rejected',
	EXPIRED = 'expired',
	CANCELLED = 'cancelled',
}

export const LoanRequestStatusToApplicationStatusMapping: Record<LoanRequestStatusEnum, BorrowerApplicationStatusEnum> = {
	[LoanRequestStatusEnum.SIMULATION_DRAFT]: BorrowerApplicationStatusEnum.DRAFT,
	[LoanRequestStatusEnum.LEAD]: BorrowerApplicationStatusEnum.DRAFT,
	[LoanRequestStatusEnum.PENDING_SIGNATURE]: BorrowerApplicationStatusEnum.PENDING_LRF_SIGNATURE,
	[LoanRequestStatusEnum.SUBMITTED_APPLICATION]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.APPLICATION_COMPLETE]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.ANALYSIS_ONGOING]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.DECISION_PENDING]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.NEGATIVE_DECISION]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.MISSING_DOCUMENTS]: BorrowerApplicationStatusEnum.DOCUMENTS_PENDING,
	[LoanRequestStatusEnum.POSITIVE_DECISION]: BorrowerApplicationStatusEnum.APPROVED,
	[LoanRequestStatusEnum.REJECTED]: BorrowerApplicationStatusEnum.REJECTED,
	[LoanRequestStatusEnum.FINAL_CHECK]: BorrowerApplicationStatusEnum.APPROVED,
	[LoanRequestStatusEnum.OFFER_CREATED]: BorrowerApplicationStatusEnum.APPROVED,
	[LoanRequestStatusEnum.OFFER_SENT]: BorrowerApplicationStatusEnum.PENDING_SIGNATURE,
	[LoanRequestStatusEnum.OFFER_SIGNED]: BorrowerApplicationStatusEnum.OFFER_SIGNED,
	[LoanRequestStatusEnum.EXPIRED]: BorrowerApplicationStatusEnum.EXPIRED,
	[LoanRequestStatusEnum.CANCELLED]: BorrowerApplicationStatusEnum.CANCELLED,
	[LoanRequestStatusEnum.DOCUMENTS_TO_BE_UPLOADED]: BorrowerApplicationStatusEnum.DOCUMENTS_PENDING,
	[LoanRequestStatusEnum.DOC_AGENT_VERIFIED]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.DOC_ANALYST_VERIFIED]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.SENT_FOR_DECISION]: BorrowerApplicationStatusEnum.UNDER_REVIEW,
	[LoanRequestStatusEnum.LOAN_DISBURSED]: BorrowerApplicationStatusEnum.APPROVED,
	[LoanRequestStatusEnum.DIP_REJECTED]: BorrowerApplicationStatusEnum.REJECTED,
	[LoanRequestStatusEnum.REQUEST_CANCELLED]: BorrowerApplicationStatusEnum.CANCELLED,
};
