import { debounceTimes } from '@oper-client/shared/configuration';
import { isLiabilityTypeCreditLine, PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { BehaviorSubject, map, of, Subject, takeUntil } from 'rxjs';
import { DynamicInputTable, InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormGroup, Validators } from '@angular/forms';

export default function (
	formData: Partial<Simulator.Borrower> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const liabilityTypes = resources?.[ResourceType.LIABILITY_TYPE] || [];
	return [
		new DynamicInputTable({
			key: 'incomes',
			value: formData?.incomes,
			debounceTime: debounceTimes.xxs,
			addRowLabel: 'ç.feature.mortgageSimulator.addIncome',
			label: 'ç.feature.mortgageSimulator.addIncome',
			required: true,
			disabled: readonlyMode,
			columns: [
				new InputField({ key: 'id', type: 'hidden', required: false }),
				new InputSelect({
					key: 'incomeType.id',
					required: true,
					clearable: true,
					disabled: readonlyMode,
					label: 'ç.feature.mortgageSimulator.incomeType.label',
					helpText: of('ç.feature.mortgageSimulator.incomeType.tooltip'),
					validators: [],
					options: orderBy(resources?.[ResourceType.INCOME_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					currency: true,
					class: 'span6',
					disabled: readonlyMode,
				}),
			],
		}),

		new DynamicInputTable({
			key: 'liabilities',
			value: formData?.liabilities,
			debounceTime: debounceTimes.xxs,
			addRowLabel: 'ç.feature.mortgageSimulator.addLiability',
			label: 'ç.feature.mortgageSimulator.addLiability',
			required: true,
			disabled: readonlyMode,
			showHeader: false,
			columns: [
				new InputField({ key: 'id', type: 'hidden', required: false }),
				new InputSelect({
					key: 'liabilityType.id',
					required: true,
					clearable: true,
					label: 'ç.feature.mortgageSimulator.liabilityType.label',
					validators: [],
					hideRequiredAsterisk: true,
					options: orderBy(liabilityTypes, 'order'),
					alreadySorted: true,
					class: 'span6',
					disabled: readonlyMode,
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					currency: true,
					hideRequiredAsterisk: true,
					class: 'span6',
					disabled: readonlyMode,
					hidden: (formGroup: FormGroup, destroy$: Subject<void>) => {
						const isCreditLine = isLiabilityTypeCreditLine(formGroup.value['liabilityType.id'], liabilityTypes);
						const isHidden$ = new BehaviorSubject(isCreditLine);
						formGroup.controls['liabilityType.id'].valueChanges
							.pipe(
								map((liabilityTypeId) => isLiabilityTypeCreditLine(liabilityTypeId, liabilityTypes)),
								takeUntil(destroy$)
							)
							.subscribe((isCreditLine) => isHidden$.next(isCreditLine));

						return isHidden$.asObservable();
					},
				}),

				new InputField({
					key: 'amount',
					label: 'ç.question.creditLimit.label',
					type: 'number',
					required: true,
					currency: true,
					hideRequiredAsterisk: true,
					class: 'span6',
					disabled: readonlyMode,
					hidden: (formGroup: FormGroup, destroy$: Subject<void>) => {
						const isCreditLine = isLiabilityTypeCreditLine(formGroup.value['liabilityType.id'], liabilityTypes);
						const isHidden$ = new BehaviorSubject(!isCreditLine);
						formGroup.controls['liabilityType.id'].valueChanges
							.pipe(
								map((liabilityTypeId) => isLiabilityTypeCreditLine(liabilityTypeId, liabilityTypes)),
								takeUntil(destroy$)
							)
							.subscribe((isCreditLine) => isHidden$.next(!isCreditLine));

						return isHidden$.asObservable();
					},
				}),
			],
		}),
		new InputField({
			key: 'numberOfDependents',
			label: 'ç.question.numberOfDependents.label',
			value: formData?.numberOfDependents,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span12',
			disabled: readonlyMode,
			placeholder: 'ç.question.numberOfDependents.placeholder',
		}),
	];
}
