import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/';
import { CalculateAmortization, CalculateMonthlyAmortization, Calculation } from '@oper-client/shared/data-model';

export const CALCULATE_SERVICE = new InjectionToken<ICalculateService>('CALCULATE_SERVICE');

export interface ICalculateService {
	calculateAmortization(calculateAmortization: CalculateAmortization): Observable<CalculateAmortization>;
	calculateMonthlyAmortization(inputParameters: any): Observable<CalculateMonthlyAmortization>;
	calculate(calculator: string, inputParameters: any): Observable<Calculation>;
}
