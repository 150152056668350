import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Params } from '@angular/router';

import { IAM, ActionState, IdentityProvider, JoinDetails } from '@oper-client/shared/data-model';

import { IamFacadeService } from './iam.facade.interface';
import * as fromIam from './iam.reducer';
import { IamActionsState } from './iam.reducer';

import * as iamActions from './iam.actions';
import * as iamSelectors from './iam.selectors';
import { loadIdentitySuccess } from './iam.actions';

const mapEntityActions = (actions: { [key: string]: ActionState }) => {
	return Object.entries(actions).reduce((acc: any, [key, action]) => {
		acc[key] = {
			processing: action.processing,
			httpError: action.httpError,
			success: action.success,
		};

		return acc;
	}, {});
};

@Injectable()
export class IamFacade implements IamFacadeService {
	readonly user$ = this.store.pipe(select(iamSelectors.getIamUser));
	readonly iamActionState$: Observable<IamActionsState> = this.store.pipe(select(iamSelectors.getIamActionsState), map(mapEntityActions));
	readonly loadIdentityActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.getLoadIdentityActionsState));
	readonly permissions$ = this.store.pipe(
		select(iamSelectors.getPermissions),
		filter((permissions) => !!permissions)
	);
	readonly authRequest$ = this.store.pipe(select(iamSelectors.authRequest));
	readonly identity$: Observable<any> = this.actionListener$.pipe(
		filter((_) => _.type === loadIdentitySuccess.type),
		map((_) => (<any>_).identity)
	);
	readonly verifyUserActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.verifyUserActionState));
	readonly activateUserActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.activateUserActionState));
	readonly resetPasswordActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.resetPasswordActionState));
	readonly updateCurrentUserState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.updateCurrentUserState));
	readonly validateCredentialsActionState$: Observable<ActionState> = this.store.pipe(
		select(iamSelectors.validateCredentialsActionState)
	);
	readonly activateOTPActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.activateOTPActionState));
	readonly veriflyOTPActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.verifyOTPActionState));
	readonly resetPassword2FaActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.resetPassword2FaActionState));
	readonly createUserActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.createUserActionState));
	readonly updateProfileActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.updateProfileActionState));
	readonly inviteUserActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.inviteUserActionState));

	readonly loginActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.loginActionState));
	readonly verfiyUserActionState$: Observable<ActionState> = this.store.pipe(select(iamSelectors.verifyUserActionState));

	constructor(
		private readonly store: Store<fromIam.IamPartialState>,
		private readonly actionListener$: ActionsSubject
	) {
		// TODO: Let's investigate if this call is on the right place
		this.loadCurrentUser();
	}

	login(credentials: IAM.UserCredentials, loginSuccessRedirectRoute: string[] = null) {
		this.store.dispatch(iamActions.login({ credentials, loginSuccessRedirectRoute }));
	}

	ssoLogin() {
		this.store.dispatch(iamActions.ssoLogin());
	}

	loadCurrentUser() {
		this.store.dispatch(iamActions.loadCurrentUser());
	}

	logout() {
		this.store.dispatch(iamActions.logout());
	}

	forgotPassword(email: string) {
		this.store.dispatch(iamActions.forgotPassword({ email }));
	}

	resetPassword(payload: IAM.ResetPassword) {
		this.store.dispatch(iamActions.resetPassword({ payload }));
	}

	resetPassword2Fa(payload: IAM.ResetPassword) {
		this.store.dispatch(iamActions.resetPassword2Fa({ payload }));
	}

	releaseIamActionState(kind: Partial<keyof IamActionsState>) {
		this.store.dispatch(iamActions.releaseIamActionState({ kind }));
	}

	loadPermissions(): void {
		this.store.dispatch(iamActions.loadPermissions());
	}

	clearPermissions(): void {
		this.store.dispatch(iamActions.clearPermissions());
	}

	activateUser(token: string) {
		this.store.dispatch(iamActions.activateUser({ token }));
	}

	verifyUser(code: string, token: string) {
		this.store.dispatch(iamActions.verifyUser({ code, token }));
	}

	hasPermission(permission: string): Observable<boolean> {
		return this.permissions$.pipe(map((permissions) => permissions?.includes(permission)));
	}

	loadIdentity(identityProvider: IdentityProvider, queryParams?: Params, loanRequestId?: number) {
		this.store.dispatch(iamActions.loadIdentity({ identityProvider, queryParams, loanRequestId }));
	}

	resetLoadIdentityState() {
		this.store.dispatch(iamActions.resetLoadIdentityState());
	}

	updateUser(user: Partial<IAM.User>) {
		this.store.dispatch(iamActions.updateCurrentUser({ user: { id: user.id, changes: user } }));
	}

	validateCredentials(username: string, password: string) {
		this.store.dispatch(iamActions.validateCredentials({ username, password }));
	}

	activateOTP(token: string) {
		this.store.dispatch(iamActions.activateOTP({ token }));
	}

	verifyOTP(token: string, code: string) {
		this.store.dispatch(iamActions.verifyOTP({ token, code }));
	}

	createUser(userData: Partial<JoinDetails>): void {
		this.store.dispatch(iamActions.createUser({ userData }));
	}

	updateProfile(profileData: Partial<JoinDetails>): void {
		this.store.dispatch(iamActions.updateProfile({ profileData }));
	}

	inviteUser(userData: Partial<JoinDetails>): void {
		this.store.dispatch(iamActions.inviteUser({ userData }));
	}

	reset() {
		this.store.dispatch(iamActions.resetState());
	}
}
