import { DynamicInputFormItems, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { Guarantee, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { Observable, of } from 'rxjs';
import { Validators } from '@angular/forms';

export default function (formData?: Guarantee, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new DynamicInputFormItems({
			key: 'guaranteeItems',
			value: formData.guaranteeItems,
			debounceTime: debounceTimes.xxs,
			itemTitle: 'ç.feature.guarantee.guaranteeItem',
			addItemLabel: 'ç.feature.guarantee.addGuaranteeItem',
			required: true,
			items: [
				new InputSelect({
					key: 'realty.id',
					label: 'ç.question.guarantee.asset',
					options: resources['realties'],
					required: true,
					searchable: true,
				}),
				new InputField({
					key: 'amount',
					label: 'ç.question.guarantee.amount',
					type: 'number',
					currency: true,
					min: 1,
					required: true,
				}),
				new InputField({
					key: 'mortgageRank',
					label: 'ç.question.guarantee.mortgageRank',
					type: 'number',
					class: 'span12',
					required: true,
					validators: [Validators.min(1)],
					hidden: (): Observable<boolean> => of(formData?.guaranteeType?.id !== 1),
				}),
			],
		}),
	];
}
