import { FormGroup } from '@angular/forms';
import { Address, AnyObject } from '@oper-client/shared/data-model';
import { Subject, Observable, BehaviorSubject, takeUntil, map, distinctUntilChanged } from 'rxjs';

/**
 * Function to hide a field when another conditional field is false
 * @param formGroup - The form group containing the fields
 * @param destroy$ - The destroy subject to unsubscribe from the value changes
 * @param conditionalField - The field to check if it is false
 * @returns An observable that emits a boolean value indicating if the field should be hidden based on the conditional field value
 */
export function hideWhenFieldIsFalse(formGroup: FormGroup, destroy$: Subject<void>, conditionalField: string): Observable<boolean> {
	const initialFieldValue = formGroup.value[conditionalField];
	const isHidden = !initialFieldValue;
	const subject = new BehaviorSubject(isHidden);

	formGroup.controls[conditionalField].valueChanges.pipe(takeUntil(destroy$)).subscribe({
		complete: () => subject.complete(),
		error: (error) => subject.error(error),
		next: (value) => subject.next(!value),
	});

	return subject.asObservable();
}

/**
 * This function is used to show a field only when all the conditions are met
 * @param formGroup - The form group containing the fields
 * @param destroy$ - The destroy subject to unsubscribe from the value changes
 * @param conditionsWhenFieldIsShown - The conditions when the field should be shown
 * @returns - An observable that emits a boolean value indicating if the field should be hidden based on the conditions
 */
export function showOnlyWhenAllConditionsAreMet(
	formGroup: FormGroup,
	destroy$: Subject<void>,
	conditionsWhenFieldIsShown: AnyObject<any>
): Observable<boolean> {
	const initialFieldValues = formGroup.value;
	const isHidden = Object.entries(conditionsWhenFieldIsShown).some(([field, value]) => value !== initialFieldValues[field]);
	const subject = new BehaviorSubject(isHidden);

	formGroup.valueChanges
		.pipe(
			map((formValue) =>
				Object.keys(conditionsWhenFieldIsShown).reduce((acc, field) => {
					acc[field] = formValue[field];
					return acc;
				}, {})
			),
			distinctUntilChanged((prev, curr) => Object.entries(prev).every(([field, value]) => curr[field] === value)),
			takeUntil(destroy$)
		)
		.subscribe({
			complete: () => subject.complete(),
			error: (error) => subject.error(error),
			next: (formValue) => {
				const isHidden = Object.entries(conditionsWhenFieldIsShown).some(([field, value]) => value !== formValue[field]);
				subject.next(isHidden);
			},
		});

	return subject.asObservable();
}

/**
 * @param value - the value of the select. Array of ids for multiple choice or selected item id for single choice
 * @param valuesToCheck - resources/items we need to find if selected
 * @param bindLabel - label to use when matching valuesToCheck to select value
 * @returns - returns true if at least one value was found
 */
export function betaSelectContainsValues(value: any | any[], valuesToCheck: any[], bindLabel = 'id'): boolean {
	const currentValue = Array.isArray(value) ? value : [value];
	return valuesToCheck.some((value) => currentValue.some((item) => item === value[bindLabel]));
}

export function displayAddress(address: Address): string {
	return `
		${address?.street} ${address?.houseNumber} ${address?.box ? ' / ' + address?.box : ''} ${address?.zipCode} ${address?.city}`;
}
