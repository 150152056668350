import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FeatureFlags, FeatureFlag } from '@oper-client/shared/data-model';
import { FeatureFlagFacade } from '../+state/feature-flag.facade';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private readonly featureFlags$ = this.featureFlagFacade.featureFlags$;

	constructor(private readonly featureFlagFacade: FeatureFlagFacade) {
		this.initFeatureFlags();
	}

	private initFeatureFlags(): void {
		this.featureFlagFacade.loadFeatureFlags();
	}

	public hasFeatureFlag(featureName: FeatureFlag): Observable<boolean> {
		return this.featureFlags$.pipe(
			filter((featureFlags) => typeof featureFlags !== 'undefined' && featureFlags !== null),
			map((featureFlags) => typeof featureFlags[featureName] !== 'undefined' && featureFlags[featureName])
		);
	}

	public hasFeatureFlags(...featureNames: string[]): Observable<FeatureFlags> {
		return this.featureFlags$.pipe(
			filter((featureFlags) => typeof featureFlags !== 'undefined' && featureFlags !== null),
			map((featureFlags) => {
				return featureNames.reduce((acc, v) => ({ [v]: Object.keys(featureFlags).includes(v) && featureFlags[v], ...acc }), {});
			})
		);
	}
}
