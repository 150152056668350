import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { generateGuid } from '@oper-client/shared/util-object';
import { BehaviorSubject, noop } from 'rxjs';

@Component({
	selector: 'oper-client-radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements ControlValueAccessor {
	@Input() set value(value: any) {
		this.value$.next(value);
	}

	@Input() disableInput = false;
	@Input() horizontal = false;
	@Input() withItemBorder = false;
	@Input() options: SelectOption[];

	@Output() valueChange = new EventEmitter<string>();

	readonly value$ = new BehaviorSubject<any>(null);
	readonly prefixId: string = generateGuid();

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	onRadioSelectChange(event): void {
		const value = event.target.defaultValue;
		this.value$.next(value);
		this.onTouchedCallback();
		this.onChangeCallback(value);
		this.valueChange.emit(value);
	}

	constructor(@Self() @Optional() public control: NgControl) {
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	writeValue(obj: any): void {
		this.value$.next(obj);
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(disabled: boolean): void {
		this.disableInput = disabled;
	}
}
