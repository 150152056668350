import { ResourceType, Simulator } from '@oper-client/shared/data-model';
import defaultSimulatorConfiguration from './default-simulator.configuration';

const configuration: Simulator.CustomerConfiguration = {
	...defaultSimulatorConfiguration,
	[Simulator.SimulatorStepEnum.COSTS_AND_FINANCING]: {
		...defaultSimulatorConfiguration[Simulator.SimulatorStepEnum.COSTS_AND_FINANCING],
		resourceTypes: [
			ResourceType.LOAN_REQUEST_PURPOSE,
			ResourceType.RENOVATION_TYPE,
			ResourceType.REGION,
			ResourceType.ADDITIONAL_FINANCING_TYPE,
			ResourceType.REALTY_USAGE_TYPE,
			ResourceType.LIABILITY_TYPE,
			ResourceType.PURCHASE_SALE_TYPE,
			ResourceType.LAND_PURCHASE_TYPE,
		],
	},
};

export default configuration;
