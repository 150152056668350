import { Resource } from './resource.model';

export type LiabilityType =
	| 'additionalLiabilities'
	| 'alimony'
	| 'buildingSocietySavings'
	| 'communicationExpense'
	| 'consumerLoan'
	| 'consumerLoanImmovable'
	| 'consumerLoanMovable'
	| 'costOfHousing'
	| 'costOfInsurance'
	| 'costOfLiving'
	| 'credit'
	| 'creditCard'
	| 'creditLine'
	| 'dependent'
	| 'deposit'
	| 'familyDebt'
	| 'generalCreditExpense'
	| 'investmentCredit'
	| 'leasing'
	| 'mobilityExpense'
	| 'mortgageImmovable'
	| 'mortgageMovable'
	| 'openCredit'
	| 'otherCredit'
	| 'otherDebt'
	| 'policyNumber'
	| 'premium'
	| 'purchaseCredit'
	| 'rent';

export enum LiabilityTypeEnum {
	AdditionalLiabilities = 'additionalLiabilities',
	Alimony = 'alimony',
	BuildingSocietySavings = 'buildingSocietySavings',
	CommunicationExpense = 'communicationExpense',
	ConsumerLoan = 'consumerLoan',
	ConsumerLoanImmovable = 'consumerLoanImmovable',
	ConsumerLoanMovable = 'consumerLoanMovable',
	CostOfHousing = 'costOfHousing',
	CostOfInsurance = 'costOfInsurance',
	CostOfLiving = 'costOfLiving',
	Credit = 'credit',
	CreditCard = 'creditCard',
	CreditLine = 'creditLine',
	Dependent = 'dependent',
	Deposit = 'deposit',
	FamilyDebt = 'familyDebt',
	GeneralCreditExpense = 'generalCreditExpense',
	InvestmentCredit = 'investmentCredit',
	Leasing = 'leasing',
	MobilityExpense = 'mobilityExpense',
	MortgageImmovable = 'mortgageImmovable',
	MortgageMovable = 'mortgageMovable',
	OpenCredit = 'openCredit',
	OtherCredit = 'otherCredit',
	OtherDebt = 'otherDebt',
	PolicyNumber = 'policyNumber',
	Premium = 'premium',
	PurchaseCredit = 'purchaseCredit',
	Rent = 'rent',
}

export function isLiabilityTypeCreditLine(liabilityTypeId: number, liabilities: Resource[]): boolean {
	const liabilityType = (liabilities ?? []).find((lt) => lt.id === liabilityTypeId)?.definition as LiabilityTypeEnum;
	return liabilityType === LiabilityTypeEnum.CreditLine;
}
