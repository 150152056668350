export enum IncomeTypeEnum {
	SALARY = 'salaryEmployed',
	SALARY_INTERIM = 'salaryEmployedInterim',
	SALARY_ABROAD = 'salaryEmployedAbroad',
	SELF_EMPLOYED = 'selfEmployed',
	RETIRED = 'retirement',
	REPLACEMENT = 'replacementIncome',
	RENT = 'rent',
	FUTURE_RENT = 'futureRent',
	FOREIGN_SALARY = 'foreignSalary',
	OTHER = 'other',
}

export enum BorrowerIncomeEnum {
	MAIN_BORROWER = 'mainBorrowerIncomes',
	CO_BORROWER = 'coBorrowerIncomes',
}

export const salaryIncomeTypes: string[] = [IncomeTypeEnum.SALARY, IncomeTypeEnum.SALARY_ABROAD, IncomeTypeEnum.SALARY_INTERIM];
