import { DynamicFormCard, DynamicInputTable, InputAsyncSearch, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { orderBy } from '@oper-client/shared/util-array';
import {
	CreditProvider,
	LoanApplicationDto,
	LoanPurposeEnums,
	PartialNormalizedResource,
	ResourceType,
} from '@oper-client/shared/data-model';
import { FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../services/validator-constants';
import { CUSTOMER_INSIGHTS_CONFIG, debounceTimes } from '@oper-client/shared/configuration';
import defaultLoanApplicationAdditionalRefinance from './default-loan-application-additional-refinance';
import { THIRD_PARTY_SERVICE_TOKEN } from '@oper-client/shared/third-party/data-access-third-party';
import { inject } from '@angular/core';
import { FormConfiguration } from '../models/dynamic-form.model';
import { GoogleGeocodeService } from '@oper-client/shared/util-google-maps';
import { filter, map, Observable } from 'rxjs';

function formatText(str: string): string {
	if (!str || str.length <= 0) return '';
	return str
		.trim()
		.split(' ')
		.map((word) => word && `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
		.filter(Boolean)
		.join(' ');
}

export default function (
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[]
): InputBase<any>[] {
	const loanPurpose = formData?.loanPurpose?.definition ?? 'default';
	const questions: InputBase<any>[] = [];
	switch (loanPurpose) {
		case LoanPurposeEnums.PURCHASE:
			questions.push(...getFormConfigurationForProjectPurposePurchase(formData, resources));
			break;
		case LoanPurposeEnums.NEW_BUILD:
			questions.push(...getFormConfigurationForProjectPurposeNewBuild(formData, resources));
			break;
		case LoanPurposeEnums.RENOVATION:
			questions.push(...getFormConfigurationForProjectPurposeRenovation(formData, resources));
			break;
		case LoanPurposeEnums.REFINANCE:
			questions.push(...getFormConfigurationForProjectPurposeRefinance(formData, resources));
			break;
		case LoanPurposeEnums.BUY_OUT:
			questions.push(...getFormConfigurationForProjectPurposeBuyOut(formData, resources));
			break;
	}
	questions.push(...defaultLoanApplicationAdditionalRefinance(formData, resources, creditProviders));
	return questions;
}

function getFormConfigurationForProjectPurposePurchase(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const notaryForm = new FormConfiguration();
	notaryForm.setName('notaryForm');

	notaryForm.formControl.questions = [
		new InputAsyncSearch({
			bindLabel: 'label',
			key: 'notary',
			value: formData?.notary,
			label: 'ç.question.notary.label',
			placeholder: 'ç.misc.action.search',
			noFoundTextLabel: 'ç.misc.nothingFound',
			required: true,
			hideRequiredAsterisk: true,
			hideArrow: true,
			debounceTime: debounceTimes.xxs,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			class: 'span12',
			prefillDefaultValue: true,
			endpointSettings: {
				method: (searchTerm: string) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.searchThirdParties('notaries', searchTerm);
				},
				transform: (notaries: any[]) =>
					notaries.map((notary) => ({
						id: notary.id,
						name: notary.name,
						addresses: notary.addresses,
						vatNumber: notary.vatNumber,
						label: `${formatText(notary.name)}, ${notary.addresses[0]?.houseNumber !== '' && notary?.addresses[0]?.houseNumber ? notary?.addresses[0]?.houseNumber + ' ' : ''}${notary.addresses[0]?.street}, ${notary.addresses[0]?.zipCode} ${formatText(notary.addresses[0]?.city)}`,
					})),
				initialLoadMethod: (value: any) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.getThirdParty('notaries', value);
				},
			},
		}),
	];

	const questions: InputBase<any>[] = [
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDateValidUntil',
			label: 'ç.question.epcValidity.label',
			value: formData?.epcDateValidUntil,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.question.notary.title',
			key: 'notary',
			clearOnClose: true,
			formConfiguration: notaryForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.notary.title',
			secondaryAction: true,
			required: false,
			requiredOnOpen: true,
			extractSingleNestedFormControl: true,
		}),
	];

	if (formData?.renovations?.length > 0) {
		questions.push(
			new DynamicInputTable({
				key: 'renovations',
				value: formData?.renovations,
				debounceTime: debounceTimes.xxs,
				showAsCard: true,
				cardTitle: 'ç.feature.realty.renovation.singular',
				addRowLabel: 'ç.feature.realty.renovations.addRenovation',
				label: 'ç.feature.mortgageSimulator.addIncome',
				required: true,
				columns: [
					new InputSelect({
						key: 'renovationType.id',
						required: true,
						clearable: true,
						label: 'ç.question.renovationType.label',
						validators: [],
						options: orderBy(resources?.[ResourceType.RENOVATION_TYPE] || [], 'order'),
						alreadySorted: true,
						class: 'span6',
					}),
					new InputField({
						key: 'amountContractor',
						label: 'ç.question.amount.label',
						type: 'number',
						required: true,
						currency: true,
						class: 'span6',
					}),
				],
			})
		);
	}
	return questions;
}

function getFormConfigurationForProjectPurposeNewBuild(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const notaryForm = new FormConfiguration();
	notaryForm.setName('notaryForm');

	notaryForm.formControl.questions = [
		new InputAsyncSearch({
			bindLabel: 'label',
			key: 'notary',
			value: formData?.notary,
			label: 'ç.question.notary.label',
			placeholder: 'ç.misc.action.search',
			noFoundTextLabel: 'ç.misc.nothingFound',
			required: true,
			hideRequiredAsterisk: true,
			hideArrow: true,
			debounceTime: debounceTimes.xxs,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			class: 'span12',
			prefillDefaultValue: true,
			endpointSettings: {
				method: (searchTerm: string) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.searchThirdParties('notaries', searchTerm);
				},
				transform: (notaries: any[]) =>
					notaries.map((notary) => ({
						id: notary.id,
						name: notary.name,
						addresses: notary.addresses,
						vatNumber: notary.vatNumber,
						label: `${formatText(notary.name)}, ${notary.addresses[0]?.houseNumber !== '' && notary?.addresses[0]?.houseNumber ? notary?.addresses[0]?.houseNumber + ' ' : ''}${notary.addresses[0]?.street}, ${notary.addresses[0]?.zipCode} ${formatText(notary.addresses[0]?.city)}`,
					})),
				initialLoadMethod: (value: any) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.getThirdParty('notaries', value);
				},
			},
		}),
	];

	return [
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.question.notary.title',
			key: 'notary',
			clearOnClose: true,
			formConfiguration: notaryForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.notary.title',
			secondaryAction: true,
			required: false,
			requiredOnOpen: true,
			extractSingleNestedFormControl: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeRenovation(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const notaryForm = new FormConfiguration();
	notaryForm.setName('notaryForm');

	notaryForm.formControl.questions = [
		new InputAsyncSearch({
			bindLabel: 'label',
			key: 'notary',
			value: formData?.notary,
			label: 'ç.question.notary.label',
			placeholder: 'ç.misc.action.search',
			noFoundTextLabel: 'ç.misc.nothingFound',
			required: true,
			hideRequiredAsterisk: true,
			hideArrow: true,
			debounceTime: debounceTimes.xxs,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			class: 'span12',
			prefillDefaultValue: true,
			endpointSettings: {
				method: (searchTerm: string) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.searchThirdParties('notaries', searchTerm);
				},
				transform: (notaries: any[]) =>
					notaries.map((notary) => ({
						id: notary.id,
						name: notary.name,
						addresses: notary.addresses,
						vatNumber: notary.vatNumber,
						label: `${formatText(notary.name)}, ${notary.addresses[0]?.houseNumber !== '' && notary?.addresses[0]?.houseNumber ? notary?.addresses[0]?.houseNumber + ' ' : ''}${notary.addresses[0]?.street}, ${notary.addresses[0]?.zipCode} ${formatText(notary.addresses[0]?.city)}`,
					})),
				initialLoadMethod: (value: any) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.getThirdParty('notaries', value);
				},
			},
		}),
	];

	return [
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDateValidUntil',
			label: 'ç.question.epcValidity.label',
			value: formData?.epcDateValidUntil,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.question.notary.title',
			key: 'notary',
			clearOnClose: true,
			formConfiguration: notaryForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.notary.title',
			secondaryAction: true,
			required: false,
			requiredOnOpen: true,
			extractSingleNestedFormControl: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeRefinance(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const notaryForm = new FormConfiguration();
	notaryForm.setName('notaryForm');

	notaryForm.formControl.questions = [
		new InputAsyncSearch({
			bindLabel: 'label',
			key: 'notary',
			value: formData?.notary,
			label: 'ç.question.notary.label',
			placeholder: 'ç.misc.action.search',
			noFoundTextLabel: 'ç.misc.nothingFound',
			required: true,
			hideRequiredAsterisk: true,
			hideArrow: true,
			debounceTime: debounceTimes.xxs,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			class: 'span12',
			prefillDefaultValue: true,
			endpointSettings: {
				method: (searchTerm: string) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.searchThirdParties('notaries', searchTerm);
				},
				transform: (notaries: any[]) =>
					notaries.map((notary) => ({
						id: notary.id,
						name: notary.name,
						addresses: notary.addresses,
						vatNumber: notary.vatNumber,
						label: `${formatText(notary.name)}, ${notary.addresses[0]?.houseNumber !== '' && notary?.addresses[0]?.houseNumber ? notary?.addresses[0]?.houseNumber + ' ' : ''}${notary.addresses[0]?.street}, ${notary.addresses[0]?.zipCode} ${formatText(notary.addresses[0]?.city)}`,
					})),
				initialLoadMethod: (value: any) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.getThirdParty('notaries', value);
				},
			},
		}),
	];

	return [
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDateValidUntil',
			label: 'ç.question.epcValidity.label',
			value: formData?.epcDateValidUntil,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.question.notary.title',
			key: 'notary',
			clearOnClose: true,
			formConfiguration: notaryForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.notary.title',
			secondaryAction: true,
			required: false,
			requiredOnOpen: true,
			extractSingleNestedFormControl: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeBuyOut(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const notaryForm = new FormConfiguration();
	notaryForm.setName('notaryForm');

	notaryForm.formControl.questions = [
		new InputAsyncSearch({
			bindLabel: 'label',
			key: 'notary',
			value: formData?.notary,
			label: 'ç.question.notary.label',
			placeholder: 'ç.misc.action.search',
			noFoundTextLabel: 'ç.misc.nothingFound',
			required: true,
			hideRequiredAsterisk: true,
			hideArrow: true,
			debounceTime: debounceTimes.xxs,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			class: 'span12',
			prefillDefaultValue: true,
			endpointSettings: {
				method: (searchTerm: string) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.searchThirdParties('notaries', searchTerm);
				},
				transform: (notaries: any[]) =>
					notaries.map((notary) => ({
						id: notary.id,
						name: notary.name,
						addresses: notary.addresses,
						vatNumber: notary.vatNumber,
						label: `${formatText(notary.name)}, ${notary.addresses[0]?.houseNumber !== '' && notary?.addresses[0]?.houseNumber ? notary?.addresses[0]?.houseNumber + ' ' : ''}${notary.addresses[0]?.street}, ${notary.addresses[0]?.zipCode} ${formatText(notary.addresses[0]?.city)}`,
					})),
				initialLoadMethod: (value: any) => {
					const service = inject(THIRD_PARTY_SERVICE_TOKEN);
					return service.getThirdParty('notaries', value);
				},
			},
		}),
	];

	return [
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAddress.property.label',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAddress.placeholder',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			required: false,
			type: 'text',
			class: 'span6',
			markAsOptional: true,
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			bindLabel: 'key',
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDateValidUntil',
			label: 'ç.question.epcValidity.label',
			value: formData?.epcDateValidUntil,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.question.notary.title',
			key: 'notary',
			clearOnClose: true,
			formConfiguration: notaryForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.notary.title',
			secondaryAction: true,
			required: false,
			requiredOnOpen: true,
			extractSingleNestedFormControl: true,
		}),
	];
}
