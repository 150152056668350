<div [class]="class" class="section__container">
	@if (titleTranslateParams) {
		<div class="section__title">
			{{ title | translate: titleTranslateParams }}
		</div>
	} @else {
		<div class="section__title">
			{{ title | translate }}
		</div>
	}
</div>
