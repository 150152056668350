import { Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	BaseProductDiscounts,
	BorrowerSimulationDto,
	getStepsWhichHaveSavedDataPoints,
	Offer,
	Product,
	Resource,
	SeverityEnum,
	Simulator,
	Step,
	StepData,
	StepperConfiguration,
} from '@oper-client/shared/data-model';
import * as Actions from './borrower-simulator-application-flow.actions';
import * as Selectors from './borrower-simulator-application-flow.selectors';
import {
	BorrowerSimulatorEligibilityConstraint,
	BorrowerSimulatorFeatureConfiguration,
	IBorrowerSimulatorFacade,
} from '../../interface/mortgage-simulator-feature.interface';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Injectable()
export class BorrowerSimulatorApplicationFlowFacade implements IBorrowerSimulatorFacade {
	constructor(private readonly store: Store<any>) {}

	readonly configuration$ = this.store.pipe(select(Selectors.getConfiguration));
	readonly dataPoints$ = this.store.pipe(select(Selectors.getDataPoints));
	readonly data$ = this.store.pipe(select(Selectors.getData));
	readonly activeStep$ = this.store.pipe(select(Selectors.getActiveStep));
	readonly configuredSteps$ = this.store.pipe(select(Selectors.getSteps));
	readonly selectedPurpose$ = this.store.pipe(select(Selectors.getSelectedPurpose));
	readonly simulation$ = this.store.pipe(select(Selectors.getSimulation));
	readonly offers$ = this.store.pipe(select(Selectors.getOffers));
	readonly discounts$ = this.store.pipe(select(Selectors.getDiscounts));
	readonly products$ = this.store.pipe(select(Selectors.getProducts));
	readonly isReadOnlyMode$ = this.store.pipe(select(Selectors.isReadOnlyMode));
	readonly calculateSimulationActionState$ = this.store.pipe(select(Selectors.getCalculateSimulationActionState));
	readonly loadDiscountsActionState$ = this.store.pipe(select(Selectors.getLoadDiscountsActionState));
	readonly loadDefaultProductsActionState$ = this.store.pipe(select(Selectors.getLoadDefaultProductsActionState));
	readonly loadOffersActionState$ = this.store.pipe(select(Selectors.getLoadOffersActionState));
	readonly updateOfferActionState$ = this.store.pipe(select(Selectors.getUpdateOfferActionState));
	readonly loadSimulationActionState$ = this.store.pipe(select(Selectors.getLoadSimulationActionState));
	readonly convertSimulationToLoanRequestActionState$ = this.store.pipe(select(Selectors.getConvertToLoanRequestActionState));
	readonly createSimulationActionState$ = this.store.pipe(select(Selectors.getCreateSimulationActionState));
	readonly convertToLoanRequestResponse$ = this.store.pipe(select(Selectors.getConvertToLoanRequestResponse));

	readonly failedAcceptanceRules$ = this.offers$.pipe(
		map((offers) =>
			offers
				.map((offer) => offer.items)
				.flat()
				.map((item) => item.acceptanceRules)
				.flat()
				.filter(
					(rule, index, self) =>
						index === self.findIndex((r) => r.name === rule.name) &&
						!rule.success &&
						rule.severity.definition === SeverityEnum.ERROR
				)
		)
	);

	readonly eligibilityConstraint$ = this.configuration$.pipe(map((config) => config?.eligibilityConstraint));

	getOffers(): Signal<Partial<Offer>[]> {
		return toSignal(this.offers$);
	}

	getActiveStep(): Signal<Step | null> {
		return toSignal(this.activeStep$);
	}

	getSteps(): Signal<Step[]> {
		return toSignal(this.configuredSteps$);
	}

	getData<BorrowerSimulationDto>(): Signal<BorrowerSimulationDto> {
		return toSignal(this.data$) as Signal<BorrowerSimulationDto>;
	}

	getSelectedPurpose(): Signal<Resource> {
		return toSignal(this.selectedPurpose$);
	}

	getCompletedStepNames(): Signal<string[]> {
		return toSignal(this.dataPoints$.pipe(map((dataPoints) => getStepsWhichHaveSavedDataPoints(dataPoints))));
	}

	getSimulation(): Signal<Simulator.Simulation> {
		return toSignal(this.simulation$) as Signal<Simulator.Simulation>;
	}

	getConfiguration?(): Signal<StepperConfiguration> {
		return toSignal(this.configuration$);
	}

	getDefaultOffers(): Signal<Partial<Offer>[]> {
		return toSignal(this.offers$);
	}

	getDefaultProducts(): Signal<Product[]> {
		return toSignal(this.products$);
	}

	getDiscounts(): Signal<Partial<BaseProductDiscounts>[]> {
		return toSignal(this.discounts$);
	}

	getEligibilityConstraint(): Signal<Partial<BorrowerSimulatorEligibilityConstraint>> {
		return toSignal(this.eligibilityConstraint$);
	}

	isReadOnlyMode(): Signal<boolean> {
		return toSignal(this.isReadOnlyMode$);
	}

	getSelectedBorrowerMode(): Signal<Resource> {
		return toSignal(this.store.pipe(select(Selectors.getNumberOfBorrowers)));
	}

	setActiveStep(step: Step): void {
		this.store.dispatch(Actions.setActiveStep({ step }));
	}

	setSelectedPurpose(purpose: Resource): void {
		this.store.dispatch(Actions.setSelectedPurpose({ purpose }));
	}

	setSelectedBorrowerMode(borrowerMode: Resource): void {
		this.store.dispatch(Actions.setSelectedBorrowerMode({ borrowerMode }));
	}

	setSimulation(simulation: Simulator.Simulation): void {
		this.store.dispatch(Actions.setSimulation({ simulation }));
	}

	updateActiveStep(changes: Partial<Step>) {
		this.store.dispatch(Actions.updateActiveStep({ changes }));
	}

	setConfiguration(configuration: BorrowerSimulatorFeatureConfiguration): void {
		this.store.dispatch(Actions.setConfiguration({ configuration }));
	}

	setReadOnlyMode(isReadOnly: boolean): void {
		this.store.dispatch(Actions.setReadOnlyMode({ isReadOnly }));
	}

	updateConfiguration(changes: Partial<BorrowerSimulatorFeatureConfiguration>) {
		this.store.dispatch(Actions.updateConfiguration({ changes }));
	}

	nextStep(): void {
		this.store.dispatch(Actions.nextStep());
	}

	previousStep(): void {
		this.store.dispatch(Actions.prevStep());
	}

	setData(data: Partial<BorrowerSimulationDto>): void {
		this.store.dispatch(Actions.setData({ data }));
	}

	updateData(data: Partial<BorrowerSimulationDto>): void {
		this.store.dispatch(Actions.updateData({ data }));
	}

	setDataForStep<T>(stepData: StepData<T>): void {
		this.store.dispatch(Actions.setDataForStep({ ...stepData }));
	}

	reset(): void {
		this.store.dispatch(Actions.reset());
	}

	clearData(): void {
		this.store.dispatch(Actions.clearData());
	}

	calculateSimulation(payload: Simulator.CalculateSimulationPayload): void {
		this.store.dispatch(Actions.calculateSimulation({ payload }));
	}

	loadOffers(payload: Simulator.LoadOffersPayload, queryParams?: Partial<Simulator.LoadOffersQueryParams>): void {
		this.store.dispatch(Actions.loadOffers({ payload, queryParams }));
	}

	updateOffer(payload: Partial<Simulator.UpdateOfferPayload>, queryParams?: Partial<Simulator.UpdateOfferQueryParams>): void {
		this.store.dispatch(Actions.updateOffer({ payload, queryParams }));
	}

	loadDiscounts(payload: Partial<Simulator.LoadDiscountsPayload>, selectDefaultDiscounts = false) {
		this.store.dispatch(Actions.loadDiscounts({ payload, selectDefaultDiscounts }));
	}

	loadDefaultProducts(payload: Simulator.SearchProductsPayload): void {
		this.store.dispatch(Actions.loadDefaultProducts({ payload }));
	}

	resetSimulationReport(): void {
		this.store.dispatch(Actions.resetSimulationReport());
	}

	resetSimulationActions(): void {
		this.store.dispatch(Actions.resetSimulationActions());
	}

	loadSimulation(simulationId: number): void {
		this.store.dispatch(Actions.loadSimulation({ simulationId }));
	}

	convertSimulationToLoanRequest(payload: Simulator.ConvertToLoanRequestPayload): void {
		this.store.dispatch(Actions.convertSimulationToLoanRequest({ payload }));
	}

	createSimulation(simulation: Partial<Simulator.Simulation>): void {
		this.store.dispatch(Actions.createSimulation({ simulation }));
	}
}
