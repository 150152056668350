import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnInit,
	Optional,
	output,
	Self,
	signal,
} from '@angular/core';
import { DestroyableComponent } from '@shared/util-component';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UtilService } from '@oper-client/shared/util-formatting';
import { map, noop, Subject } from 'rxjs';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { removeNullValues, deepClone } from '@oper-client/shared/util-object';

@Component({
	selector: 'oper-client-dynamic-input-single-row',
	templateUrl: './dynamic-input-single-row.html',
	styleUrls: ['./dynamic-input-single-row.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputSingleRowComponent extends DestroyableComponent implements AfterViewInit, OnInit, ControlValueAccessor {
	readonly formConfiguration = input.required<FormConfiguration>();
	readonly debounceTime = input<number>(debounceTimes.s);
	readonly valueChange = output<object>();
	readonly removeCard = output<void>();

	readonly formChanged$ = new Subject<FormGroupWithWarning>();
	readonly label = signal<string[]>([]);
	readonly row = signal<FormConfiguration>(new FormConfiguration());

	private readonly destroyRef = inject(DestroyRef);
	constructor(
		@Self() @Optional() public control: NgControl,
		readonly utilService: UtilService
	) {
		super();
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.row.set(deepClone(this.formConfiguration()));
		this.row().formControl.questions.forEach((question) => {
			this.label().push(question.label);
			delete question.label;
		});
	}

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	ngOnInit(): void {
		this.formChanged$
			.pipe(
				map((form) => removeNullValues(this.utilService.erectObject(form.value))),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((value) => {
				this.valueChange.emit(value);
			});
	}

	removeCardAction(): void {
		this.valueChange.emit(null);
		this.removeCard.emit();
	}

	writeValue(obj: any[]): void {}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(disabled: boolean): void {}
}
