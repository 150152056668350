import { Component, ElementRef, Input, OnDestroy, HostListener } from '@angular/core';
import { faQuestionCircle, IconDefinition } from '@oper-client/shared/util-fontawesome';

import { TooltipService } from '../../services/tooltip.service';

@Component({
	selector: 'oper-client-tooltip-indicator',
	styleUrls: ['./tooltip-indicator.component.scss'],
	template: `
		<div
			class="tooltip__indicator"
			[ngClass]="{
				danger: type === 'danger'
			}"
			(click)="toggle($event)"
		>
			<fa-icon [icon]="icon" [fixedWidth]="true"></fa-icon>
		</div>
	`,
})
export class TooltipIndicatorComponent implements OnDestroy {
	@Input() text: string;
	@Input() parentContainer: Element;
	@Input() icon: IconDefinition = faQuestionCircle;
	@Input() type: 'info' | 'danger' = 'info';

	constructor(
		private tooltipService: TooltipService,
		private elementRef: ElementRef
	) {}

	public toggle(event: MouseEvent): void {
		const indicatorBoundingClientRect = this.elementRef.nativeElement.getBoundingClientRect();
		const parentRect = this.parentContainer
			? this.parentContainer.getBoundingClientRect()
			: (event.target as HTMLElement).getBoundingClientRect();
		this.tooltipService.toggle(this.text, indicatorBoundingClientRect, parentRect);
		event.stopPropagation();
	}

	public ngOnDestroy() {
		this.tooltipService.flush();
	}

	@HostListener('document:click', ['$event']) clickout(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.tooltipService.flush();
		}
	}
}
