// also exists in simulator.constants.ts
export enum RealtyPurposeEnum {
	BUY = 'buy',
	BUILD = 'build',
	EXTENSION = 'extension',
	MODERNIZATION = 'modernization',
	REFINANCE = 'refinance',
	RENOVATE = 'renovate',
	COLLATERAL = 'collateral',
	BUY_OUT = 'buyOut',
}
