import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, CustomHttpUrlEncodingCodec, IApiService, IClientProfileService } from '@oper-client/shared/data-access';
import { ClientProfile, PaginatedSearchResponse } from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ClientProfileService implements IClientProfileService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getClient(id: number): Observable<ClientProfile> {
		return this.apiService.get(`/api/clients/${id}`);
	}

	getAll(searchTerm: string, page = 1): Observable<PaginatedSearchResponse<ClientProfile>> {
		const defaultPageSize = 20;

		let httpParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
		httpParams = httpParams.append('page', page);
		httpParams = httpParams.append('page_size', defaultPageSize);

		if (searchTerm) {
			httpParams = httpParams.append('search', searchTerm);
		}

		return this.apiService.get(`/api/clients/`, httpParams);
	}
}
