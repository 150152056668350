import { InjectionToken } from '@angular/core';
import { ComplianceCheck, ComplianceCheckConfig } from '@oper-client/shared/data-model';
import { Observable } from 'rxjs';

export const COMPLIANCE_CHECK_SERVICE = new InjectionToken<IComplianceCheckService>('COMPLIANCE_CHECK_SERVICE');

export interface IComplianceCheckService {
	getConfig(): Observable<ComplianceCheckConfig>;
	getComplianceCheck(id: number): Observable<ComplianceCheck[]>;
	createComplianceCheck(id: number, complianceCheck: ComplianceCheck): Observable<ComplianceCheck[]>;
	updateComplianceCheck(id: number, changes: Partial<ComplianceCheck>): Observable<ComplianceCheck[]>;
}
