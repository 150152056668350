import { NormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Realty>, resources?: NormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span6',
			order: 1,
		}),
		new InputSelect({
			key: 'landPurchaseType.id',
			label: 'ç.question.typeOfPurchase.label',
			value: formData?.landPurchaseType?.id,
			required: false,
			clearable: false,
			options: resources?.[ResourceType.LAND_PURCHASE_TYPE],
			class: 'span6',
		}),
	];
}
