import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject, Observable, throwError, of, BehaviorSubject, from } from 'rxjs';
import { catchError, map, skip, switchMap } from 'rxjs/operators';

import { API_SERVICE, convertObjectToHttpParams, IApiService, IAuthService } from '@oper-client/shared/data-access';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';
import { IAM, JoinDetails, PrivacyPolicy } from '@oper-client/shared/data-model';
import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';
import { APP_CONFIG, ApplicationName, Configuration } from '@oper-client/shared/configuration';
import { KeycloakService } from 'keycloak-angular';

const REFRESH_TOKEN_KEY = 'refreshToken';
const ACCESS_TOKEN_KEY = 'accessToken';
const LOGIN_METHOD_KEY = 'loginMethod';

enum LoginMethodEnum {
	BASIC = 'basic',
	KEYCLOAK_SSO = 'keycloak-sso',
}

@Injectable()
export class AuthService implements IAuthService {
	public token$: Subject<any>;

	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(APP_CONFIG) private readonly configuration: Configuration,
		private readonly localStorageService: LocalStorageService,
		private readonly jwtHelper: JwtHelperService,
		private readonly localeFormatService: EnvironmentLocaleFormatService,
		private readonly keycloakService: KeycloakService = null
	) {
		this.token$ = new BehaviorSubject<any>({ access: this.localStorageService.get(ACCESS_TOKEN_KEY) });
		this.token$.pipe(skip(1)).subscribe((tokens) => {
			if (typeof tokens.access !== 'undefined') {
				if (tokens.access) {
					this.localStorageService.set(ACCESS_TOKEN_KEY, tokens.access);
				} else {
					this.localStorageService.unset(ACCESS_TOKEN_KEY);
				}
			}
			// For refreshes, we don't get a new refresh token.
			if (typeof tokens.refresh !== 'undefined') {
				if (tokens.refresh) {
					this.localStorageService.set(REFRESH_TOKEN_KEY, tokens.refresh);
				} else {
					this.localStorageService.unset(REFRESH_TOKEN_KEY);
				}
			}
		});
	}

	public setTokens(tokens) {
		this.token$.next(tokens);
	}

	public refreshToken(): Observable<any> {
		if (this.isKeycloakSsoLogin()) {
			return from(this.keycloakService.updateToken());
		}

		const token = this.getRefreshToken();
		if (!token) {
			return throwError('No refresh token');
		}
		const body = {
			refresh: token,
		};
		return this.apiService.post('/api/jwt/refresh/', body).pipe(
			map((response) => {
				this.token$.next(response);
			}),
			catchError((error) => {
				return throwError(error);
			})
			// mergeMap(() => {
			// 	return this.permissionsService.setPermissions();
			// })
		);
	}

	public getAccessToken() {
		if (this.isKeycloakSsoLogin()) {
			return this.keycloakService.getKeycloakInstance().token;
		}

		return this.localStorageService.get(ACCESS_TOKEN_KEY);
	}

	public getRefreshToken() {
		if (this.isKeycloakSsoLogin()) {
			return this.keycloakService.getKeycloakInstance().refreshToken;
		}

		return this.localStorageService.get(REFRESH_TOKEN_KEY);
	}

	public unsetTokens() {
		this.token$.next({ access: null, refresh: null });
	}

	public isAuthenticated() {
		let isAuthenticated = !this.jwtHelper.isTokenExpired();

		if (this.isKeycloakSsoLogin()) {
			isAuthenticated = this.keycloakService.isLoggedIn();
		}

		return isAuthenticated;
	}

	public login(body: IAM.UserCredentials): Observable<IAM.JwtTokens> {
		this.setLoginMethod(LoginMethodEnum.BASIC);
		return this.apiService.post('/api/jwt/', body);
	}

	ssoLogin() {
		this.setLoginMethod(LoginMethodEnum.KEYCLOAK_SSO);
		return from(
			this.keycloakService.login({ redirectUri: this.configuration?.customerInsights?.kcAuth?.redirectUri }).then(() => {
				return {
					access: this.keycloakService.getKeycloakInstance().token,
					refresh: this.keycloakService.getKeycloakInstance().refreshToken,
				};
			})
		);
	}

	public getCurrentUser(): Observable<IAM.User> {
		if (this.isKeycloakSsoLogin()) {
			const accessToken = this.keycloakService.getKeycloakInstance().token;
			const unpackedJWT = this.jwtHelper.decodeToken(accessToken);
			if (!unpackedJWT) {
				return of(null);
			}

			return this.getLoggedUser(unpackedJWT);
		}

		return this.token$.pipe(
			map((tokens) => tokens?.access && this.jwtHelper.decodeToken(tokens.access)),
			switchMap((unpackedJWT) => {
				if (unpackedJWT) {
					return this.getLoggedUser(unpackedJWT);
				} else {
					return of(null);
				}
			})
		);
	}

	updateCurrentUser(user: Partial<IAM.User>): Observable<IAM.User> {
		return this.apiService.patch(`/api/me/`, { bank_ids: user.bankIds });
	}

	public logout(): Observable<void> {
		const observable = this.isKeycloakSsoLogin()
			? from(this.keycloakService.logout(this.configuration.customerInsights.kcAuth.postLogoutRedirectUri))
			: of(null);
		this.localStorageService.unset(LOGIN_METHOD_KEY);
		this.unsetTokens();
		return observable;
	}

	public forgotPassword(email: string): Observable<any> {
		const body = {
			email: email,
		};
		const applicationName = this.configuration.applicationInsights.appName;
		const params = convertObjectToHttpParams({
			'user-type':
				applicationName === ApplicationName.BROKERAGE
					? 'application-user'
					: applicationName === ApplicationName.SELF_SERVICE
						? 'client-user'
						: 'base-user',
		});
		return this.apiService.post('/api/forgot-password/', body, params);
	}

	public resetPassword(body: IAM.ResetPassword): Observable<any> {
		return this.apiService.post('/api/reset-password/', body);
	}

	public resetPassword2Fa(body: IAM.ResetPassword): Observable<any> {
		return this.apiService.post('/api/sign-in/reset-password/', body);
	}

	public checkResetPasswordToken(token: string) {
		const body = {
			token: token,
		};
		return this.apiService.post('/api/check-reset-password/', body);
	}

	public activateUser(token: string): Observable<void> {
		return this.apiService.post('/api/me/activate/', { token });
	}

	public verifyUser(code: string, token: string): Observable<void> {
		return this.apiService.post('/api/me/verify/', { token, code });
	}

	public validateCredentials(username: string, password: string): Observable<any> {
		return this.apiService.post('/api/sign-in/otp/', { username, password });
	}

	public activateOTP(token: string): Observable<any> {
		return this.apiService.post('/api/sign-in/activate/', { token });
	}

	public verifyOTP(token: string, code: string): Observable<any> {
		return this.apiService.post('/api/sign-in/verify/', { code, token });
	}

	public getPrivacyPolicy(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/privacy-policy/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	getTermsAndConditions(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/terms-and-conditions/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	getGDPRPolicy(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<PrivacyPolicy> {
		return this.apiService.get(
			`/api/gdpr-policy/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	public createUser(userData: JoinDetails): Observable<any> {
		return this.apiService.post('/api/sign-up/create/', userData);
	}

	public updateProfile(profileData: JoinDetails): Observable<{ token: string }> {
		return this.apiService.put('/api/sign-up/profile/', profileData);
	}

	public inviteUser(userData: JoinDetails): Observable<any> {
		return this.apiService.post('/api/sign-up/reset-password/', userData);
	}

	public checkInviteToken(token: string) {
		const body = {
			token: token,
		};
		return this.apiService.post('/api/check-invite-token/', body);
	}

	private isKeycloakSsoLogin() {
		return this.localStorageService.get(LOGIN_METHOD_KEY) === LoginMethodEnum.KEYCLOAK_SSO && !!this.keycloakService?.isLoggedIn();
	}

	private setLoginMethod(method: LoginMethodEnum) {
		this.localStorageService.set(LOGIN_METHOD_KEY, method);
	}

	private getLoggedUser(unpackedJWT: any): Observable<IAM.User> {
		const { role, language } = unpackedJWT;
		return this.apiService.get('/api/me/').pipe(
			map(
				(apiUser) =>
					({
						...apiUser,
						role: role?.definition,
						language: apiUser.language ? apiUser.language?.definition : language?.definition,
						fullname: `${this.localeFormatService.formatFullName(apiUser)}`,
					}) as IAM.User
			)
		);
	}
}
