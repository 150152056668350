import { Inject, Injectable } from '@angular/core';
import { EMPTY, expand, mergeMap, of, reduce } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

import * as AdvisorSimulatorActions from './advisor-simulator.actions';
import { updateLoadedProductsCount } from './advisor-simulator.actions';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';
import { ADVISOR_SIMULATOR_SERVICE, IAdvisorSimulatorService } from '@oper-client/shared/data-access';
import { getDefaultCustomerConfiguration } from '../../tokens/advisor-simulator-feature.tokens';
import { getQueryParams } from '@oper-client/shared/util-url';
import { CustomersEnum } from '@oper-client/shared/configuration';
import { PaginatedSearchResponse, Product, ProductExplorerSolution } from '@oper-client/shared/data-model';
import { Store } from '@ngrx/store';
import * as fromSimulator from './advisor-simulator.reducer';

@Injectable()
export class AdvisorSimulatorEffects {
	calculateSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.calculateSimulation),
			switchMap(({ payload }) =>
				this.simulatorService.calculateSimulation(payload).pipe(
					map((result) => AdvisorSimulatorActions.calculateSimulationSuccess({ result, payload })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.calculateSimulationFailure({ error })))
				)
			)
		)
	);

	loadSimulations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadSimulations),
			switchMap(({ queryParams, ordering }) =>
				this.simulatorService.loadSimulations(queryParams, ordering).pipe(
					map((response) => AdvisorSimulatorActions.loadSimulationsSuccess({ response })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadSimulationsFailure({ error })))
				)
			)
		)
	);

	loadMoreSimulations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadMoreSimulations),
			switchMap(({ searchResponse, ordering }) =>
				this.simulatorService.loadSimulations(getQueryParams(searchResponse.next), ordering).pipe(
					map((response) => AdvisorSimulatorActions.loadMoreSimulationsSuccess({ response })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadMoreSimulationsFailure({ error })))
				)
			)
		)
	);

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadProducts),
			switchMap(({ payload, filters }) => {
				let page = 1;

				return this.simulatorService.loadProducts(payload, { ...filters, page }).pipe(
					expand((response) => {
						if (!response.next) {
							return EMPTY;
						}
						page += 1;
						return this.simulatorService.loadProducts(payload, { ...filters, page });
					}),
					reduce((allProducts: Product[], currentResponse: PaginatedSearchResponse<Product>) => {
						const combinedProducts = [...allProducts, ...currentResponse.results];

						this.store.dispatch(
							updateLoadedProductsCount({
								totalLoadedProductsProgress: (combinedProducts.length / currentResponse.count) * 100,
							})
						);

						return combinedProducts;
					}, []),
					map((products: Product[]) =>
						AdvisorSimulatorActions.loadProductsSuccess({
							products: products.map((product) => ({ ...product, selected: false })),
						})
					),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadProductsFailure({ error })))
				);
			})
		)
	);

	loadSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadSimulation),
			switchMap(({ simulationId }) =>
				this.simulatorService.loadSimulation(simulationId).pipe(
					map((simulation) => AdvisorSimulatorActions.loadSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadSimulationFailure({ error })))
				)
			)
		)
	);

	loadSimulationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadSimulationSuccess),
			map(({ simulation }) => {
				return AdvisorSimulatorActions.setCurrentSimulation({ simulation });
			})
		)
	);

	loadSimulationSuccessUpdateSolutions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadSimulationSuccess),
			map(({ simulation }) => {
				const solutions: ProductExplorerSolution[] =
					simulation?.selectedProducts.length > 0
						? simulation?.selectedProducts?.map((solutionProducts) => ({
								selectedProducts: solutionProducts as Product[],
							}))
						: [{ selectedProducts: [] }];

				return AdvisorSimulatorActions.setSolutions({ solutions });
			})
		)
	);

	createSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.createSimulation),
			switchMap(({ simulation }) =>
				this.simulatorService.createSimulation(simulation).pipe(
					map((simulation) => AdvisorSimulatorActions.createSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.createSimulationFailure({ error })))
				)
			)
		)
	);

	updateSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.updateSimulation),
			switchMap(({ simulationId, simulation }) =>
				this.simulatorService.updateSimulation(simulationId, simulation).pipe(
					map((simulation) => AdvisorSimulatorActions.updateSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.updateSimulationFailure({ error })))
				)
			)
		)
	);

	removeSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.removeSimulation),
			switchMap(({ simulationId }) =>
				this.simulatorService.removeSimulation(simulationId).pipe(
					map(() => AdvisorSimulatorActions.removeSimulationSuccess({ simulationId })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.removeSimulationFailure({ error })))
				)
			)
		)
	);

	cleanUpSimulations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.cleanUpSimulations),
			switchMap(() =>
				this.simulatorService.cleanUpSimulations().pipe(
					map(() => AdvisorSimulatorActions.cleanUpSimulationsSuccess()),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.cleanUpSimulationsFailure({ error })))
				)
			)
		)
	);

	loadOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadOffers),
			mergeMap(({ payload, queryParams }) =>
				this.simulatorService.loadOffers(payload, queryParams).pipe(
					map((offers) => AdvisorSimulatorActions.loadOffersSuccess({ offers })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadOffersFailure({ error })))
				)
			)
		)
	);

	updateOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.updateOffer),
			switchMap(({ payload, queryParams }) =>
				this.simulatorService.updateOffer(payload, queryParams).pipe(
					map((offer) => AdvisorSimulatorActions.updateOfferSuccess({ offer })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.updateOfferFailure({ error })))
				)
			)
		)
	);

	loadDiscounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadDiscounts),
			switchMap(({ payload, selectDefaultDiscounts }) =>
				this.simulatorService.loadDiscounts(payload, selectDefaultDiscounts).pipe(
					map((discounts) => AdvisorSimulatorActions.loadDiscountsSuccess({ discounts })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadDiscountsFailure({ error })))
				)
			)
		)
	);

	convertSimulationToLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.convertSimulationToLoanRequest),
			switchMap(({ payload }) =>
				this.simulatorService.convertSimulationToLoanRequest(payload).pipe(
					map((response) => AdvisorSimulatorActions.convertSimulationToLoanRequestSuccess({ response })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.convertSimulationToLoanRequestFailure({ error })))
				)
			)
		)
	);

	loadConfiguration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadConfiguration),
			distinctUntilChanged((previous, current) => previous.customer === current.customer),
			switchMap(({ customer }) =>
				of(getDefaultCustomerConfiguration(customer as CustomersEnum)).pipe(
					map((configuration) => AdvisorSimulatorActions.loadConfigurationSuccess({ configuration })),
					catchError((error) => of(AdvisorSimulatorActions.loadConfigurationFailure({ error })))
				)
			)
		)
	);

	loadBorrowerDocumentTypes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdvisorSimulatorActions.loadBorrowerDocumentTypes),
			switchMap(() =>
				this.simulatorService.loadBorrowerDocumentTypes().pipe(
					map((documents) => AdvisorSimulatorActions.loadBorrowerDocumentTypesSuccess({ documents })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(AdvisorSimulatorActions.loadBorrowerDocumentTypesFailure({ error })))
				)
			)
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<fromSimulator.AdvisorSimulatorState>,
		@Inject(ADVISOR_SIMULATOR_SERVICE) private readonly simulatorService: IAdvisorSimulatorService
	) {}
}
