import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	input,
	OnInit,
	Optional,
	output,
	Self,
} from '@angular/core';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { UtilService } from '@oper-client/shared/util-formatting';
import { ControlType } from '../../models/input-base.model';
import { BehaviorSubject, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'oper-client-dynamic-form-card',
	templateUrl: './dynamic-form-card.component.html',
	styleUrl: './dynamic-form-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormCardComponent implements ControlValueAccessor, OnInit, AfterViewInit {
	private _destroyRef = inject(DestroyRef);

	readonly key = input.required<string | null>();
	readonly title = input.required<string | null>();
	readonly formConfiguration = input.required<FormConfiguration | null>();
	readonly subtitle = input<string>('');
	readonly debounceTime = input<number>(0);
	readonly showDeleteButton = input<boolean>(false);
	readonly requiredOnOpen = input<boolean>(false);
	readonly clearOnClose = input<boolean>(true);

	readonly removeCard = output<string>();
	readonly valueChange = output<any>();

	readonly formReference$ = new BehaviorSubject<FormGroupWithWarning>(null);

	readonly hasInvalidForm$ = this.formReference$.pipe(map((form) => (form ? form.invalid : false)));

	parentForm: FormGroupWithWarning;
	readonly nestedControlName = 'nestedControlFormGroup';

	constructor(
		@Self() @Optional() public control: NgControl,
		readonly utilService: UtilService
	) {
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.formReference$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
	}

	ngOnInit(): void {
		this.parentForm = this.control.control.parent as FormGroupWithWarning;

		this.hasInvalidForm$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isInvalid) => {
			if (isInvalid) {
				if (this.requiredOnOpen() && !this.parentForm.contains(this.nestedControlName)) {
					this.parentForm.addControl(this.nestedControlName, new FormControl(null, Validators.required));
					this.valueChange.emit(null);
				}
			} else {
				if (this.requiredOnOpen() && this.parentForm.contains(this.nestedControlName)) {
					this.parentForm.removeControl(this.nestedControlName);
				}
			}
		});

		this._destroyRef.onDestroy(() => {
			if (this.parentForm.contains(this.nestedControlName) && this.requiredOnOpen()) {
				this.parentForm.removeControl(this.nestedControlName);
			}

			if (this.clearOnClose() && this.formReference$.value) {
				this.formReference$.value.reset(null, { emitEvent: false });
			}

			this.formReference$.complete();
		});
	}

	onFormValueChange(formGroup: FormGroupWithWarning): void {
		const hasControls = Object.keys(formGroup?.controls ?? {}).length > 0;
		if (!hasControls) {
			return;
		}

		if (formGroup.valid) {
			const changes = this.utilService.erectObject(formGroup.value);
			this.valueChange.emit(changes);
		}

		this.formReference$.next(formGroup);
	}

	isQuestion(controlType: ControlType): boolean {
		return !['space', 'section', 'header', 'information-box', 'separator'].includes(controlType);
	}

	writeValue(obj: any): void {}

	registerOnChange(fn: any): void {}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {}
}
