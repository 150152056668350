import { Inject, Injectable, signal } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AI_VALIDATION_SERVICE, IAiValidationServiceService } from '@oper-client/shared/data-access';
import { concatMap, delayWhen, filter, forkJoin, mergeMap, Observable, of, repeat, takeWhile, tap, timer } from 'rxjs';
import { Action } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';
import * as AIValidationActions from './ai-validation.actions';

const VERIFICATION_TYPE = 'data_extraction';

@Injectable()
export class AiValidationEffects {
	$validationEnabled = signal(false);

	loadFieldMapping$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.loadFieldMapping),
				switchMap(() => {
					return this.validationService.getConfig().pipe(
						map((config) =>
							AIValidationActions.loadFieldMappingSuccess({
								config,
							})
						),
						catchError((error) => of(AIValidationActions.loadFieldMappingFailure({ error: error })))
					);
				})
			)
	);

	triggerValidation$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.triggerValidation),
				filter(() => this.$validationEnabled()),
				concatMap((props) => {
					return this.validationService.triggerValidation(props.loanRequestId, props.proofId, props.docId).pipe(
						map((res) => {
							return AIValidationActions.triggerValidationSuccess({
								...props,
								id: res.id,
							});
						}),
						catchError((error) => of(AIValidationActions.triggerValidationFailure({ error: error })))
					);
				})
			)
	);

	loadValidationResult$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AIValidationActions.loadValidationResults),
			mergeMap((props) =>
				forkJoin(
					props.validations.map((v) =>
						this.validationService.getValidationResult(v, VERIFICATION_TYPE).pipe(
							catchError(() => of(null)),
							map((result) => ({
								result,
								retry: this.$validationEnabled() && result && result?.status !== 'finished',
							})),
							delayWhen(({ retry }) => {
								return retry ? timer(5000) : of(0);
							}),
							repeat(5),
							takeWhile(({ retry }) => retry, true)
						)
					)
				).pipe(
					map((results) => {
						const filteredRes = results.filter(({ result }) => !!result);
						if (!filteredRes.length) {
							return AIValidationActions.loadValidationResultsFailure();
						}

						return AIValidationActions.loadValidationResultsSuccess({
							validationResults: filteredRes.map(({ result }) => result),
						});
					})
				)
			)
		)
	);

	disableValidationTrigger$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.clearValidationResults),
				tap(() => this.$validationEnabled.set(false))
			),
		{ dispatch: false }
	);

	enableValidationTrigger$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.enableValidationTrigger),
				tap(() => this.$validationEnabled.set(true))
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		@Inject(AI_VALIDATION_SERVICE) private validationService: IAiValidationServiceService
	) {}
}
