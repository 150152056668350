import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (): InputBase<any>[] {

	return [
		new InputField({
			key: 'firstField',
			placeholder: 'Placeholder',
			class: 'span12',
            label: 'ZIP code'
		}),
        new InputField({
            key: 'secondField',
            placeholder: 'Placeholder',
            class: 'span12',
            label: 'City'
        }),
        new InputField({
            key: 'secondField',
            placeholder: 'Placeholder',
            class: 'span12',
            label: 'Street'
        }),
        new InputField({
            key: 'secondField',
            placeholder: 'Placeholder',
            class: 'span12',
            label: 'Type of street'
        }),
        new InputField({
            key: 'secondField',
            placeholder: 'Placeholder',
            class: 'span12',
            label: 'House number'
        }),
	];
}
