import { Comment } from './comment.model';
import { CommunicationHistory, History } from './history.model';
import { CreditProvider, Product } from './offer.model';
import { Resource } from './resource.model';
import { ThirdParty } from './third-party.model';
import { User } from './user.model';
import { LoanAmountField, OwnFundField } from './financial-plan.model';

export enum LoanRequestCostPropertyNames {
	COST_PURCHASE = 'costPurchase',
	COST_GROUP_PURCHASE = 'purchaseCosts',
	COST_LAND_PURCHASE = 'costLandPurchase',
	COST_BUILDING = 'costBuilding',
	COST_RENOVATION = 'costRenovation',
	COST_BOYOUT = 'costBuyout',
	COST_CREDIT_TAKEOVER = 'costCreditTakeover',
	COST_HANDLING = 'costHandling',
	COST_FILE = 'costFile',
	COST_VAT = 'costVat',
	COST_CREDIT_NOTARY = 'costCreditNotary',
	COST_CREDIT_NOTARY_VAT = 'costCreditNotaryVat',
	COST_CREDIT_NOTARY_FEES = 'costCreditNotaryFees',
	COST_CREDIT_NOTARY_ADMINISTRATION = 'costCreditNotaryAdministration',
	COST_CREDIT_NOTARY_MORTGAGE_REGISTRATION_FEE = 'costCreditNotaryMortgageRegistrationFee',
	COST_CREDIT_NOTARY_MORTGAGE_GUARANTEE_FEE = 'costCreditNotaryMortgageGuaranteeFee',
	COST_CREDIT_NOTARY_DISBURSEMENTS = 'costCreditNotaryDisbursements',
	COST_CREDIT_NOTARY_ANNEXES = 'costCreditNotaryAnnexes',
	COST_CREDIT_NOTARY_ACT = 'costCreditNotaryAct',
	COST_CREDIT_REGISTRATION = 'costCreditRegistration',
	COST_DISTRIBUTION_NOTARY = 'costDistributionNotary',
	COST_DISTRIBUTION_REGISTRATION = 'costDistributionRegistration',
	COST_PURCHASE_REGISTRATION = 'costPurchaseRegistration',
	COST_GROUP_CREDIT = 'creditCosts',
	COST_GROUP_OTHER = 'otherCosts',
	COST_GROUP_DISTRIBUTION = 'distributionCosts',
	COST_PURCHASE_NOTARY = 'costPurchaseNotary',
	COST_PURCHASE_NOTARY_ACT = 'costPurchaseNotaryAct',
	COST_PURCHASE_NOTARY_VAT = 'costPurchaseNotaryVat',
	COST_PURCHASE_NOTARY_ADMINISTRATION = 'costPurchaseNotaryAdministration',
	COST_PURCHASE_NOTARY_FEES = 'costPurchaseNotaryFees',
	COST_PURCHASE_NOTARY_ANNEXES = 'costPurchaseNotaryAnnexes',
	COST_PURCHASE_NOTARY_DISBURSEMENTS = 'costPurchaseNotaryDisbursements',
	COST_PURCHASE_NOTARY_MORTGAGE_TRANSCRIPT = 'costPurchaseNotaryMortgageTranscript',
	COST_ADMINISTRATION = 'costAdministration',
	COST_INSURANCE = 'costInsurance',
	COST_ADDITIONAL_FINANCING_NEEDS = 'costAdditionalFinancingNeed',
	COST_REALTOR = 'costRealtor',
	COST_ESTIMATION = 'costEstimation',
	COST_LAND_REGISTRY = 'costLandRegistry',
	LIQUIDITIES = 'liquidities',
	BUSINESS_CAPITAL = 'businessCapital',
	OWN_FUNDS = 'ownFunds',
	OVERRIDDEN_POSTFIX = 'Overridden',
	LOAN_AMOUNT = 'loanAmount',
	FUNDING_AMOUNTS = 'fundingAmounts',
	REFINANCE_COSTS = 'refinanceCosts',
	EARLY_REPAYMENT_FEE = 'refinanceEarlyRepaymentFee',
	REFINANCE_RELEASE_FEE = 'refinanceReleaseFee',
	ADDITIONAL_REFINANCE_FEE = 'refinanceAdditionalCosts',
}

export enum BorrowerFacingStatus {
	INITIAL_STEP = 'initialStep',
	FIRST_STEP_ONGOING = 'firstStepOngoing',
	FIRST_STEP_ERROR = 'firstStepError',
	FIRST_STEP_COMPLETED = 'firstStepCompleted',
	SECOND_STEP_ONGOING = 'secondStepOngoing',
	SECOND_STEP_ERROR = 'secondStepError',
	SECOND_STEP_COMPLETED = 'secondStepCompleted',
	THIRD_STEP_ONGOING = 'thirdStepOngoing',
	THIRD_STEP_ERROR = 'thirdStepError',
	THIRD_STEP_COMPLETED_WITH_CTA = 'thirdStepCompletedWithCta',
	THIRD_STEP_COMPLETED_WITHOUT_CTA = 'thirdStepCompletedWithoutCta',
}

export interface LoanRequestStatus extends Resource {
	borrowerFacingStatus?: BorrowerFacingStatus;
}

export interface OtherCreditProviderRequest {
	creditProvider: CreditProvider;
	amount: number;
	applicationDate: Date;
	offerReceived: boolean;
}

export interface LoanRequestDecision {
	decisionType: Resource;
	outcome: Resource;
	content?: string;
	user?: User;
	created?: string;
}

export interface LoanRequestDecisionStatistics {
	recommendation?: LoadRequestDecisionDetail;
	initial?: LoadRequestDecisionDetail;
}

export interface LoadRequestDecisionDetail {
	positive: number;
	total: number;
}

export interface LoanRequestComment extends Comment {
	loanRequest?: LoanRequest;
}

export interface ConsultationBorrower {
	id: number;
	nationalNumber: string;
	lastName: string;
	firstName: string;
	birthdate: string;
	city: string;
	street: string;
	streetNumber: string;
	country: string;
	postalCode: string;
}

export interface ConsultationLiabilityType {
	id: number;
	definition: string;
	order: number | null;
}

export interface ConsultationRegularity {
	id: number;
	definition: string;
	order: number | null;
}

export interface ConsultationInstrument {
	id: number;
	liabilityType: ConsultationLiabilityType;
	regularity: ConsultationRegularity;
	contractIdentifier: string;
	instrumentIdentifier: string;
	instrumentCategory: string;
	cicrInstrumentType: string;
	cicrInstrumentDefaultStatus: string;
	ccrPurpose: string;
	conclusionDate: string;
	commitmentAmount: string | number;
	totalAmountRepayable: string;
	firstTermDate: string;
	paymentFrequency: string;
	anticipatedRedemptionDate: string;
	debtFinancing: string;
	guarantee: string;
	termAmount: string;
	legalFinalMaturityDate: string;
	numberOfTerms: string;
	balance: number;
	rate: number;
	consultation: number;
}

export interface ConsultationClient {
	firstName: string;
	lastName: string;
	middleName: string;
	nationalNumber: string;
	id: number;
}

export interface LoanRequestHistory extends History {
	loanRequest?: LoanRequest;
}

export interface LoanRequestStatusHistory extends LoanRequestHistory {
	oldStatus: Resource;
	newStatus: Resource;
}

export type LoanRequestCommunicationHistory = CommunicationHistory;

export interface LoanRequest {
	readonly id: number;
	status: LoanRequestStatus;
	readonly borrowerReference?: string;
	readonly possibleStatuses: Record<'backwards' | 'forwards', Resource[]>;
	readonly lastModified: string;
	readonly created: string;
	readonly prospectus?: Document;
	readonly needsSeniorDecision: boolean;
	externalReference?: string;
	needsSeniorDecisionOverwrite?: boolean;
	actionRequired: boolean;
	filedOtherProvider: boolean;
	internalReference: string;
	liquidities: number;
	ownFunds: number;
	loanAmountOverridden?: boolean;
	businessCapital: number;
	purposes: Array<Resource>;
	anonymized?: boolean;
	createdBy?: User;

	readonly costPurchase: number;
	readonly costRenovation: number;
	readonly costBuilding: number;
	readonly costLandPurchase: number;
	readonly costBuyout: number;
	readonly costCreditTakeover: number;
	readonly costEstimation: number;
	readonly costFile: number;
	readonly costHandling: number;
	readonly costInsurance: number;
	readonly costAdditionalFinancingNeed: number;
	readonly loanAmount: number;
	costDistributionNotary: number;
	costDistributionRegistration: number;
	costCreditNotary: number;
	costCreditRegistration: number;
	costPurchaseNotary: number;
	costPurchaseRegistration: number;
	costAdministration: number;
	costRealtor: number;
	costLandRegistry: number;

	deedDate?: string;
	dateFundsRequested?: string;
	dateFundsProvisioned?: string;
	dateOfferMade?: string;
	dateOfferAccepted?: string;
	dateInitialAgreement?: string;
	dateSubmission?: string;
	dateLoanRequestForm?: string;
	dateContractAllowed?: string;
	dateContractRequested?: string;
	dateContractSent?: string;
	dateCompletion?: string;
	dateDecision?: string;
	dateDipAccepted?: string;
	dateDipRejected?: string;
	dateCreditClosed?: string;
	dateEsisAccepted?: string;
	dateExpectedDisbursement?: string;
	dateAnalysisStarted?: string;

	broker: ThirdParty;
	analyst?: User;
	owner?: User;
	coBroker?: ThirdParty;
	notary?: ThirdParty;
	otherCreditProviderRequests: Array<OtherCreditProviderRequest>;
	acquisitionSource: Resource;
	source?: Resource;
	documentManagementOnly?: boolean;
	financialPlanLoanAmountItems: LoanAmountField[];
	financialPlanOwnFundItems: OwnFundField[];
	hasPoliticallyExposedClient?: boolean;
	hasSimulations?: boolean;
	expirationDate?: Date;
}

export interface LoanRequestClient {
	id: number;
	profile: {
		id: number;
		firstName: string;
		lastName: string;
		nationalNumber: string;
		idCardNumber: string;
		createdBy: {
			id: number;
			email: string;
		};
	};
	loanRequest: {
		id: number;
		internalReference: string;
	};
	client: {
		id: number;
	};
	role: Resource;
}

export interface ProductExplorerSolution {
	selectedProducts: Product[];
}

export interface LoanRequestLoadParameters {
	search?: string;
	filters?: { [key: string]: string };
	page?: string;
	page_size?: number;
}

export type StatusList = Array<Resource>;
export type ChangeStatusItem = Resource & { selected?: boolean; disabled?: boolean };

export interface ChangeStatus {
	item: ChangeStatusItem;
}

export type ChangeStatusItemList = Array<ChangeStatusItem>;

export type CommonHistory = Partial<LoanRequestHistory & LoanRequestStatusHistory & LoanRequestCommunicationHistory>;

export enum ProductExplorerStep {
	PRODUCT_SELECTON = 'product-selection',
	OFFER_ALLOCATION = 'offer-allocation',
}

export enum LoanRequestDecisionType {
	ADVICE = 'advice',
	RECOMMENDATION = 'recommendation',
	INITIAL_DECISION = 'initiaDecision',
	CREDIT_COMMITTEE_DECISION = 'creditCommitteeDecision',
	SENIOR_DECISION = 'seniorDecision',
}
