import { PhoneNumber, IAM } from '../../index';

export interface JoinDetails extends Partial<IAM.User> {
	email: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	language?: string;
	phoneNumber?: PhoneNumber;
	termsAndConditionsAccepted?: boolean;
	privacyPolicyAccepted?: boolean;
	gdprPolicyAccepted?: boolean;
	confirmResidency?: boolean;
	token?: string;
}

export enum JoinStates {
	start = 'start',
	activation = 'activation',
	credentials = 'credentials',
	details = 'details',
	verification = 'verification',
}

export function toJoinDetails(user: IAM.User): JoinDetails {
	return Object.keys(user).reduce((acc, key) => {
		if (key === 'phoneNumbers' && user?.[key]?.[0]) {
			acc['phoneNumber'] = user?.[key]?.[0];
			return acc;
		}

		if (user[key]) acc[key] = user[key];
		return acc;
	}, {} as JoinDetails);
}
