/// <reference types="@types/google.maps" />

import { Inject, Injectable } from '@angular/core';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { Address, AnyObject, MapConfig, Realty, Resource } from '@oper-client/shared/data-model';
import { map, Observable, of, Subject, switchMap } from 'rxjs';
import { GoogleLibrariesService } from './google-libraries.service';
import { roundNumber } from '@oper-client/shared/util-formatting';
import { ResourcesFacade } from '@oper-client/shared/resources/data-access-resource';

interface GoogleGeocodeAddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

const autoCompleteAddressRealtyFields: (keyof Pick<Address, 'street' | 'houseNumber' | 'zipCode' | 'city' | 'country' | 'mapConfig'>)[] = [
	'street',
	'houseNumber',
	'zipCode',
	'city',
	'country',
	'mapConfig',
];

const addressStringFields: (keyof Pick<Address, 'street' | 'houseNumber' | 'zipCode' | 'city' | 'country'>)[] = [
	'houseNumber',
	'street',
	'city',
	'zipCode',
	'country',
];

@Injectable({
	providedIn: 'root',
})
export class GoogleGeocodeService {
	private placesService: google.maps.places.PlacesService;

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerConfig: CustomerInsights,
		private googleLibrariesService: GoogleLibrariesService,
		private readonly resourceFacade: ResourcesFacade
	) {
		this.placesService = this.googleLibrariesService.placesService;
	}

	public googleAddressComponentsToRealty(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>
	): Partial<Realty> {
		return addressComponents.reduce(
			(acc, item) => {
				if (item.types.includes('street_number')) {
					return { ...acc, address: { ...acc.address, houseNumber: item.long_name } };
				} else if (item.types.includes('route')) {
					return { ...acc, address: { ...acc.address, street: item.long_name } };
				} else if (item.types.includes('postal_code')) {
					return { ...acc, address: { ...acc.address, zipCode: item.long_name } };
				} else if (item.types.includes('locality')) {
					return {
						...acc,
						address: { ...acc.address, city: item.long_name },
					};
				} else if (item.types.includes('country')) {
					const countryResource = countryResources?.find((country) => country.definition === item.short_name);
					return {
						...acc,
						address: {
							...acc.address,
							country: { id: countryResource?.id },
						},
					};
				}
				return acc;
			},
			{ ...realtyData }
		);
	}

	public googleAddressComponentsToAddressForm(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>,
		prefix = '',
		mapConfig?: MapConfig
	): any {
		const addressFormValue = {};
		const realty: Partial<Realty> = this.googleAddressComponentsToRealty(addressComponents, countryResources, realtyData);
		if (realty?.address) {
			autoCompleteAddressRealtyFields.forEach((field) => {
				if (typeof realty?.address[field] !== 'undefined') {
					if (field === 'country') {
						addressFormValue[`${prefix}${field}.id`] = realty.address.country?.id || '';
					} else {
						addressFormValue[`${prefix}${field}`] = realty.address[field];
					}
				}

				if (field === 'mapConfig' && mapConfig) {
					addressFormValue[`${prefix}${field}`] = mapConfig;
				}
			});
		}
		return addressFormValue;
	}

	addressFormToAddressString(
		addressFormValue: {
			[key: string]: any;
		},
		countryResources: Resource[]
	): string {
		let str = '';

		addressStringFields.forEach((field) => {
			let value: string | number;
			if (field === 'country') {
				value = addressFormValue[`address.country.id`];
			} else {
				value = addressFormValue[`address.${field}`];
			}
			if (value) {
				if (field === 'country') {
					str += ` ${countryResources?.length ? countryResources.find((country) => country.id === value).definition : ''}`;
				} else {
					str += ` ${value},`;
				}
			}
		});

		return str;
	}

	public getAddressDetails(originalValue: any, placeId: string, fields?: string[], countryResources?: Resource[]): Observable<any> {
		return this.googleLibrariesService.scriptLoaded$.pipe(
			switchMap((loaded) => {
				if (loaded) {
					return new Observable<any>((observer) => {
						this.placesService.getDetails({ placeId, fields }, (result, status) => {
							if (status === google.maps.places.PlacesServiceStatus.OK && result) {
								const transformedValue = this.googleAddressComponentsToRealty(result.address_components, countryResources);
								observer.next(transformedValue);
							} else {
								// TODO: Manage error with callback or log
								observer.next(null);
							}
							observer.complete();
						});
					});
				} else {
					return of(null);
				}
			}),
			map((transformedValue) => ({ ...originalValue, ...transformedValue }))
		);
	}

	/**
	 *
	 * @param searchTerm -- query
	 * @param countryRestriction -- optional parameter to restrict the country in which search for an address, each client has an autocompleteRestriction:   ```"customerInsights": {"addressAutocompleteRestrictions": {"client": ["ch"],"realty": ["ch"]}```,
	 * that you can access through the injection token ```inject(CUSTOMER_INSIGHTS_CONFIG)```;
	 * @param types -- optional parameter to the tell the endpoint on what th query should match ('country', 'address'...)
	 * @returns Observable<google.maps.places.AutocompletePrediction[]> -- an array that contains results that match the query
	 */
	searchAddresses(
		searchTerm: string,
		countryRestriction?: string[] | string,
		types?: string[] | null
	): Observable<google.maps.places.AutocompletePrediction[]> {
		const request: google.maps.places.AutocompletionRequest = {
			input: searchTerm,
			types: types || ['address'],
			componentRestrictions: countryRestriction ? { country: countryRestriction } : undefined,
		};
		return this.googleLibrariesService.scriptLoaded$.pipe(
			switchMap((loaded) => {
				if (loaded) {
					return new Observable<google.maps.places.AutocompletePrediction[]>((observer) => {
						this.googleLibrariesService.autocompleteService.getPlacePredictions(request, (results, status) => {
							if (status === google.maps.places.PlacesServiceStatus.OK) {
								observer.next(results || []);
							} else {
								observer.error(status);
							}
							observer.complete();
						});
					});
				} else {
					return of([]);
				}
			})
		);
	}

	getCoordinates(address: string): Observable<{ lat: number; lng: number }> {
		const subject = new Subject<{ lat: number; lng: number }>();

		this.placesService.findPlaceFromQuery(
			{
				query: address,
				fields: ['geometry'],
			},
			(results, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && results?.[0]?.geometry) {
					const location = results[0].geometry.location;
					subject.next({
						lat: roundNumber(location.lat(), 10),
						lng: roundNumber(location.lng(), 10),
					});
				} else {
					subject.next(null);
				}
				subject.complete();
			}
		);

		return subject.asObservable();
	}

	equalFormAddressData(address1: AnyObject<any>, address2: AnyObject<any>): boolean {
		return addressStringFields.every((field) => {
			return (
				address1?.[`address.${field}${field === 'country' ? '.id' : ''}`] ===
				address2?.[`address.${field}${field === 'country' ? '.id' : ''}`]
			);
		});
	}

	equalMapConfigs(config1: MapConfig, config2: MapConfig): boolean {
		if (config1?.pov) {
			return JSON.stringify(config1) === JSON.stringify(config2);
		}

		return JSON.stringify(config1?.coordinates) === JSON.stringify(config2?.coordinates);
	}
}
