export enum LoanTypeEnum {
	PROGRESSIVE_AMORTIZATION = 'progressiveAmortization',
	INFINITE_CREDIT = 'infiniteCredit',
	SUBSIDY = 'subsidy',
	BALLOON_CREDIT = 'balloonCredit',
	BALLOON_CREDIT_NO_PAYMENT = 'balloonCreditNoPayment',
	CRESCENDO = 'crescendo',
	ANNUITY = 'mensuality',
	BRIDGE_LOAN = 'bridgeLoan',
	MORTGAGE_LOAN = 'mortgageLoan',
}

export const BULLET_LOAN_TYPES: LoanTypeEnum[] = [LoanTypeEnum.BALLOON_CREDIT, LoanTypeEnum.BALLOON_CREDIT_NO_PAYMENT];
