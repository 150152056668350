import { Component, Input } from '@angular/core';
import { faSpinnerThird, IconDefinition } from '@oper-client/shared/util-fontawesome';

@Component({
	selector: 'oper-client-loading-indicator',
	template: `
		<div class="loader">
			@if (translateKey) {
				<div class="loader-content">
					<span>{{ translateKey | translate }}</span>
				</div>
			}
			@if (!hideLoadingIcon) {
				<div class="loader-content">
					<fa-icon [size]="loaderSize" [icon]="iconLoading" [spin]="true"></fa-icon>
				</div>
			}
		</div>
	`,
	styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
	/**
	 * @description
	 * Defines whether gray background should be displayed. Default to false (background hidden).
	 */
	@Input() hasBackground = false;
	/**
	 * @description
	 * The size of the loading indicator. Defaults to 'lg'.
	 */
	@Input() loaderSize: 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' = 'lg';
	/**
	 * @description
	 * Delay in milliseconds before the loading indicator display. Default to 0.
	 */
	@Input() showDelay = 0;
	/**
	 * @description
	 * The traslatablekey containing the text to display under the loading indicator.
	 */
	@Input() translateKey: string;
	/**
	 * @description
	 * Defines whether to show the loading animation or not
	 */
	@Input() hideLoadingIcon: boolean;

	public readonly iconLoading: IconDefinition = faSpinnerThird;
}
