import {
	Component,
	Input,
	HostListener,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	TemplateRef,
	OnDestroy,
	AfterViewInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

import { ComponentType } from '@angular/cdk/portal';
import { OverlayService, OperOverlayRef } from '@oper-client/shared/overlay';
import { faAngleLeft, IconDefinition } from '@oper-client/shared/util-fontawesome';

@Component({
	selector: 'oper-client-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy, AfterViewInit {
	/** The inner element */
	@ViewChild('inner') innerElement: ElementRef;
	@ViewChild('tpl', { read: TemplateRef }) tpl: TemplateRef<any>;

	/** Whether the modal is shown */
	@Input()
	set shown(shown: boolean) {
		this._shown = shown;
		this.handleOpenClose();
	}

	get shown(): boolean {
		return this._shown;
	}

	@Input() size: 'small' | 'medium' | 'large' | 'xlarge' | 'max' = 'small';
	@Input() disableTransitionEffect = false;
	@Input() wrapperClasses: string[] = [];
	@Input() overlayClasses: string[] = [];
	@Input() title: string;
	@Input() titleDescription: string;

	@Input() actionPrimary: string;
	@Input() primaryActionIcon = null;
	@Input() enablePrimary = true;
	@Input() classPrimaryPostfix: string;
	@Input() actionSecondary: string;
	@Input() enableSecondary = true;
	@Input() classSecondaryPostfix: string;
	@Input() actionThirdary: string;
	@Input() enableThirdary = true;
	@Input() classThirdaryPostfix: string;
	@Input() iconThirdary: IconDefinition;
	@Input() closeButton = true;
	@Input() headerBackButton: string;
	@Input() headerBorder = true;
	@Input() footerBorder = true;
	@Input() actionsAutoHeight = false;
	@Input() hideFooter = false;
	@Input() closeOnClickOutside = false;
	@Input() noContentPadding = false;
	@Input() centerCta = false;
	@Input() enableDynamicForm = false;
	@Input() actionPosition: 'body' | 'footer' = 'footer';
	@Input() showAfterRedirect = false;
	@Input() fullscreen = false;
	@Input() hideHeader = false;
	@Input() minModalHeight = '304px';

	@Output() clickedPrimary: EventEmitter<void> = new EventEmitter();
	@Output() clickedSecondary: EventEmitter<void> = new EventEmitter();
	@Output() clickedThirdary: EventEmitter<void> = new EventEmitter();
	@Output() closed: EventEmitter<any> = new EventEmitter();
	@Output() back: EventEmitter<void> = new EventEmitter();

	readonly iconAngleLeft: IconDefinition = faAngleLeft;

	protected _shown: boolean;
	protected overlayReference: OperOverlayRef<ComponentType<ModalComponent>>;
	protected readonly destroy$ = new Subject<void>();
	protected clickOutsideListener: (event: MouseEvent) => void;

	constructor(
		private readonly _overlayService: OverlayService,
		private readonly _router: Router
	) {}

	/** When the esc key is pressed, emit a close event */
	@HostListener('document:keydown.escape', ['$event']) onEsc() {
		if (this.shown) {
			this.closed.emit();
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		if (this.overlayReference) {
			this.onClose();
		}
	}

	ngAfterViewInit() {
		this.handleOpenClose();
	}

	handleOpenClose() {
		if (this.shown && this.tpl && !this.overlayReference) {
			this.onOpen(this.tpl);
		} else if (!this.shown && this.overlayReference) {
			this.onClose();
		}
	}

	getButtonClass(baseClass: string, postfix): string {
		return typeof postfix === 'undefined' ? baseClass : `${baseClass}--${postfix}`;
	}

	protected onOpen(content: TemplateRef<ModalComponent>) {
		this.overlayReference = this._overlayService.openModal(content, null, this.wrapperClasses, this.overlayClasses);
		if (this.closeOnClickOutside) {
			this.clickOutsideListener = (event: MouseEvent) => {
				const overlayElement = this.overlayReference?.overlay?.overlayElement;
				if (
					overlayElement === (<HTMLElement>event?.target)?.parentElement ||
					overlayElement === (<HTMLElement>event?.target)?.parentElement?.parentElement
				) {
					this.closed.emit();
				}
			};
			this.overlayReference.overlay.overlayElement.addEventListener('click', this.clickOutsideListener);
		}
		this._router.events.pipe(takeWhile(() => !!this.overlayReference && !this.showAfterRedirect)).subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.onClose();
			}
		});
	}

	protected onClose() {
		this.removeListeners();
		this._overlayService.close(this.overlayReference);
		this.overlayReference = null;
	}

	protected removeListeners() {
		if (this.clickOutsideListener) {
			this.overlayReference?.overlay?.overlayElement?.removeEventListener('click', this.clickOutsideListener);
			this.clickOutsideListener = null;
		}
	}
}
