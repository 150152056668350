// Pre-define debounce values
export const debounceTimes = {
	xxs: 100,
	xs: 200,
	s: 300,
	m: 500,
	l: 1000,
	xl: 2000,
	xxl: 3000,
	xxxl: 5000,
};
