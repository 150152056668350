import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IComplianceCheckService } from '@oper-client/shared/data-access';
import { ComplianceCheck, ComplianceCheckConfig } from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class ComplianceCheckService implements IComplianceCheckService {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getConfig(): Observable<ComplianceCheckConfig> {
		return this.apiService.get(`/configuration/configurationmodule/compliance-check-configuration`);
	}

	public getComplianceCheck(id: number): Observable<ComplianceCheck[]> {
		return this.apiService.get(`/api/loan-requests/${id}/compliance-checks/`);
	}

	public createComplianceCheck(id: number, complianceCheck: ComplianceCheck): Observable<ComplianceCheck[]> {
		return this.apiService.post(`/api/loan-requests/${id}/compliance-checks/`, complianceCheck);
	}

	public updateComplianceCheck(id: number, changes: Partial<ComplianceCheck>): Observable<ComplianceCheck[]> {
		return this.apiService.patch(`/api/loan-requests/${id}/compliance-checks/`, changes);
	}
}
