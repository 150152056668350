import { Email, I18nPhoneNumber, PartialNormalizedResource, PhoneNumber, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { InputField, InputPhone, InputSelect, Section } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	analystOptions: SelectOption[] = [],
	brokerOptions: SelectOption[] = [],
	defaultLanguage?: string,
	isReadonlyMode = false
): InputBase<any>[] {
	const email: Email = (formData?.borrowersInfo?.[0]?.emails?.length && formData?.borrowersInfo?.[0]?.emails[0]) || <Email>{};
	const mainPhoneNumber: PhoneNumber =
		formData?.borrowersInfo?.[0]?.phoneNumbers?.find((item) => item.phoneType?.definition === 'main') ||
		(formData?.borrowersInfo?.[0]?.phoneNumbers?.length === 1 &&
			formData?.borrowersInfo?.[0]?.phoneNumbers[0]?.phoneType?.definition !== 'landline' &&
			formData?.borrowersInfo?.[0]?.phoneNumbers[0]) ||
		<PhoneNumber>{};

	const language =
		formData?.borrowersInfo?.[0]?.language?.id ??
		resources?.[ResourceType.LANGUAGE]?.find((item) => item.definition === defaultLanguage)?.id ??
		'';

	const questions = [
		new Section({
			title: 'ç.feature.mortgageSimulator.assignSimulation',
		}),
		new InputSelect({
			key: 'broker.id',
			label: 'ç.feature.loanRequest.broker',
			bindValue: 'id',
			value: formData?.broker?.id,
			options: brokerOptions,
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new InputSelect({
			key: 'analyst.id',
			label: 'ç.feature.loanRequest.analyst',
			value: formData?.analyst?.id ?? formData?.createdBy?.id,
			options: analystOptions,
			required: true,
			hideRequiredAsterisk: true,
			class: 'span6',
		}),
		new Section({
			title: 'ç.feature.mortgageSimulator.borrowerDetails',
		}),
		new InputField({
			key: 'borrowersInfo[0].isMainBorrower',
			type: 'hidden',
			value: true,
			required: false,
		}),
		new InputField({
			key: 'borrowersInfo[0].firstName',
			label: 'ç.question.firstName.label',
			placeholder: 'ç.feature.mortgageSimulator.borrowerName',
			value: formData?.borrowersInfo?.[0]?.firstName ?? null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			onlyLetters: true,
			class: 'span6',
			disabled: isReadonlyMode,
		}),
		new InputField({
			key: 'borrowersInfo[0].lastName',
			label: 'ç.question.lastName.label',
			placeholder: 'ç.feature.mortgageSimulator.borrowerSurname',
			value: formData?.borrowersInfo?.[0]?.lastName ?? null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			onlyLetters: true,
			class: 'span6',
			disabled: isReadonlyMode,
		}),
		new InputField({
			key: 'borrowersInfo[0].emails[0].value',
			label: 'ç.question.email.label',
			placeholder: 'ç.feature.mortgageSimulator.borrowerEmail',
			value: email?.value ?? '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [ValidatorService.trimmedEmailValidator],
			disabled: isReadonlyMode,
		}),
		new InputPhone({
			key: 'borrowersInfo[0].phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: mainPhoneNumber as I18nPhoneNumber,
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: true,
			hideRequiredAsterisk: true,
			validators: [ValidatorService.phoneNumberRequiredValidator()],
			allowedTypes: ['MOBILE'],
			disabled: isReadonlyMode,
		}),
		new InputSelect({
			key: 'borrowersInfo[0].language.id',
			label: 'ç.question.language.label',
			value: language,
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			hideRequiredAsterisk: true,
			disabled: isReadonlyMode,
		}),
	];

	return questions;
}
