import { AnyObject } from './any-object.model';
import { AIValidationResourceConfig } from '@oper-client/shared/configuration';
import { Resource } from './resource.model';

export interface ValidationResult {
	categoryEntityId: number;
	id: number;
	category: string;
	docName: string;
	proofId: number;
	payload: VerificationResult;
	status: string;
	rejectionRate?: number;
}

export interface AiValidationDataMapping {
	[key: string]: AiValidationDataMappingSection;
}

export interface AiValidationDataMappingSection {
	id: number;
	discrepancies: number;
	docNumber: number;
	section: string;
	sectionName: string;
	sectionNameTranslationParams?: AnyObject<any>;
	subsections: { [key: string]: AiValidationDataMappingSubsection };
}

export interface AiValidationDataMappingSubsection {
	fields: (AiValidationDataMappingField | AiValidationDataMappingGroupField)[];
}

export interface AiValidationDataMappingField {
	currentValue: AiValidationFieldValue;
	discrepancies: number;
	docNumber: number;
	name: string;
	propertyPath: string;
	resource?: string;
	type?: string;
	extractedValues: ExtractedValue[];
	group?: boolean;
	isList?: boolean;
	resourceConfig?: AIValidationResourceConfig;
	digitsInfo?: string;
}

export interface AiValidationDataMappingGroupField {
	currentValue: AnyObject<any>;
	discrepancies: number;
	docNumber: number;
	name: string;
	propertyPath: string;
	extractedValues: ExtractedGroupValue[];
	group?: boolean;
	groupCurrentValues?: GroupValue[];
}

export interface GroupValue {
	name: string;
	value: AiValidationFieldValue;
	valueMatches?: boolean;
	resource?: string;
	type?: string;
	propertyPath: string;
	digitsInfo?: string;
}

export interface ExtractedValue {
	value: AiValidationFieldValue;
	docName: string;
	docId: number;
	proofId: number;
	valueMatches: boolean;
}

export interface ExtractedGroupValue extends ExtractedValue {
	value: GroupValue[];
}

export interface LoadValidationData {
	loanRequestId: number;
	proofId: number;
	docId: number;
	docName: string;
	category: string;
	categoryEntityId: number;
}

export interface VerificationResult {
	isValid: boolean;
	confidenceLevel: string;
	criteriaRejections: CriteriaRejections[];
}

export interface CriteriaRejections {
	isValid: boolean;
	criteriaPoint: number;
	reason: string[];
	reasonCoordinates?: ReasonCoordinates;
}
export interface ReasonCoordinates {
	pageNumber: number;
	x1: number;
	x2: number;
	y1: number;
	y2: number;
}

export type AiValidationFieldValue = string | number | Array<any> | AnyObject<string>;

export type AiValidationUpdateFieldValue = string | number | Array<any> | Resource;

export const MAX_AI_ATTEMPTS = 24;

export const AI_ATTEMPT_DELAY = 5000; //5 sec

export interface AIPreviewData {
	url?: string;
	pageNumber?: number;
	coordinates?: ReasonCoordinates;
	field?: string;
}
