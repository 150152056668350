import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsyncValidatorFn } from '@angular/forms';
import { ValidatorApiService } from './validator-api.service';
import { Resource } from '@oper-client/shared/data-model';
import { conditionValidator } from '../validators/condition-validator';
import { trimmedEmailValidator } from '../validators/email-validator';
import { addressValidator } from '../validators/address-validator';
import { trimmedPatternValidator } from '../validators/trimmed-pattern-validator';
import { trimmedMinLengthValidator } from '../validators/trimmed-min-lenght-validator';
import { dateRestrictionValidatior, endDateRequiredValidator } from '../validators/date-restriction.validator';
import { ibanValidator } from '../validators/iban-validator';
import { bicValidator } from '../validators/bic-validator';
import { nationalNumberAsyncValidator } from '../validators/national-number-validator';
import { lambdaValidator } from '../validators/lambda-validator';
import { phoneNumberRequiredValidator, uniquePhoneNumberValidator } from '../validators/phone-input-validator';
import { EnvironmentCurrencyPipe } from '@oper-client/shared/util-formatting';
import { bankAccountValidator } from '../validators/bank-account-validator';
import { vatNumberValidator } from '../validators/vat-number-validator';
import { nestedFormValidator } from '../validators/nested-form-validator';
@Injectable({
	providedIn: 'root',
})
export class ValidatorService {
	static lambdaValidator = lambdaValidator;

	static getConditionControlValidator = conditionValidator;

	static getNestedFormValidator = nestedFormValidator;

	static getAddressValidator = addressValidator;

	static trimmedEmailValidator = trimmedEmailValidator;

	static getTrimmedPatternValidator = trimmedPatternValidator;

	static getTrimmedMinLengthValidator = trimmedMinLengthValidator;

	static getDateRestrictionValidatior = dateRestrictionValidatior;

	static getEndDateRestriction = endDateRequiredValidator;

	static getIbanValidator = ibanValidator;

	static getBicValidator = bicValidator;

	static phoneNumberRequiredValidator = phoneNumberRequiredValidator;

	static uniquePhoneNumberValidator = uniquePhoneNumberValidator;

	static getBankAccountValidator = bankAccountValidator;

	static getVatNumberValidator = vatNumberValidator;

	static getDateByYearOffset(yearOffset: number): Date {
		const currentYear: number = new Date().getFullYear();
		const currentMonth: number = new Date().getMonth();
		const currentDate: number = new Date().getDate();
		return new Date(currentYear - yearOffset, currentMonth, currentDate);
	}

	constructor(
		private readonly translate: TranslateService,
		private readonly validatorApiService: ValidatorApiService,
		private currencyPipe: EnvironmentCurrencyPipe
	) {}

	getNationalNumberAsyncValidator(countryResources: Resource[]): AsyncValidatorFn {
		return nationalNumberAsyncValidator(countryResources, this.validatorApiService);
	}

	formatValidationError(key, options = null) {
		switch (key) {
			case 'required':
				return `ç.feature.validator.required`;
			case 'email':
				return `ç.feature.validator.email`;
			case 'minlength':
				return this.translate.instant('ç.feature.validator.minLength', { requiredLength: options.requiredLength });
			case 'maxlength':
				return this.translate.instant('ç.feature.validator.maxLength', { requiredLength: options.requiredLength });
			case 'minAge':
				return this.translate.instant('ç.feature.validator.minAge', { requiredAge: options.requiredAge });
			case 'maxAge':
				return this.translate.instant('ç.feature.validator.maxAge', { requiredAge: options.requiredAge });
			case 'future':
				return `ç.feature.validator.future`;
			case 'past':
				return `ç.feature.validator.past`;
			case 'negative':
				return `ç.feature.validator.negative`;
			case 'positive':
				return `ç.feature.validator.positive`;
			case 'min':
				return this.translate.instant('ç.feature.validator.min', { min: options.min });
			case 'max':
				return this.translate.instant('ç.feature.validator.max', { max: options.max });
			case 'similarFields':
				return this.translate.instant('ç.feature.validator.similarFields', { similarFields: options.similarFields });
			case 'maxPercentage':
				return `ç.feature.validator.maxPercentage`;
			case 'minPercentage':
				return `ç.feature.validator.minPercentage`;
			case 'venalValue':
				return `ç.feature.validator.venalValuePurchasePrice`;
			case 'venalBeforeAfter':
				return `ç.feature.validator.venalBeforeAfter`;
			case 'requiredEstimated':
				return `ç.feature.validator.requiredEstimated`;
			case 'maxRenovation':
				return this.translate.instant('ç.feature.validator.maxRenovation', { max: options.max });
			case 'creditCardTakeOver':
				return `ç.feature.validator.creditCardTakeOverRequiresBalance`;
			case `moreThanTwoMonthsAgo`:
				return `ç.feature.validator.moreThanTwoMonthsAgo`;
			case `moreThanThreeYearsAgo`:
				return `ç.feature.validator.moreThanThreeYearsAgo`;
			case `expiredIdCard`:
				return `ç.feature.validator.expiredIdCard`;
			case `pattern`:
				if (options.requiredPattern === '^([^0-9]*)$') {
					return `ç.feature.validator.noNumbers`;
				}
				if (options.requiredPattern === '^[0-9]*$') {
					return `ç.feature.validator.onlyNumbers`;
				}
				if (options.requiredPattern === '^true$') {
					return `ç.feature.validator.required`;
				}

				return `ç.feature.validator.pattern`;
			case 'registrationRightsDetailsRequired':
				return `ç.feature.validator.registrationRightsDetailsRequired`;
			case 'irregularityStartDateMustBeEmpty':
				return `ç.feature.validator.irregularityStartDateMustBeEmpty`;
			case 'irregularityStartDateMustBeFilled':
				return `ç.feature.validator.irregularityStartDateMustBeFilled`;
			case 'nationalNumberInvalid':
				return `ç.feature.validator.nationalNumber`;
			case 'modulo97':
				return `ç.feature.validator.modulo97`;
			case 'atLeastOneRequestRequired':
				return `ç.feature.validator.atLeastOneRequestRequired`;
			case `monthsBeforeOtherDate`:
				return this.translate.instant('ç.feature.validator.monthsBeforeOtherDate', { otherDate: options.otherDate });
			case `liabilityInsuranceToBeFinancedMustBeOneShot`:
				return `ç.feature.validator.liabilityInsuranceToBeFinancedMustBeOneShot`;
			case `otherEstimatorNameMustBeFilled`:
				return `ç.feature.validator.otherEstimatorNameMustBeFilled`;
			case `otherEstimatorNameMustBeEmpty`:
				return `ç.feature.validator.otherEstimatorNameMustBeEmpty`;
			case `employmentStartDateCannotBeEarlierThanDateOfBirth+14years`:
				return `ç.feature.validator.employmentStartDateCannotBeEarlierThanDateOfBirth+14years`;
			case `requiredFieldAddressCannotBeProvidedPartially`:
				return `ç.feature.validator.requiredFieldAddressCannotBeProvidedPartially`;
			case 'iban':
				return `ç.feature.validator.iban`;
			case 'bic':
				return `ç.feature.validator.bic`;
			case 'ageRestrictionYounger':
				return `ç.feature.validator.ageRestrictionYounger`;
			case 'ageRestrictionOlder':
				return `ç.feature.validator.ageRestrictionOlder`;
			case 'notInPast':
				return `ç.feature.validator.notInPast`;
			case 'onlyNumbersAndCapitalLetters':
				return `ç.feature.validator.onlyNumbersAndCapitalLetters`;
			case 'onlyNumbersAndLetters':
				return `ç.feature.validator.onlyNumbersAndLetters`;
			case 'onlyNumbers':
				return `ç.feature.validator.onlyNumbers`;
			case 'onlyLetters':
				return `ç.feature.validator.onlyLetters`;
			case `notEarlierThanYear`:
				return this.translate.instant('ç.feature.validator.notEarlierThanYear', { year: new Date(options).getFullYear() });
			case 'passwordsDontMatch':
				return 'ç.feature.validator.passwordsDontMatch';
			case 'versionExists':
				return 'ç.feature.validator.versionExists';
			case 'validFromExists':
				return 'ç.feature.validator.validFromExists';
			case 'pastXYears':
				return this.translate.instant('ç.feature.validator.pastXYears', options);
			case 'futureXYears':
				return this.translate.instant('ç.feature.validator.futureXYears', options);
			case 'invalidPhoneNumber':
				return 'ç.feature.validator.invalidPhoneNumber';
			case 'invalidPhoneType':
				return 'ç.feature.validator.invalidPhoneType';
			case 'notUniquePhoneNumber':
				return 'ç.feature.validator.notUniquePhoneNumber';
			case 'csokNotAllowedRealtyPurpose':
				return 'ç.feature.validator.csokNotAllowedRealtyPurpose';
			case 'csokNotAllowedRealtyType':
				return 'ç.feature.validator.csokNotAllowedRealtyType';
			case 'maxBabyLoanHome':
				return this.translate.instant('ç.feature.validator.maxBabyLoanHome', {
					maxBabyLoanHomeAmount: this.currencyPipe.transform(options.maxBabyLoanHomeAmount),
				});
			case 'maxBabyLoan':
				return this.translate.instant('ç.feature.validator.maxBabyLoan', {
					maxBabyLoanAmount: this.currencyPipe.transform(options.maxBabyLoanAmount),
				});
			case 'taxId':
				return `ç.feature.validator.taxId`;
			case 'landRegister':
				return `ç.feature.validator.landRegister`;
			case 'bankAccount':
				return `ç.feature.validator.bankAccount`;
			case 'vatNumber':
				return `ç.feature.validator.vatNumber`;
			case 'logoUrl':
				return `ç.feature.validator.invalidLogoUrl`;
			default:
				return `ç.feature.validator.default`;
		}
	}

	formatValidationErrors(errors, question = null) {
		return Object.keys(errors).map((key) => {
			if (question?.errorLabel) {
				return this.translate.instant(question.errorLabel);
			} else {
				const formattedError = this.translate.instant(this.formatValidationError(key, errors[key]));
				return key === 'required' && !!question.label
					? `${this.translate.instant(question.label)}: ${formattedError}`
					: formattedError;
			}
		});
	}
}
