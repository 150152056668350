<div class="header">
	@for (label of label(); track $index) {
		<div class="header__label">
			{{ label | translate }}
		</div>
	}
</div>

<div class="row">
	<oper-client-dynamic-form
		class="row__form"
		[formConfiguration]="row()"
		[debounceTime]="debounceTime()"
		(valueChange)="formChanged$.next($event)"
	/>

	<oper-client-fontawesome-icon class="row__icon" [icon]="'faMinus'" [size]="'lg'" (click)="removeCardAction()" />
</div>
