import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, camelCaseToDashed, IApiService, ICalculateService } from '@oper-client/shared/data-access';
import { CalculateAmortization, CalculateMonthlyAmortization, Calculation } from '@oper-client/shared/data-model';

export class CalculateService implements ICalculateService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}
	calculateAmortization(calculateAmortization: CalculateAmortization): Observable<CalculateAmortization> {
		return this.apiService.post(`/api/calculate/credit/`, calculateAmortization);
	}
	calculateMonthlyAmortization(calculateAmortization: CalculateAmortization): Observable<CalculateMonthlyAmortization> {
		return this.apiService.post(`/api/calculate/credit-theoretical-amortization/`, calculateAmortization);
	}
	calculate(calculator: string, inputParameters: any): Observable<Calculation> {
		return this.apiService.post(`/api/calculate/${camelCaseToDashed(calculator)}/`, inputParameters);
	}
}
