import { DynamicFormCard, InformationBox, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import {
	BorrowerSimulationDto,
	CreditProvider,
	FinancialPlanConfig,
	FinancialPlanItem,
	LoanAmountFieldConfig,
	PartialNormalizedResource,
} from '@oper-client/shared/data-model';
import { of, Subject } from 'rxjs';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { hideWhenFieldIsFalse } from '../utils/dynamic-form.utils';
import { FormConfiguration } from '../models/dynamic-form.model';

export default function (
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource,
	financialPlanConfig?: FinancialPlanConfig,
	liabilityProviders?: CreditProvider[]
): InputBase<any>[] {
	const collateralForm = new FormConfiguration();
	collateralForm.setName('extraCollateralForm');
	collateralForm.formControl.questions = [
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'hasExistingLoan',
			label: 'ç.question.hasExistingLoan.label',
			value: !!formData?.extraCollateral?.originalLoanAmount,
			updateOn: 'change',
			type: 'checkbox',
		}),
		new InputField({
			key: 'originalLoanAmount',
			label: 'ç.question.originalLoanAmount.label',
			value: formData?.extraCollateral?.originalLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
		new InputSelect({
			key: 'creditProvider.id',
			label: 'ç.question.creditProvider.label',
			value: formData?.extraCollateral?.creditProvider?.id,
			required: false,
			appendTo: null,
			bindLabel: 'name',
			options: liabilityProviders,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
		new InformationBox({
			content: 'ç.feature.mortgageSimulator.yourContribution.collateral.informationBox',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
	];

	const questions = [
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.misc.additionalCollateral',
			key: 'extraCollateral',
			value: formData?.extraCollateral,
			formConfiguration: collateralForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.misc.action.addCollateral',
			secondaryAction: true,
			forceExpand: formData?.extraCollateral?.collateralAmount > 0,
			required: false,
			requiredOnOpen: true,
			clearOnClose: true,
		}),
	];

	const validators: ValidatorFn[] = [Validators.required, Validators.min(0)];

	const secondaryLoanAmountFields = financialPlanConfig?.financialPlanLoanAmountFields?.filter((field) => !field.isMain);
	secondaryLoanAmountFields?.forEach((field) => {
		questions.push(getLoanAmountItemDynamicCard(field, formData, validators));
	});

	return questions;
}

function getLoanAmountItemDynamicCard(
	field: LoanAmountFieldConfig,
	formData: Partial<BorrowerSimulationDto>,
	validators: ValidatorFn[]
): DynamicFormCard {
	const loanAmountType = field?.loanAmountType.definition;
	const capitalizedLoanAmountType = loanAmountType.charAt(0).toUpperCase() + loanAmountType.slice(1);

	const formConfiguration = getFormConfiguration(loanAmountType, formData[loanAmountType], validators);

	return new DynamicFormCard({
		title: `ç.resource.loan-type.${loanAmountType}`,
		key: loanAmountType,
		value: formData[loanAmountType] ?? null,
		formConfiguration: formConfiguration,
		showDeleteButton: true,
		secondaryActionLabel: `ç.misc.action.add${capitalizedLoanAmountType}`,
		secondaryAction: true,
		forceExpand: formData[loanAmountType]?.amount > 0,
		required: false,
		requiredOnOpen: true,
		clearOnClose: true,
	});
}

function getFormConfiguration(loanAmountType: string, item: FinancialPlanItem, validators: ValidatorFn[]): FormConfiguration {
	const formConfiguration = new FormConfiguration();
	formConfiguration.setName(loanAmountType);
	formConfiguration.formControl.questions = [
		new InputField({
			key: 'amount',
			label: `ç.question.${loanAmountType}Amount.label`,
			value: item?.amount ? +item.amount.toFixed(2) : null,
			helpText: of(`ç.question.${loanAmountType}.helpText`),
			type: 'text',
			currency: true,
			validators: [...(validators ?? [])],
			hideRequiredAsterisk: true,
			required: true,
		}),
	];

	return formConfiguration;
}
