import { TranslateService } from '@ngx-translate/core';

import { ThirdParty, Broker, CreditProvider, Resource, User, ResourceType } from '@oper-client/shared/data-model';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';

export class SelectOption {
	id: number | string;
	order?: number | null;
	key: string;
	translatedKey?: string;
	color?: string;
	icon?: string;
	extraData?: string | number;
}

export const UNASSIGNED_SELECT_OPTION = { key: 'ç.misc.unassigned', order: 0, id: -1 } as SelectOption;

export class SelectOptionWithConfirmationDialog extends SelectOption {
	skipConfirmation?: boolean;
}

export function convertUserInstancesToOptions(users: User[], localeFormatService?: EnvironmentLocaleFormatService): SelectOption[] {
	return users
		?.map((user) => ({
			id: user.id,
			key: localeFormatService?.formatFullName(user) || `${user.firstName} ${user.lastName}`,
		}))
		.sort((a, b) => (a.key > b.key ? 1 : -1));
}

export function convertBrokersInstancesToOptions(brokers: Broker[] = []): SelectOption[] {
	return brokers.map(({ id, name }) => ({ id, key: name })).sort((a, b) => a.key.localeCompare(b.key));
}

export function convertThirdPartyInstancesToOptions(thirdParties: Array<ThirdParty>): SelectOption[] {
	return thirdParties
		?.map((thirdParty) => ({
			id: thirdParty.id,
			key: thirdParty.name + ((thirdParty?.addresses?.length > 0 && ' ' + thirdParty.addresses[0].zipCode) || ''),
		}))
		.sort((a, b) => (a.key > b.key ? 1 : -1));
}

export function convertCreditProviderToOptions(creditProviders: CreditProvider[]): SelectOption[] {
	return creditProviders?.map((creditProvider) => ({
		id: creditProvider.id,
		key: creditProvider.name,
	}));
}

export function convertResourceToSelectOptions(items: Resource[], resourceType: ResourceType = ResourceType.VARIABILITY): SelectOption[] {
	return items?.map(({ id, definition }) => {
		return { id, key: `ç.resource.${resourceType}.${definition}` };
	});
}

export function generateDurationOptions(minDuration: number, maxDuration: number, translateService: TranslateService): SelectOption[] {
	return Array(35)
		.fill(1)
		.map((_, i) => i + 1)
		.filter((n) => n >= minDuration && n <= maxDuration)
		.map((n) => ({
			id: n,
			key: `${n} ${
				n === 1
					? translateService.instant('ç.misc.duration.year.singular')
					: translateService.instant('ç.misc.duration.year.plural')
			}`,
		}));
}

const botNames = ['oper bot', 'bot oper', 'operise bot', 'bot operise', '0per bot', 'bot 0per', '0perise bot', 'bot 0perise'];

export function isUserBot(fullName: string): boolean {
	return botNames.includes(fullName.toLowerCase());
}

export function capitalizeWords(value: string): string {
	return value?.replace(/(^[a-zA-Z0-9À-ž]{1})|(\s{1}[a-zA-Z0-9À-ž]{1})|([-'][a-zA-Z0-9À-ž]{1})/g, (match) => match.toUpperCase()) ?? '';
}
