import { Component, input } from '@angular/core';

@Component({
	selector: 'oper-client-dynamic-form-named-section',
	templateUrl: './dynamic-form-named-section.component.html',
	styleUrls: ['./dynamic-form-named-section.component.scss'],
})
export class DynamicFormNamedSectionComponent {
	readonly class = input<string>('');
	readonly user = input<Partial<{ firstName: string; lastName: string }>>({ firstName: '', lastName: '' });
	readonly iconName = input<string | undefined>(undefined);
	readonly iconBackground = input<boolean>(false);
}
