import { ValidatorFn } from '@angular/forms';
import { FormGroupWithWarning } from '../models/form-warning.model';

interface nestedForm {
	formGroup: FormGroupWithWarning;
}

export function nestedFormValidator(config: nestedForm): ValidatorFn {
	return (c): { [key: string]: any } | null => {
		const isNestedFormValid = config.formGroup?.status === 'VALID';

		if (!isNestedFormValid) {
			return { required: true };
		}
		return null;
	};
}
