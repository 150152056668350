import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IAiValidationServiceService, IApiService } from '@oper-client/shared/data-access';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadValidationData, Resource, ValidationResult } from '@oper-client/shared/data-model';
import { AiValidationConfig, AiConfiguration } from '@oper-client/shared/configuration';
import { AI_VALIDATION_CONFIG } from '@oper-client/shared/ai-validation/data-access';

@Injectable()
export class AiValidationService implements IAiValidationServiceService {
	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(AI_VALIDATION_CONFIG)
		private readonly config: AiValidationConfig
	) {}

	getConfig(): Observable<AiValidationConfig> {
		return of(this.config);
	}

	getSupportedDocumentTypes(): Observable<AiConfiguration> {
		return this.apiService.get('/configuration/proofmodule/ai-configuration/');
	}

	triggerValidation(
		loanRequestId: number,
		proofId: number,
		docId: number,
		verificationTypes?: Resource
	): Observable<{
		id: string;
		status: string;
	}> {
		const payload: { ai_verification_type?: Resource } = {};

		if (verificationTypes !== undefined) {
			payload.ai_verification_type = verificationTypes;
		}

		return this.apiService
			.post(`/api/loan-requests/${loanRequestId}/proof/${proofId}/documents/${docId}/ai_verifications/`, payload)
			.pipe(map((res) => ({ id: res.celeryTaskId, status: res.status })));
	}

	getValidationResult(validationConfig: LoadValidationData, aiVerificationType: string): Observable<ValidationResult> {
		return this.apiService
			.get(
				`/api/loan-requests/${validationConfig.loanRequestId}/proof/${validationConfig.proofId}/documents/${validationConfig.docId}/ai_verifications?ai_verification_type=${aiVerificationType}&ordering=-created`
			)
			.pipe(
				map((res) => {
					return res[0]
						? {
								categoryEntityId: validationConfig.categoryEntityId,
								id: validationConfig.docId,
								category: validationConfig.category,
								docName: validationConfig.docName,
								proofId: validationConfig.proofId,
								payload: res[0].payload,
								status: res[0].status,
							}
						: null;
				})
			);
	}
}
