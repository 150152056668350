import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnChanges,
	Optional,
	Self,
	SimpleChanges,
	ViewEncapsulation,
	HostListener,
	isDevMode,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, noop } from 'rxjs';

import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'oper-client-select',
	styleUrls: ['./select.component.scss'],
	template: `
		<ng-container *ngIf="multiple || deviceDetectorService.isDesktop()">
			<ng-select
				*ngIf="(selectedClass$ | async) !== 'ng-select-oper--status'; else statusSelect"
				#ngSelectOper
				class="ng-select-oper"
				[ngClass]="selectedClass$ | async"
				[appendTo]="appendTo"
				[bindValue]="bindValue"
				[bindLabel]="bindLabel"
				[class.ng-valid]="control?.valid"
				[class.ng-invalid]="control?.invalid ?? errorState"
				[class.ng-touched]="control?.touched ?? errorState"
				[items]="optionsTransformed"
				[clearable]="clearable"
				[searchable]="searchable"
				[placeholder]="placeholder"
				[(ngModel)]="value"
				(change)="onValueChanged($event)"
				(clear)="onValueChanged(null)"
				(close)="onClose()"
				(blur)="onTouchedCallback()"
				[disabled]="disabled"
				[multiple]="multiple"
				[closeOnSelect]="!multiple"
				[searchFn]="searchFn"
				(open)="onOpen(ngSelectOper)"
				[attr.data-test]="dataTestKey"
			>
				<ng-template *ngIf="dataTestKey.length > 0" ng-multi-label-tmp let-items="items" let-clear="clear">
					<div class="ng-value" *ngFor="let item of items">
						<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
						<span
							class="ng-value-label"
							[attr.data-test-option]="dataTestValue(item)"
							[attr.title]="item[bindLabel] | translate"
							>{{ item[bindLabel] }}</span
						>
					</div>
				</ng-template>
				<ng-template *ngIf="dataTestKey.length > 0" ng-label-tmp let-item="item" let-clear="clear">
					<span class="ng-value-label" [attr.data-test-option]="dataTestValue(item)">{{ item[bindLabel] }}</span>
				</ng-template>
				<ng-template *ngIf="dataTestKey.length > 0" ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					<div>
						<span
							[tooltip]="item[bindLabel] | translate"
							showTooltipIfTruncated
							[hideDelay]="0"
							[tooltipClass]="'tooltip-break-word'"
							[placement]="'right'"
							class="option-text"
							[attr.data-test-option]="dataTestValue(item)"
						>
							{{ item[bindLabel] }}
						</span>
					</div>
				</ng-template>
			</ng-select>
		</ng-container>
		<select
			*ngIf="!multiple && !deviceDetectorService.isDesktop()"
			class="select"
			[class.ng-valid]="control?.valid"
			[class.ng-invalid]="control?.invalid ?? errorState"
			[class.ng-touched]="control?.touched ?? errorState"
			[class.no-value-selected]="value === null || this.value === 'null'"
			[required]="!clearable"
			[disabled]="disabled"
			[(ngModel)]="value"
			(focusout)="onFocusOut($event)"
			(ngModelChange)="onSelectModelChange(value)"
		>
			<option
				*ngIf="clearable || value === 'null' || value === null"
				[value]="null"
				[style.display]="((!clearable || value === 'null' || value === null) && 'none') || undefined"
				[disabled]="!clearable || value === 'null' || value === null"
			>
				{{ value === 'null' || value === null ? placeholder : ('ç.question.clearSelection' | translate) }}
			</option>
			<option *ngFor="let option of optionsTransformed" [value]="option[bindValue]">
				{{ option[bindLabel] }}
			</option>
		</select>

		<ng-template #statusSelect>
			<ng-select
				#ngSelectOperStatus
				class="ng-select-oper--status"
				[ngClass]="selectedClass$ | async"
				[appendTo]="appendTo"
				[bindValue]="bindValue"
				[bindLabel]="bindLabel"
				[class.ng-valid]="control?.valid"
				[class.ng-invalid]="control?.invalid ?? errorState"
				[class.ng-touched]="control?.touched ?? errorState"
				[items]="optionsTransformed"
				[clearable]="clearable"
				[searchable]="searchable"
				[placeholder]="placeholder"
				[(ngModel)]="value"
				(change)="onValueChanged($event)"
				(clear)="onValueChanged(null)"
				(close)="onClose()"
				(blur)="onTouchedCallback()"
				[disabled]="disabled"
				[multiple]="multiple"
				[closeOnSelect]="!multiple"
				[searchFn]="searchFn"
				(open)="onOpen(ngSelectOperStatus)"
				[attr.data-test]="dataTestKey"
			>
				<ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
					<div class="status-option">
						<span class="dot" [style.background]="item.color"></span>
						<span
							[tooltip]="item[bindLabel] | translate"
							showTooltipIfTruncated
							[hideDelay]="0"
							[tooltipClass]="'tooltip-break-word'"
							[placement]="'right'"
							class="text"
							[attr.data-test-option]="dataTestValue(item)"
							>{{ item[bindLabel] }}
						</span>
					</div>
				</ng-template>
			</ng-select>
		</ng-template>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnChanges, ControlValueAccessor {
	@Input() ngSelectClass = 'ng-select-oper';
	@Input() options: SelectOption[];
	@Input() placeholder = '';
	@Input() formGroup: FormGroup;
	@Input() formControlName: string;
	@Input() clearable = true;
	@Input() searchable = true;
	@Input() value = null;
	@Input() disabled = false;
	@Input() bindValue = 'id';
	@Input() bindLabel = 'translatedKey';
	@Input() appendTo = 'body';
	@Input() multiple = false;
	@Input() alreadySorted = false;
	@Input() closeOnHostScroll = false;
	@Input() formName = '';
	@Input() prefillDefaultValue = false;
	@Input() errorState = false;

	@Output() valueChange = new EventEmitter<any>();
	@Output() selectedValueChange = new EventEmitter<any>();

	optionsTransformed: SelectOption[] = [];
	selectedClass$ = new BehaviorSubject('ng-select-oper');
	private isDevMode = isDevMode;

	private _closingComponentRef: NgSelectComponent = null;
	private defaultValueSet = false;

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;
	@Input() searchFn = (term: string, item: any) => {
		let label: string = <string>(item && item[this.bindLabel]);
		if (!label && typeof item === 'string') {
			label = <string>item;
		}
		return !!label && label.toLocaleLowerCase().startsWith(term.toLocaleLowerCase());
	};

	constructor(
		@Self() @Optional() public control: NgControl,
		private readonly translateService: TranslateService,
		public readonly deviceDetectorService: DeviceDetectorService
	) {
		if (this.control) {
			this.control.valueAccessor = this;
		}

		translateService.onLangChange.subscribe(() => {
			if (Array.isArray(this.options)) {
				this.transformOptions();
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['options'] && Array.isArray(this.options)) {
			this.transformOptions();
		}
		if (changes['ngSelectClass'] || changes['value']) {
			this.handleSelectClass();
		}
		this.handleDefaultValue();
	}

	private handleDefaultValue(): void {
		if (!this.prefillDefaultValue) {
			return;
		}
		if (this.value) {
			this.defaultValueSet = true;
		}
		if (this.defaultValueSet || !this.options) {
			return;
		}
		// In case for some item order is set to 0, it is considered as default value
		const defaultValue = this.options?.find((x) => x.order === 0);
		if (defaultValue) {
			this.value = defaultValue[this.bindValue];
			this.onValueChanged(defaultValue, true);
		}
		this.defaultValueSet = true;
	}

	private handleSelectClass(): void {
		if (!this.ngSelectClass) {
			return;
		}
		this.selectedClass$.next(this.ngSelectClass);
	}

	private transformOptions() {
		this.optionsTransformed = this.options
			.map((option) =>
				option.key
					? {
							...option,
							[this.bindLabel]: this.translateService.instant(option.key),
						}
					: option
			)
			// filter items with empty translation, case: nationality resource
			.filter((option) => !option?.key || option[this.bindLabel]);
		if (!this.alreadySorted) {
			this.optionsTransformed.sort((a: any, b: any) => {
				if (a.order !== b.order) {
					return (a.order === null ? Number.MAX_VALUE : a.order) - (b.order === null ? Number.MAX_VALUE : b.order);
				}
				return typeof a[this.bindLabel] === 'number' || !Number.isNaN(Number.parseFloat(a[this.bindLabel]))
					? a[this.bindLabel] - b[this.bindLabel]
					: typeof a[this.bindLabel] === 'string'
						? a[this.bindLabel].localeCompare(b[this.bindLabel])
						: 0;
			});
		}
	}

	onFocusOut(event: Event) {
		this.onTouchedCallback();
	}

	onValueChanged(value: any, skipOnTouched?: boolean) {
		let newValue: any;
		if (!value) {
			newValue = this.multiple ? [] : value;
		} else {
			if (Array.isArray(value)) {
				newValue = value.map((item) => item[this.bindValue]);
			} else {
				newValue = value[this.bindValue];
			}
		}
		this.onChangeCallback(newValue);
		if (!skipOnTouched) {
			this.onTouchedCallback();
		}

		// in current dynamic form implementation value change event is listening,
		// but the value is fetch from the form.
		// Without below timeout when change event is arrived, the form values can be not up to date
		setTimeout(() => {
			this.valueChange.emit(newValue);
			this.selectedValueChange.emit(newValue);
		});
	}

	onSelectModelChange(eventValue: any) {
		const value = this.optionsTransformed.find((item) => JSON.stringify(item[this.bindValue]) === eventValue);
		this.onValueChanged(value || null);
	}

	writeValue(obj: any) {
		this.value = obj;
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	onOpen(ngSelect: NgSelectComponent): void {
		// Fixing the misalignment between dropdown panel and the select (issue in the library)
		this._closingComponentRef = ngSelect;
		setTimeout((): void => {
			if (!ngSelect.dropdownId || !ngSelect.appendTo) {
				return;
			}

			const dropdown: HTMLElement = document.getElementById(ngSelect.dropdownId);
			const parent: HTMLElement = document.querySelector(ngSelect.appendTo);
			if (dropdown) {
				const selectRect: DOMRect = ngSelect.element.getBoundingClientRect();
				const parentRect: DOMRect = parent.getBoundingClientRect();
				const offsetLeft: number = selectRect.left - parentRect.left;
				dropdown.style.left = offsetLeft + 'px';
				dropdown.style.width = selectRect.width + 'px';
				dropdown.style.minWidth = selectRect.width + 'px';
				dropdown.style.maxWidth = selectRect.width + 'px';
			}
		});
	}

	onClose(): void {
		this.onTouchedCallback();
		this._closingComponentRef = null;
	}

	@HostListener('window:scroll', ['$event']) // for window scroll events
	onScroll(event) {
		// handling issue of scroll dropdown panel overflow on scroll
		// https://github.com/ng-select/ng-select/issues/1130
		if (!this.closeOnHostScroll || !this._closingComponentRef) {
			return;
		}
		const immuneClassName = 'ng-dropdown-panel-items';
		const isScrollingInScrollHost: boolean = (event.target.className as string)?.indexOf(immuneClassName) > -1;
		if (isScrollingInScrollHost) {
			return;
		}
		this._closingComponentRef?.close();
	}

	dataTestValue(item: any): string {
		const value = item.definition ?? item[this.bindValue];
		return `${this.dataTestKey}-${value}`;
	}

	get dataTestKey(): string {
		if (!this.formName && !this.formControlName) return '';
		return `${this.formName || ''}-${this.formControlName || ''}`;
	}
}
